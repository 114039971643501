import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {hover, primaryText, whiteText, secondaryText} from '../../../globalStyleVars';
import {Col, Container, Row} from "react-bootstrap";
import Title from "../../../components/Title";
import {baseUrl} from "../../../components/BaseUrl";
import ReactHtmlParser from "react-html-parser";
import {Parallax, useController} from "react-scroll-parallax";
import {Link, useParams} from "react-router-dom";

// Image
import BlurImg from "../../../assets/images/blur.jpg";
import pant from "../../../assets/images/dynamic/mens-removebg-preview.png";
import Modal from "../../../components/Modal";
import ModalVideo from "react-modal-video";
import VisibilitySensor from "react-visibility-sensor";

const NewsEventDetail = ({data, parentSlug}) => {
        let [open, setOpen] = useState(false);
        let [videoId, setVideo] = useState('');
        let handelOpen = (open, id) => {
            setOpen(open);
            setVideo(id);
        };

        // console.log(data, 'news Detail');
        return (
            <StyledNewsEventDetail className="">
                <Container>
                    <Row>
                        <div className="newseventDetail">
                            <div className="newseventDetail__head">
                                <Link to={'/media/' + parentSlug}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                        <g id="Back2parent" transform="translate(0 0)">
                                            <circle id="BG" cx="15" cy="15" r="15" transform="translate(0 0)"
                                                    fill="#313033"/>
                                            <circle id="Hover" cx="1" cy="1" r="1" transform="translate(14 14)"
                                                    fill="#80c42b" opacity="0"/>
                                            <g id="Icon" transform="translate(21.817 18.145) rotate(180)">
                                                <line id="line" x2="12.121" transform="translate(0 2.709)" fill="none"
                                                      stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                                <path id="path" d="M0,0,2.785,2.768,0,5.418"
                                                      transform="translate(9.942 0)"
                                                      fill="none" stroke="#fff" stroke-linecap="round"
                                                      stroke-linejoin="round" stroke-width="1"/>
                                            </g>
                                        </g>
                                    </svg>
                                    <span>All {parentSlug}</span>
                                </Link>

                            </div>
                            {
                                data && data.posts?.list ?
                                    <div className="newseventDetail__body">
                                        <div className="newseventDetail__body__title">
                                            <p>{data?.page_data?.short_desc}</p>
                                            <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                                {({isVisible}) =>
                                                    <h3 className={`${isVisible ? 'anim-active' : ''} fade-right`}>{data?.page_data?.sub_title.split("").map(function (char, index) {
                                                        return <span aria-hidden="true" key={index}>{char}</span>;
                                                    })}</h3>
                                                }
                                            </VisibilitySensor>
                                        </div>
                                        {
                                            data?.posts?.list.map((postData, pKey) => {
                                                return (
                                                    (() => {
                                                        if (postData?.data?.type === '17') {  // Text Type for post
                                                            return (
                                                                <div className="newseventDetail__body__text">
                                                                    {ReactHtmlParser(postData?.data?.description)}
                                                                </div>
                                                            )
                                                        } else if (postData?.data?.type === '18') {     // Media Type for post
                                                            let thumbImageData = postData?.images ? postData?.images.find(e => e.is_thumb === 'on') : '';
                                                            let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;
                                                            return (
                                                                thumbImageData?.short_desc !== '' ?
                                                                    <div className="newseventDetail__body__media">
                                                                        <div className="newseventDetail__body__media__img"
                                                                             onClick={() => handelOpen(true, `${thumbImageData?.short_desc}`)}>
                                                                            <img src={thumbImagePath}
                                                                                 alt="Video Thumbnail"/>
                                                                            <div
                                                                                className="newseventDetail__body__media__img__overlay">
                                                                                <svg id="Component_26_8"
                                                                                     data-name="Component 26 – 8"
                                                                                     xmlns="http://www.w3.org/2000/svg"
                                                                                     width="80" height="80"
                                                                                     viewBox="0 0 80 80">
                                                                                    <circle id="Ellipse_6"
                                                                                            data-name="Ellipse 6"
                                                                                            cx="40" cy="40" r="40"
                                                                                            fill="#80c42b"/>
                                                                                    <circle id="Ellipse_7"
                                                                                            data-name="Ellipse 7"
                                                                                            cx="1" cy="1" r="1"
                                                                                            transform="translate(39 39)"
                                                                                            fill="#fff" opacity="0"/>
                                                                                    <g id="Polygon_1" data-name="Polygon 1"
                                                                                       transform="translate(50.75 28) rotate(90)"
                                                                                       fill="none">
                                                                                        <path
                                                                                            d="M11.252,1.872a1.5,1.5,0,0,1,2.5,0l9.7,14.546A1.5,1.5,0,0,1,22.2,18.75H2.8a1.5,1.5,0,0,1-1.248-2.332Z"
                                                                                            stroke="none"/>
                                                                                        <path
                                                                                            d="M 12.5 2.704166412353516 L 12.5 2.704156875610352 L 2.802780151367188 17.24999237060547 C 2.802780151367188 17.2499942779541 2.802780151367188 17.24999618530273 2.802770614624023 17.24999618530273 L 22.19721603393555 17.24999618530273 C 22.19721603393555 17.24999618530273 22.19721794128418 17.2499942779541 22.19721984863281 17.24999618530273 L 12.50001049041748 2.704166412353516 C 12.50000667572021 2.704166412353516 12.50000381469727 2.704166412353516 12.5 2.704166412353516 M 12.5 1.204164505004883 C 12.97560501098633 1.204164505004883 13.45121002197266 1.426811218261719 13.74808025360107 1.872106552124023 L 23.44529914855957 16.41794776916504 C 24.10985946655273 17.4147777557373 23.3952693939209 18.74999618530273 22.19721984863281 18.74999618530273 L 2.802770614624023 18.74999618530273 C 1.604730606079102 18.74999618530273 0.8901405334472656 17.4147777557373 1.55470085144043 16.41794776916504 L 11.25191974639893 1.872106552124023 C 11.54878997802734 1.426811218261719 12.02439498901367 1.204164505004883 12.5 1.204164505004883 Z"
                                                                                            stroke="none" fill="#fff"/>
                                                                                    </g>
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                        <ModalVideo channel='youtube' isOpen={open}
                                                                                    videoId={videoId}
                                                                                    onClose={() => handelOpen(false, '')}/>
                                                                    </div>

                                                                    :
                                                                    <div className="newseventDetail__body__media">
                                                                        {
                                                                            postData?.images ?

                                                                                    postData?.images.map((postImage) => {
                                                                                        let postImagePath = postImage?.path ? baseUrl + postImage?.path + postImage?.img_name : BlurImg;

                                                                                        return(
                                                                                            <div
                                                                                                className="newseventDetail__body__media__img imageMedia">
                                                                                                <img src={postImagePath}
                                                                                                     alt="Video Thumbnail"/>
                                                                                            </div>
                                                                                        )
                                                                                    })

                                                                                :
                                                                                ''
                                                                        }

                                                                    </div>

                                                            )
                                                        }else {
                                                            <></>
                                                        }

                                                        return null;
                                                    })()
                                                )
                                            })
                                        }
                                    </div>
                                    : ''
                            }


                        </div>
                    </Row>
                </Container>
            </StyledNewsEventDetail>
        )
    }
;

const StyledNewsEventDetail = styled.section`
  margin-top: 150px;
  margin-bottom: 150px;

  .newseventDetail {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;

    &__head {
      border-bottom: 1px solid #E1E1E1;

      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        svg {
          transform: rotate(0deg);
          transition: transform .4s ease;

          circle#Hover {
            cx: 15;
            cy: 15;
            r: 0.1;
            transform: translate(0, 0) !important;
            fill: ${hover};
            opacity: 0;
            transition: all .3s ease;
          }

          #Icon {
            line, path {
              stroke: ${whiteText};
            }
          }
        }

        span {
          margin-left: 10px;
          font-size: 16px;
          font-weight: 500;
          color: ${primaryText};

        }

        margin-bottom: 50px;

        &:hover {
          svg {
            //transform: rotate(45deg);

            circle#Hover {
              r: 15;
              opacity: 1;
            }

            #Icon {
              line, path {
                stroke: ${whiteText};
              }
            }
          }
        }
      }
    }

    &__body {
      padding-top: 100px;
      padding-bottom: 50px;
      width: 70%;
      margin: 0 auto;

      &__title {
        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 26px;
          color: #A5A5A6;
          letter-spacing: 1px;
          margin-bottom: 20px;
        }

        h3 {
          font-size: 32px;
          line-height: 40px;
          font-weight: 500;
          color: ${hover};
          margin-bottom: 0;
        }
      }

      &__text {
        margin-top: 40px;

        p {
          font-size: 18px;
          line-height: 26px;
          font-weight: 500;
          color: ${secondaryText};
          margin-bottom: 40px;
        }
      }

      &__media {
        &__img {
          margin-top: 15px;
          margin-bottom: 15px;
          &.imageMedia {
            padding-top: initial;
            position: static;
            cursor: initial;

            &:hover {
              img {
                transform: scale(1.0);
              }
            }

            img {
              position: static;
            }
          }

          padding-top: calc(503 / 880 * 100%);
          position: relative;
          overflow: hidden;
          cursor: pointer;

          &__overlay {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background-color: rgba(49, 48, 51, 0.25);
            display: flex;
            justify-content: center;
            align-items: center;
          }

          img {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: scale(1);
            transition: transform .3s cubic-bezier(0.5, 1, 0.89, 1);

          }

          &:hover {
            img {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }

  @media (max-width: 1366px) {

  }

  @media (max-width: 991px) {

  }

  @media (max-width: 768px) {

  }

  @media (max-width: 767px) {
    margin-top: 90px;
    .newseventDetail {
      &__head {
        a{
          margin-bottom: 30px;
        }
      }
      &__body{
        padding-top: 40px;
        width: 100%;
        border-bottom: 1px solid #E1E1E1;
        &__title{
          h3{
            font-size: 24px;
            line-height: 30px;
          }
        }
        &__text {

          p {
            font-size: 16px;
            line-height: 26px;
          }
        }
      }
    }
    
  }

`;

export default NewsEventDetail;
