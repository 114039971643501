import React, {useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import Modal from '../../../components/Modal';
import TextSlideChildrenAnimation from "../../../components/animations/TextSlideChildrenAnimation";
import LinkWithIcon from "../../../components/LinkWithIcon";
import {primaryText, secondaryText, hover} from "../../../globalStyleVars";

// dynamic Image
import PopupImage from "../../../assets/images/dynamic/popup-image.jpg";
import ReactHtmlParser from "react-html-parser";
import VisibilitySensor from "react-visibility-sensor";


const AboutOverview = ({data, offset}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const splitedSubTitle = data?.page_data?.sub_title.split("*");

    return (
        <StyledOverview className="overview">
            <Container>
                <Row>
                    <Col md={12}>
                        <Row>
                            <Col md={10}>
                                {
                                    typeof splitedSubTitle != "undefined" ?
                                        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                            {({isVisible}) =>
                                                <h2 className={`${isVisible ? 'anim-active' : ''} fade-right overview-title`}>
                                                    <strong>
                                                        {splitedSubTitle[0].split("").map(function (char, index) {
                                                            return <span aria-hidden="true" key={index}>{char}</span>;
                                                        })}
                                                    </strong>
                                                    {splitedSubTitle[1].split("").map(function (char, index) {
                                                        return <span aria-hidden="true" key={index}>{char}</span>;
                                                    })}
                                                </h2>
                                            }
                                        </VisibilitySensor>
                                        :''
                                }

                                <Row>
                                    <Col sm={{span: 12, offset: 0}} md={{span: 6, offset: 6}}>
                                        <TextSlideChildrenAnimation className="overview-text">
                                            {ReactHtmlParser(data?.page_data?.short_desc)}
                                        </TextSlideChildrenAnimation>
                                        <div className="popupButton" onClick={handleShow}>
                                            <LinkWithIcon LinkTextColor={primaryText} LinkText={'Read More'}/>
                                        </div>
                                        {/*<Button variant="primary" onClick={handleShow}>*/}
                                        {/*    Launch static backdrop modal*/}
                                        {/*</Button>*/}
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Modal handleClose={handleClose} show={show} postData={data?.page_data?.description}
                   title={data?.page_data?.sub_title}/>
        </StyledOverview>
    )
};

const StyledOverview = styled.section`
  padding-top: 50px;
  padding-bottom: 150px;
  background-color: #F1F1F1;

  .sub-title {
    text-transform: initial;
    padding-bottom: 0 !important;
    margin-bottom: 100px !important;

    &:after {
      content: none;
    }
  }

  .popupButton {
    display: inline-block;
  }

  .overview-title{
    color: #545355;
    font-size: 45px; 
    line-height: 56px;
    font-weight: 500;
      margin-bottom: 80px;
    strong{
      color: #545355;
      font-weight: 500;
    }
  }

  .overview-text {
    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      margin-bottom: 48px;
      color: ${secondaryText};
    }
    table{
      p{
        margin-bottom: 10px;
      }
      tbody{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        tr{
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          td{
            &:first-child{
              flex: 0 0 50%;
            }
            &:last-child{
              flex: 0 0 50%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1680px) {
    .sub-title {
      font-size: 32px;
      line-height: 40px;
    }
    .overview-title{
      font-size: 32px;
    line-height: 40px;
    font-weight: 400;
  
    }
    .overview-text {
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 48px;
      }
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 100px;
  }

  @media (max-width: 767px) {
    padding-top: 80px;
    padding-bottom: 80px;
    .sub-title {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 40px !important;
    }
    .overview-title{
      margin-bottom: 40px;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
    }
  }

`;

export default AboutOverview;