import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {hover, primaryText, whiteText, secondaryText} from '../../../globalStyleVars';
import {Col, Container, Row} from "react-bootstrap";
import Title from "../../../components/Title";
import {baseUrl} from "../../../components/BaseUrl";
import ReactHtmlParser from "react-html-parser";
import {Parallax, useController} from "react-scroll-parallax";
import {Link, useParams} from "react-router-dom";

// Image
import BlurImg from "../../../assets/images/blur.jpg";
import pant from "../../../assets/images/dynamic/mens-removebg-preview.png";
import Modal from "../../../components/Modal";
import VisibilitySensor from "react-visibility-sensor";

const Listing = ({data, offset}) => {
        let {servicesSlug} = useParams();


        const [show, setShow] = useState(false);
        const [slug, setSlug] = useState('');

        const handleClose = () => setShow(false);
        const handleShow = (show, slug) => {
            setShow(show);
            setSlug(slug)
        };

        useEffect(() => {
            if (typeof (servicesSlug) !== 'undefined') {
                setSlug(servicesSlug)
                setShow(true);
            }
        }, [servicesSlug])

        let filterDetail = data && data.length ? data.find(f => f.page_data.slug === slug) : '';
        let servicePopupImagesData = filterDetail?.images?.list ? filterDetail?.images?.list.filter(e => e.is_thumb !== 'on' && e.is_banner !== 'on') : '';

        return (
            <StyledList>
                <Container>
                    {
                        data && data.length ?
                            <Row>
                                {
                                    data.map((service, sKey) => {
                                        let thumbImageData = service?.images?.list ? service?.images?.list.find(e => e.is_thumb === 'on') : '';
                                        let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;
                                        return (
                                            <Col key={sKey} md={6} className="serviceCol">
                                                <div className="serviceSingle"
                                                     onClick={() => handleShow(true, service?.page_data?.slug)}>
                                                    <Link to={'#'}>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             xmlnsXlink="http://www.w3.org/1999/xlink"
                                                             viewBox="0 0 85 85">
                                                            <defs>
                                                                <filter id="BG" x="0" y="0" width="85" height="85"
                                                                        filterUnits="userSpaceOnUse">
                                                                    <feOffset dy="5" input="SourceAlpha"/>
                                                                    <feGaussianBlur stdDeviation="7.5" result="blur"/>
                                                                    <feFlood flood-color="#80c42b" flood-opacity="0.302"/>
                                                                    <feComposite operator="in" in2="blur"/>
                                                                    <feComposite in="SourceGraphic"/>
                                                                </filter>
                                                            </defs>
                                                            <g id="Arrow_Icon_Component" data-name="Arrow Icon Component"
                                                               transform="translate(22.5 17.5)">
                                                                <g id="Icon" transform="translate(0 0)">
                                                                    <g transform="matrix(1, 0, 0, 1, -22.5, -17.5)"
                                                                       filter="url(#BG)">
                                                                        <circle id="BG-2" data-name="BG" cx="20" cy="20"
                                                                                r="20"
                                                                                transform="translate(22.5 17.5)"
                                                                                fill="#80c42b"/>
                                                                    </g>
                                                                    <circle id="Hover2" cx="1.333" cy="1.333" r="1.333"
                                                                            transform="translate(18.666 18.667)" fill="#fff"
                                                                            opacity="0"/>
                                                                    <circle id="Hover1" cx="2" cy="2" r="2"
                                                                            transform="translate(18 18)"
                                                                            fill="#313033" opacity="0"/>
                                                                    <g id="Arrow"
                                                                       transform="translate(11.673 23.908) rotate(-45)">
                                                                        <line id="Line_11" data-name="Line 11" x2="16.512"
                                                                              transform="translate(0 3.691)" fill="none"
                                                                              stroke="#fff"
                                                                              stroke-linecap="round" stroke-width="1.5"/>
                                                                        <path id="Path_7" data-name="Path 7"
                                                                              d="M0,0,3.794,3.77,0,7.381"
                                                                              transform="translate(13.543)" fill="none"
                                                                              stroke="#fff"
                                                                              stroke-linecap="round" stroke-linejoin="round"
                                                                              stroke-width="1.5"/>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </Link>
                                                    <div className="serviceSingle__img">
                                                        <img src={thumbImagePath} alt=""/>
                                                    </div>
                                                    <div className="serviceSingle__title">
                                                        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                                            {({isVisible}) =>
                                                                <h3 className={`${isVisible ? 'anim-active' : ''} fade-right`}>{service?.page_data?.title.split("").map(function (char, index) {
                                                                    return <span aria-hidden="true" key={index}>{char}</span>;
                                                                })}</h3>
                                                            }
                                                        </VisibilitySensor>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                            : ''
                    }

                </Container>
                <Modal handleClose={handleClose} show={show} postData={filterDetail?.page_data?.description}
                       title={filterDetail?.page_data?.title} thumbImage={servicePopupImagesData}/>
            </StyledList>
        )
    }
;

const StyledList = styled.section`
  padding-top: 150px;
  padding-bottom: 150px;
  background-color: ${primaryText};

  .serviceCol {
    &:nth-child(odd) {
      .serviceSingle {
        margin-right: 15px;
      }
    }

    &:nth-child(even) {
      .serviceSingle {
        margin-left: 15px;
        transform: translateY(100px);
      }
    }
  }

  .serviceSingle {
    position: relative;
    margin-bottom: 150px;

    &__img {
      position: relative;
      padding-top: calc(540 / 540 * 100%);
      background: rgb(241, 241, 241);
      background: radial-gradient(circle, rgba(241, 241, 241, 1) 0%, rgba(225, 225, 225, 1) 100%);
      overflow: hidden;

      img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1.01);
        transition: transform .4s ease;
      }
    }

    &__title {
      //padding-left: 10px;
      padding-top: 20px;

      h3 {
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        color: ${whiteText};
      }
    }

    a {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      display: flex;
      justify-content: flex-end;

      svg {
        width: 85px;
        height: 85px;
        transform: rotate(0deg);
        transition: transform .4s ease;

        circle#Hover1 {
          cx: 20;
          cy: 20;
          r: 1;
          transform: translate(0, 0) !important;
          fill: ${whiteText};
          opacity: 0;
          transition: all .3s ease;
        }

        #Arrow {
          line, path {
            stroke: ${whiteText};
          }
        }
      }
    }

    &:hover {
      .serviceSingle {
        &__img {
          img {
            transform: scale(1.04);
          }
        }
      }

      a {
        svg {
          transform: rotate(45deg);

          circle#Hover1 {
            r: 20;
            opacity: 1;
          }

          #Arrow {
            line, path {
              stroke: ${hover};
            }
          }
        }
      }

    }
  }

  @media (max-width: 1680px) {
    .serviceSingle {

      &__title {
        h3 {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }

  @media (max-width: 991px) {

  }

  @media (max-width: 768px) {

  }

  @media (max-width: 767px) {
    .serviceSingle {

      &__title {
        h3 {
          font-size: 20px;
          line-height: 32px;
        }
      }
    }
    .serviceCol {
      .serviceSingle {
        margin-bottom: 40px;
      }

      &:nth-child(odd) {
        .serviceSingle {
          margin-right: 0;
        }
      }

      &:nth-child(even) {
        .serviceSingle {
          margin-left: 0;
          transform: translateY(0);
        }
      }
    }
  }

`;


export default Listing;
