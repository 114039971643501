import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from "react-bootstrap";
import 'swiper/css/swiper.css';
import ReactHtmlParser from "react-html-parser";


// Component
import {hover, primaryText, secondaryText, whiteText} from "../../../globalStyleVars";
import SubTitle from "../../../components/SubTitle";
import TextSlideChildrenAnimation from "../../../components/animations/TextSlideChildrenAnimation";
import Title from "../../../components/Title";

// Images
import navLeft from "../../../assets/images/nav-left.svg";
import navLeftAfter from "../../../assets/images/nav-left-hover.svg";
import navRight from "../../../assets/images/nav-right.svg";
import navRightAfter from "../../../assets/images/nav-right-hover.svg";
import Swiper from "react-id-swiper";
import {Link} from "react-router-dom";

// Dynamic image
import Testimonial from "../../../assets/images/dynamic/testimonial.jpg";
import {baseUrl} from "../../../components/BaseUrl";
import BlurImg from "../../../assets/images/blur.jpg";
import VisibilitySensor from "react-visibility-sensor";


const HomeTestimonial = ({data, offset}) => {

    const params = {
        speed: 1500,
        slidesPerView: 1,
        spaceBetween: 30,
        // freeMode: true,
        // loop: true,
        slidesPerGroup: 1,
        grabCursor: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        breakpoints: {
            320: {
                autoplay: false,
                spaceBetween: 15,
            },

        }
    };

    const nextRef = useRef(null);
    const prevRef = useRef(null);
    const offsetRef = useRef(null);

    let [leftNav, setLeftNav] = useState(navLeft);
    let [rightNav, setRightNav] = useState(navRight);

    let [navStatus, setNavStatus] = useState(false);


    let handleRightBefore = (e) => {
        setRightNav(e);
    };
    let handleRightAfter = (e) => {
        setRightNav(e);
    };
    let handleLeftBefore = (e) => {
        setLeftNav(e);
    };
    let handleLeftAfter = (e) => {
        setLeftNav(e);
    };

    useEffect(() => {
        nextRef.current.addEventListener('click', () => {
            document.querySelector('.testimonial .swiper-button-prev').click();
        });
        prevRef.current.addEventListener('click', () => {
            document.querySelector('.testimonial .swiper-button-next').click();
        });

    }, []);

    return (
        <StyledTestimonial className="testimonial">
            <Container>
                <Row>
                    <Col md={5} className="testimonial__left">
                        <SubTitle color={'#939299'} text={''}/>
                        <TextSlideChildrenAnimation duration="1.17s">
                            <Title fontSize={'24'} lineHeight={'24'}
                                   text={data?.page_data?.short_desc} fontWeight={500} textTransform={'initial'}
                                   color={primaryText}/>
                        </TextSlideChildrenAnimation>

                        <div className="slider-nav">
                            <ul>
                                <li onMouseLeave={() => handleLeftAfter(navLeft)}
                                    onMouseOver={() => handleLeftBefore(navLeftAfter)} ref={nextRef}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                        <g id="Component_17_21" data-name="Component 17 – 21"
                                           transform="translate(40 40) rotate(180)">
                                            <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20"
                                                    fill="#545355"/>
                                            <circle id="Ellipse_5" data-name="Ellipse 5" cx="1" cy="1" r="1"
                                                    transform="translate(19 19)" fill="#545355" opacity="0"/>
                                            <g id="Group_274" data-name="Group 274" transform="translate(-710 -3473)">
                                                <path id="Path_9" data-name="Path 9" d="M775.554,4027.167l5,5-5,5"
                                                      transform="translate(-42.554 -539.168)" fill="none" stroke="#fff"
                                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                                <path id="Path_18" data-name="Path 18" d="M0,0H16"
                                                      transform="translate(722 3493)" fill="none" stroke="#fff"
                                                      stroke-linecap="round" stroke-width="1"/>
                                            </g>
                                        </g>
                                    </svg>

                                </li>
                                <li onMouseLeave={() => handleRightAfter(navRight)}
                                    onMouseOver={() => handleRightBefore(navRightAfter)} ref={prevRef}>
                                    <svg id="Component_17_20" data-name="Component 17 – 20"
                                         xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20"
                                                fill="#545355"/>
                                        <circle id="Ellipse_5" data-name="Ellipse 5" cx="1" cy="1" r="1"
                                                transform="translate(19 19)" fill="#545355" opacity="0"/>
                                        <g id="Group_274" data-name="Group 274" transform="translate(-710 -3473)">
                                            <path id="Path_9" data-name="Path 9" d="M775.554,4027.167l5,5-5,5"
                                                  transform="translate(-42.554 -539.168)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                            <path id="Path_18" data-name="Path 18" d="M0,0H16"
                                                  transform="translate(722 3493)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                    </svg>

                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={7} className="slider-wrap testimonial__right">
                        <Title fontSize={'60'} lineHeight={'60'}
                               text={data?.page_data?.title} fontWeight={300} textTransform={'uppercase'}
                               color={hover}/>
                        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                            {({isVisible}) =>
                                <p className={`${isVisible ? 'anim-active' : ''} fade-right`}>{data?.page_data?.sub_title.split("").map(function (char, index) {
                                    return <span aria-hidden="true" key={index}>{char}</span>;
                                })}</p>
                            }
                        </VisibilitySensor>

                        <div className="slider-wrap__mobile">
                            <Title fontSize={'24'} lineHeight={'24'}
                                   text={data?.page_data?.short_desc} fontWeight={500} textTransform={'initial'}
                                   color={primaryText}/>
                        </div>
                        {data?.posts?.list?.length &&
                            <Swiper {...params}>
                                {
                                    data?.posts?.list.map((clientsMessage, key) => {
                                        // let thumbImageData = clientsMessage?.images?.list ? clientsMessage?.images?.list.find(e => e.is_thumb === 'on') : '';
                                        // let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;
                                        return (
                                            <div className="slider_item" key={key}>
                                                <Link to={'#'}/>
                                                <div className="slider_item__img">
                                                    <img src={Testimonial} alt=""/>
                                                    <div className="slider_item__img__text">
                                                        <div className="testimonial-text">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="39.286"
                                                                 height="31.429"
                                                                 viewBox="0 0 39.286 31.429">
                                                                <path id="Path_2004" data-name="Path 2004"
                                                                      d="M-900.862,463.02a6.2,6.2,0,0,1,1.813,4.507,6.363,6.363,0,0,1-2.408,5.084,8.191,8.191,0,0,1-5.409,2.022,9.312,9.312,0,0,1-7.59-3.885,13.1,13.1,0,0,1-2.832-8.35,18.021,18.021,0,0,1,4.645-12.177,24.068,24.068,0,0,1,10.875-7.017v2.5q-9.8,5.232-9.8,12.556,0,3.779,1.869,3.779a8.391,8.391,0,0,0,2.181-.434,8.439,8.439,0,0,1,2.237-.433A5.957,5.957,0,0,1-900.862,463.02Zm21.1-.044a5.844,5.844,0,0,0-4.361-1.8,9.106,9.106,0,0,0-2.351.433,9.049,9.049,0,0,1-2.294.434q-1.984,0-1.983-3.488a11.254,11.254,0,0,1,3.059-7.9,29.086,29.086,0,0,1,6.854-5.058V443.2a23.059,23.059,0,0,0-10.932,7.017,18.427,18.427,0,0,0-4.475,12.293,13.036,13.036,0,0,0,2.775,8.292,9.107,9.107,0,0,0,7.533,3.827,8.181,8.181,0,0,0,5.522-2.091A6.6,6.6,0,0,0-878,467.358,6.054,6.054,0,0,0-879.758,462.976Z"
                                                                      transform="translate(917.288 -443.204)"
                                                                      fill="#fff"
                                                                      opacity="0.21"/>
                                                            </svg>
                                                            {ReactHtmlParser(clientsMessage?.data?.description)}
                                                        </div>
                                                        <div className="testimonial-designation">
                                                            <h3>{clientsMessage?.data?.title}</h3>
                                                            <p>{ReactHtmlParser(clientsMessage?.data?.extra_data)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </Swiper>
                        }
                    </Col>
                </Row>
            </Container>
        </StyledTestimonial>
    );
};

const StyledTestimonial = styled.section`
  padding-top: 180px;
  padding-bottom: 180px;
  background-color: ${whiteText};
  position: relative;


  .nav-wrap {
    position: relative;
  }

  .testimonial {
    &__left {

    }

    &__right {

    }
  }

  .slider-nav {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: fit-content;

    ul {
      display: flex;

      li {
        height: 42px;
        width: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #E5E5E5;
        transition: background-color .4s ease;
        cursor: pointer;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          height: 0;
          width: 0;
          background-color: ${hover};
          border-radius: 50%;
          transition: all .6s cubic-bezier(0.33, 1, 0.68, 1);
          opacity: 0;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          z-index: -1;
        }

        svg {
          circle#Ellipse_5 {
            cx: 20;
            cy: 20;
            r: 1;
            transform: translate(0, 0) !important;
            fill: rgb(128, 196, 43);
            stroke: rgb(128, 196, 43);
            transition: all .3s ease;

          }
        }

        &:nth-of-type(1) {
          margin-right: 5px;
        }

        &:hover {
            //background-color: ${hover};
          &:after {
            height: 100%;
            width: 100%;
            opacity: 0;
          }

          svg {
            circle#Ellipse_5 {
              r: 20;
              opacity: 1;
            }

            g {
              fill: inherit !important;
              stroke: inherit !important;
            }

          }

          img {
            z-index: 1;
          }
        }
      }
    }
  }

  .slider_item {
    position: relative;

    .slider_item__img {
      position: relative;
      padding-top: calc(503 / 880 * 100%);
      overflow: hidden;

      img {
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
        //transform: scale(1.01);
        //transition: transform 0.8s cubic-bezier(0.33, 1, 0.68, 1);
        //transform-origin: right top;
        position: absolute;
        top: 0;
        left: 0;
      }

      &__text {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(49, 48, 51, 0.85);
        padding: 65px 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .testimonial-text {
          svg {
            margin-bottom: 20px;
          }

          p {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            color: ${whiteText};
          }
        }

        .testimonial-designation {
          h3 {
            font-size: 16px;
            font-weight: 500;
            color: ${hover};
          }

          p {
            font-size: 13px;
            font-weight: 300;
            color: ${whiteText};
          }
        }

      }
    }

    a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 4;
    }

  }

  .sub-title {
    padding-top: 130px;

    &:after {
      width: 80%;
      background-color: #E1E1E1;
    }
  }

  .slider-wrap {
    padding-left: 0;

    &__mobile {
      display: none;
    }

    > p {
      margin-top: 20px;
      margin-bottom: 40px;
      font-size: 14px;
      font-weight: 300;
      line-height: 21px;
    }
  }

  .bottom {
    margin-top: 30px;

    h3 {
      font-size: 18px;
      font-weight: bold;
      color: ${primaryText};
      text-transform: uppercase;
      margin: 0;
    }
  }

  //.swiper-container {
  //  padding-right: 80px;
  //}
  @media (min-width: 1660px){
    .slider-wrap > p{
      font-size: 16px;
    }
  }
  @media (max-width: 1530px) {
    .slider_item {
      .slider_item__img {
        padding-top: calc(540 / 880 * 100%);
      }
    }
  }

  @media (max-width: 1680px) {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
    .slider-wrap {
      > p {
        font-size: 16px;
        line-height: 21px;
      }
    }

    .slider_item {
      .slider_item__img {
        padding-top: calc(550 / 880 * 100%);

        &__text {
          padding: 40px 65px;

          .testimonial-text {
            svg {
              width: 31px;
              margin-bottom: 10px;
            }

            p {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    padding-top: 85px;
    padding-bottom: 85px;

    .testimonial {

    }

    .slider-wrap {
      //padding: 0 40px !important;
      //padding-left: 40px !important;
      //padding-right: 0;
      //margin-top: 50px;
      //&__mobile {
      //  display: block;
      //  opacity: .7;
      //  padding-bottom: 20px;
      //  border-bottom: 1px solid #E1E1E1;
      //  margin-bottom: 30px;
      //}
    }

    .sub-title {
      padding-top: 130px;
    }

    .slider_item {
      .slider_item__img {
        padding-top: calc(650 / 880 * 100%);

        &__text {
          padding: 30px 30px;

          .testimonial-text {
            svg {
              width: 20px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 840px) {
    .sub-title {
      padding-top: 150px;
    }
  }

  @media (max-width: 768px) {
    .slider-wrap {
      padding: 0 15px !important;
    }
  }
  @media (max-width: 767px) {
    padding-top: 80px !important;
    padding-bottom: 80px !important;

    .testimonial {
      &__left {
        display: none;
      }
    }

    .slider-wrap {
      padding-left: 15px !important;
      padding-right: 0 !important;
      margin-top: 0;

      &__mobile {
        display: block;
        opacity: .7;
        padding-bottom: 20px;
        border-bottom: 1px solid #E1E1E1;
        margin-bottom: 30px;
      }

      .title {
        //padding-left: 15px;
        padding-right: 15px;
      }

      > p {
        //padding-left: 15px;
        padding-right: 15px;
        font-size: 14px;
        line-height: 21px;
      }

      &__mobile {
        //padding-left: 15px;
        margin-right: 15px;

        .title {
          font-size: 20px !important;
          line-height: 32px !important;
        }
      }

      .swiper-container {
        padding-right: 70px;
      }
    }

    .slider_item {
      .slider_item__img {
        padding-top: calc(423 / 324 * 100%);

        &__text {
          padding: 40px 15px;

          .testimonial-text {
            svg {
              width: 31px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    .slider-nav {
      bottom: 70px;
    }

  }



`;

export default HomeTestimonial;




