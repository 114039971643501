import {ABOUT} from "../../constants/about";

let initialState = {
  aboutMain: '',
  aboutMainLoading: false,
  aboutMainErr: '',
  aboutChild: '',
  aboutChildLoading: false,
  aboutChildErr: ''
};

export default function aboutReducer(state = initialState, action) {
  switch (action.type) {
    case ABOUT.FETCH_ABOUT_PAGE.MAIN:
      return {
        ...state,
        aboutMain: '',
        aboutMainLoading: true,
        aboutMainErr: ''
      };

    case ABOUT.FETCH_ABOUT_PAGE.SUCCESS:
      return {
        ...state,
        aboutMain: action.result,
        aboutMainLoading: false,
        aboutMainErr: ''
      };

    case ABOUT.FETCH_ABOUT_PAGE.FAILURE:
      return {
        ...state,
        aboutMain: '',
        aboutMainLoading: false,
        aboutMainErr: 'Error from Server'
      };

    case ABOUT.FETCH_ABOUT_CHILD.MAIN:
      return {
        ...state,
        aboutChild: '',
        aboutChildLoading: true,
        aboutChildErr: ''
      };

    case  ABOUT.FETCH_ABOUT_CHILD.SUCCESS:
      return {
        ...state,
        aboutChild: action.result,
        aboutChildLoading: false,
        aboutChildErr: ''
      };

    case ABOUT.FETCH_ABOUT_CHILD.FAILURE:
      return {
        ...state,
        aboutChild: '',
        aboutChildLoading: false,
        aboutChildErr: 'Error from server'
      };

    default:
      return state
  }
}
