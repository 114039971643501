import {call, put, takeLatest} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {ApiServices} from '../../../network/ApiServices';
import {MEDIA} from "../../constants/media";

function* fetchMediaMain(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )

    yield put({
      type: MEDIA.FETCH_MEDIA_PAGE.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: MEDIA.FETCH_MEDIA_PAGE.FAILURE
    })
  }
}

function* fetchMediaChild(actions) {
  try {
    const result = yield call(
        AxiosServices.get,
        ApiServices.GET_PAGE_BY_PAGE_SLUG,
        actions.params
    )

    yield put({
      type: MEDIA.FETCH_MEDIA_CHILD.SUCCESS,
      result: result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: MEDIA.FETCH_MEDIA_CHILD.FAILURE
    })
  }
}

function* fetchMediaChildOfChild(actions) {
  try {
    const result = yield call(
        AxiosServices.get,
        ApiServices.GET_CHILD_BY_PAGE_ID,
        actions.params
    )

    yield put({
      type: MEDIA.FETCH_MEDIA_CHILD_OF_CHILD.SUCCESS,
      result: result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: MEDIA.FETCH_MEDIA_CHILD_OF_CHILD.FAILURE
    })
  }
}

function* fetchMediaDetail(actions) {
  try {
    const result = yield call(
        AxiosServices.get,
        ApiServices.GET_PAGE_BY_PAGE_SLUG,
        actions.params
    )

    yield put({
      type: MEDIA.FETCH_MEDIA_DETAIL.SUCCESS,
      result: result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: MEDIA.FETCH_MEDIA_DETAIL.FAILURE
    })
  }
}

export default function* mediaSagas() {
  yield takeLatest(MEDIA.FETCH_MEDIA_PAGE.MAIN, fetchMediaMain);
  yield takeLatest(MEDIA.FETCH_MEDIA_CHILD.MAIN, fetchMediaChild);
  yield takeLatest(MEDIA.FETCH_MEDIA_CHILD_OF_CHILD.MAIN, fetchMediaChildOfChild);
  yield takeLatest(MEDIA.FETCH_MEDIA_DETAIL.MAIN, fetchMediaDetail);
}
