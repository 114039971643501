import React, {useEffect} from 'react';
import {connect} from "react-redux";

// components
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {ParallaxProvider} from 'react-scroll-parallax';
import InnerBanner from "../../components/InnerBanner";
import Listing from "./components/listing";


// Image
import BlurImg from "../../assets/images/blur.jpg";

// Action
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {fetchHomeService, fetchHomeServiceChild} from "../../redux/actions/home";

const ServicePage = ({
                         getHomeService,
                         homeServiceData,
                         getHomeServiceChild,
                         homeServiceChildData,
                         offset
                     }) => {

    useEffect(() => {
        window.scrollTo(0, 0);

        let serviceParam = {
            [ApiParams.page_type]: 'services'
        };
        getHomeService(serviceParam);
    }, []);


    useEffect(() => {
        let serviceChildParam = {
            [ApiParams.page_id]: homeServiceData?.page_data?.id
        };
        if (homeServiceData){
            getHomeServiceChild(serviceChildParam);
        }
    }, [homeServiceData]);


    let bannerImageData = homeServiceData?.images?.list ? homeServiceData?.images?.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = bannerImageData?.path ? baseUrl + bannerImageData?.path + bannerImageData?.img_name : BlurImg;


    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <ParallaxProvider>
                <InnerBanner image={bannerImagePath} subTitle={homeServiceData?.page_data?.title}
                             text={homeServiceData?.page_data?.sub_title}/>
                <Listing data={homeServiceChildData} parentData={homeServiceData} offset={offset}/>
            </ParallaxProvider>
        </motion.div>
    );
};


function mapStateToProps(state) {
    return {
        homeServiceData: state.homeReducer.homeServiceData,
        homeServiceChildData: state.homeReducer.homeServiceChildData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getHomeService: params => dispatch(fetchHomeService(params)),
        getHomeServiceChild: params => dispatch(fetchHomeServiceChild(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicePage);