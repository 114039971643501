import {MEDIA} from "../../constants/media";

export function fetchMediaMain(params) {
  return {
    type: MEDIA.FETCH_MEDIA_PAGE.MAIN,
    params
  }
}

export function fetchMediaChild(params) {
  return {
    type: MEDIA.FETCH_MEDIA_CHILD.MAIN,
    params
  }
}

export function fetchMediaChildOfChild(params) {
  return {
    type: MEDIA.FETCH_MEDIA_CHILD_OF_CHILD.MAIN,
    params
  }
}

export function fetchMediaDetail(params) {
  return {
    type: MEDIA.FETCH_MEDIA_DETAIL.MAIN,
    params
  }
}
