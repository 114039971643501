import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {ApiServices} from '../../../network/ApiServices';
import {HOME} from '../../constants/home'

function* fetchHomeBannerSlider(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    ); //true when Json Server
    yield put({
      type: HOME.FETCH_HOME_BANNER_SLIDER.SUCCESS,
      result: result.data,
      message: result.data.page_data.description
    })
  } catch (err) {
    Logger(err)
    yield put({type: HOME.FETCH_HOME_BANNER_SLIDER.FAILURE, error: "something went wrong"})
  }
}

// Home Service
function* fetchHomeService(actions) {
  try {
    const result = yield call(
        AxiosServices.get,
        ApiServices.GET_PAGE_BY_TYPE_DATA,
        actions.params
    ); //true when Json Server
    yield put({
      type: HOME.FETCH_HOME_SERVICE.SUCCESS,
      result: result.data,
      message: result.data.page_data.description
    })
  } catch (err) {
    Logger(err)
    yield put({type: HOME.FETCH_HOME_SERVICE.FAILURE, error: "something went wrong"})
  }
}

// Home Service Child
function* fetchHomeServiceChild(actions) {
  try {
    const result = yield call(
        AxiosServices.get,
        ApiServices.GET_CHILD_BY_PAGE_ID,
        actions.params
    ); //true when Json Server
    yield put({
      type: HOME.FETCH_HOME_SERVICE_CHILD.SUCCESS,
      result: result.data,
      message: 'Child data success'
    })
  } catch (err) {
    Logger(err)
    yield put({type: HOME.FETCH_HOME_SERVICE_CHILD.FAILURE, error: "something went wrong"})
  }
}
// Home Service Child
function* fetchHomeOverview(actions) {
  try {
    const result = yield call(
        AxiosServices.get,
        ApiServices.GET_PAGE_BY_TYPE_DATA,
        actions.params
    ); //true when Json Server
    yield put({
      type: HOME.FETCH_HOME_OVERVIEW.SUCCESS,
      result: result.data,
      message: 'Child data success'
    })
  } catch (err) {
    Logger(err)
    yield put({type: HOME.FETCH_HOME_OVERVIEW.FAILURE, error: "something went wrong"})
  }
}
// Home Why Choose
function* fetchHomeWhyChoose(actions) {
  try {
    const result = yield call(
        AxiosServices.get,
        ApiServices.GET_PAGE_BY_TYPE_DATA,
        actions.params
    ); //true when Json Server
    yield put({
      type: HOME.FETCH_HOME_WHY_CHOOSE.SUCCESS,
      result: result.data,
      message: 'Why choose data success'
    })
  } catch (err) {
    Logger(err)
    yield put({type: HOME.FETCH_HOME_WHY_CHOOSE.FAILURE, error: "something went wrong in why choose"})
  }
}
// Product Category
function* fetchProductCategory() {
  try {
    const result = yield call(
        AxiosServices.get,
        ApiServices.GET_ALL_CATEGORY,
        ''
    ); //true when Json Server
    yield put({
      type: HOME.FETCH_PRODUCT_CATEGORY.SUCCESS,
      result: result.data,
      message: 'Product Category data success'
    })
  } catch (err) {
    Logger(err)
    yield put({type: HOME.FETCH_PRODUCT_CATEGORY.FAILURE, error: "something went wrong in Product Category"})
  }
}
// Home Video

// Home Why Choose
function* fetchHomeVideo(actions) {
  try {
    const result = yield call(
        AxiosServices.get,
        ApiServices.GET_PAGE_BY_TYPE_DATA,
        actions.params
    ); //true when Json Server
    yield put({
      type: HOME.FETCH_HOME_VIDEO.SUCCESS,
      result: result.data,
      message: 'Why choose data success'
    })
  } catch (err) {
    Logger(err)
    yield put({type: HOME.FETCH_HOME_VIDEO.FAILURE, error: "something went wrong in why choose"})
  }
}
// Home Testimonial
function* fetchHomeTestimonial(actions) {
  try {
    const result = yield call(
        AxiosServices.get,
        ApiServices.GET_PAGE_BY_TYPE_DATA,
        actions.params
    ); //true when Json Server
    yield put({
      type: HOME.FETCH_HOME_TESTIMONIAL.SUCCESS,
      result: result.data,
      message: 'Home testimonial data success'
    })
  } catch (err) {
    Logger(err)
    yield put({type: HOME.FETCH_HOME_TESTIMONIAL.FAILURE, error: "something went wrong in home testimonial"})
  }
}



export default function* homeSagas() {
  yield takeLatest(HOME.FETCH_HOME_BANNER_SLIDER.MAIN, fetchHomeBannerSlider);
  yield takeLatest(HOME.FETCH_HOME_SERVICE.MAIN, fetchHomeService);
  yield takeLatest(HOME.FETCH_HOME_SERVICE_CHILD.MAIN, fetchHomeServiceChild);
  yield takeLatest(HOME.FETCH_HOME_OVERVIEW.MAIN, fetchHomeOverview);
  yield takeLatest(HOME.FETCH_HOME_WHY_CHOOSE.MAIN, fetchHomeWhyChoose);
  yield takeLatest(HOME.FETCH_PRODUCT_CATEGORY.MAIN, fetchProductCategory);
  yield takeLatest(HOME.FETCH_HOME_VIDEO.MAIN, fetchHomeVideo);
  yield takeLatest(HOME.FETCH_HOME_TESTIMONIAL.MAIN, fetchHomeTestimonial);
}
