export const HOME = {
  FETCH_HOME_BANNER_SLIDER: {
    MAIN: 'FETCH_HOME_BANNER_SLIDER',
    SUCCESS: 'FETCH_HOME_BANNER_SLIDER_SUCCESS',
    FAILURE: 'FETCH_HOME_BANNER_SLIDER_FAILURE',
  },
  FETCH_HOME_SERVICE: {
    MAIN: 'FETCH_HOME_SERVICE',
    SUCCESS: 'FETCH_HOME_SERVICE_SUCCESS',
    FAILURE: 'FETCH_HOME_SERVICE_FAILURE',
  },
  FETCH_HOME_SERVICE_CHILD: {
    MAIN: 'FETCH_HOME_SERVICE_CHILD',
    SUCCESS: 'FETCH_HOME_SERVICE_CHILD_SUCCESS',
    FAILURE: 'FETCH_HOME_SERVICE_CHILD_FAILURE',
  },
  FETCH_HOME_OVERVIEW: {
    MAIN: 'FETCH_HOME_OVERVIEW',
    SUCCESS: 'FETCH_HOME_OVERVIEW_SUCCESS',
    FAILURE: 'FETCH_HOME_OVERVIEW_FAILURE',
  },
  FETCH_HOME_WHY_CHOOSE: {
    MAIN: 'FETCH_HOME_WHY_CHOOSE',
    SUCCESS: 'FETCH_HOME_WHY_CHOOSE_SUCCESS',
    FAILURE: 'FETCH_HOME_WHY_CHOOSE_FAILURE',
  },
  FETCH_PRODUCT_CATEGORY: {
    MAIN: 'FETCH_PRODUCT_CATEGORY',
    SUCCESS: 'FETCH_PRODUCT_CATEGORY_SUCCESS',
    FAILURE: 'FETCH_PRODUCT_CATEGORY_FAILURE',
  },
  FETCH_HOME_VIDEO: {
    MAIN: 'FETCH_HOME_VIDEO',
    SUCCESS: 'FETCH_HOME_VIDEO_SUCCESS',
    FAILURE: 'FETCH_HOME_VIDEO_FAILURE',
  },
  FETCH_HOME_TESTIMONIAL: {
    MAIN: 'FETCH_HOME_TESTIMONIAL',
    SUCCESS: 'FETCH_HOME_TESTIMONIAL_SUCCESS',
    FAILURE: 'FETCH_HOME_TESTIMONIAL_FAILURE',
  },
};
