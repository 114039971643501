import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {hover, primaryText, whiteText, secondaryText} from '../../../globalStyleVars';
import {Col, Container, Row} from "react-bootstrap";
import Title from "../../../components/Title";
import SubTitle from "../../../components/SubTitle";
import {baseUrl} from "../../../components/BaseUrl";
import ReactHtmlParser from "react-html-parser";
import {Parallax, useController} from "react-scroll-parallax";
import {Link, useParams} from "react-router-dom";

// Image
import BlurImg from "../../../assets/images/blur.jpg";
import pant from "../../../assets/images/dynamic/mens-removebg-preview.png";
import Modal from "../../../components/Modal";
import VisibilitySensor from "react-visibility-sensor";

const NewsEventListing = ({data, catData, parentData, offset, mediaSlug}) => {
// console.log(data, 'news events listing');
        return (
            <>
                <StyledNewsEventMenu className="">
                    <Container fluid>
                        <Row>
                            <div className="newseventSingle">
                                <Parallax y={[0, 0]} tagOuter="figure">
                                    <Link to={'/media/events'}>
                                        <div
                                            className={`newseventSingle__wrap ${'events' === mediaSlug ? 'active' : ''}`}>
                                            <div className="newseventSingle__wrap__inner">
                                                <h4>01</h4>
                                                <p>Events</p>
                                            </div>
                                        </div>
                                    </Link>

                                </Parallax>
                            </div>
                            <div className="newseventSingle">
                                <Parallax y={[0, 0]} tagOuter="figure">
                                    <Link to={'/media/news'}>
                                        <div
                                            className={`newseventSingle__wrap ${'news' === mediaSlug ? 'active' : ''}`}>
                                            <div className="newseventSingle__wrap__inner">
                                                <h4>02</h4>
                                                <p>News</p>
                                            </div>
                                        </div>
                                    </Link>

                                </Parallax>
                            </div>
                        </Row>
                    </Container>
                </StyledNewsEventMenu>
                <StyledNewsEventList>
                    <Container>
                        {
                            data.length && data ?
                                <Row>
                                    {
                                        data.map((item, iKey) => {

                                            let thumbImageData = item?.images?.list ? item?.images?.list.find(e => e.is_thumb === 'on') : '';
                                            let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;
                                            return (
                                                <Col key={iKey} md={4}>
                                                    <div className="productSingle">
                                                        <Link to={'/media/' + mediaSlug + '/' + item?.page_data?.slug}></Link>
                                                        <div className="productSingle__img">
                                                            <img src={thumbImagePath} alt=""/>
                                                        </div>
                                                        <div className="productSingle__title">
                                                            <p>{item?.page_data?.short_desc}</p>
                                                            <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                                                {({isVisible}) =>
                                                                    <h3 className={`${isVisible ? 'anim-active' : ''} fade-right`}>{item?.page_data?.sub_title.split("").map(function (char, index) {
                                                                        return <span aria-hidden="true" key={index}>{char}</span>;
                                                                    })}</h3>
                                                                }
                                                            </VisibilitySensor>
                                                            <div className="productSingle__title__btn">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="30"
                                                                     height="30" viewBox="0 0 30 30">
                                                                    <g id="news-icon" transform="translate(0 0)">
                                                                        <circle id="BG" cx="15" cy="15" r="15"
                                                                                transform="translate(0 0)" fill="#80c42b"/>
                                                                        <circle id="Hover" cx="1" cy="1" r="1"
                                                                                transform="translate(14 14)" fill="#fff"
                                                                                opacity="0"/>
                                                                        <g id="Icon"
                                                                           transform="translate(9.039 18.02) rotate(-45)">
                                                                            <line id="line" x2="12.121"
                                                                                  transform="translate(0 2.709)" fill="none"
                                                                                  stroke="#fff" stroke-linecap="round"
                                                                                  stroke-width="1"/>
                                                                            <path id="path" d="M0,0,2.785,2.768,0,5.418"
                                                                                  transform="translate(9.942 0)" fill="none"
                                                                                  stroke="#fff" stroke-linecap="round"
                                                                                  stroke-width="1"/>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                                <span>Read More</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                                : <SubTitle color={'#545355'} fontSize={45} lineHeight={56} fontWeight={'500'}
                                            text={parentData?.page_data?.sub_title}/>
                        }
                    </Container>
                </StyledNewsEventList>
            </>
        )
    }
;

const StyledNewsEventMenu = styled.section`
  background-color: #F1F1F1;

  .newseventSingle {
    flex: 0 0 50%;

    &__wrap {
      position: relative;
      //flex: 0 0 25%;
      padding-top: calc(300 / 683 * 100%);
      background-color: ${primaryText};
      transition: all .4s ease;

      &.active {
        background-color: ${hover};

        .newseventSingle {
          &__wrap {
            &__inner {
              h4 {
                background-color: ${whiteText};
                color: ${hover};
              }
            }
          }
        }
      }

      &__inner {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        padding: 50px 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h4 {
          font-size: 16px;
          font-weight: 500;
          color: ${whiteText};
          line-height: 50px;
          width: 50px;
          height: 50px;
          border: 1px solid ${whiteText};
          border-radius: 50%;
          padding-left: 15px;
          transition: all .4s ease;
        }

        p {
          font-size: 32px;
          line-height: 40px;
          font-weight: 500;
          color: ${whiteText};
          margin-bottom: 0;
          text-transform: uppercase;
        }
      }
    }

    a {
      &:hover {
        .newseventSingle {
          &__wrap {
            //background-color: ${hover};

            &__inner {
              h4 {
                background-color: ${whiteText};
                color: ${hover};
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1680px) {
    .newseventSingle {
      &__wrap {
        &__inner {
          p {
            font-size: 24px;
            line-height: 32px;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .newseventSingle {
      &__wrap {
        &__inner {
          padding: 40px 40px;
        }
      }
    }
  }

  @media (max-width: 768px) {

  }

  @media (max-width: 767px) {
    .newseventSingle {
      flex: 0 0 50%;
      &__wrap {
        padding-top: calc(123 / 207 * 100%);
        &__inner {
          padding: 20px 20px;
          h4{
            width: 30px;
            height: 30px;
            font-size: 12px;
            line-height: 30px;
            padding-left: 7px;
          }
          p {
            font-size: 20px;
            line-height: 26px;
          }
        }
      }
      
    }
  }

`;
const StyledNewsEventList = styled.section`
  padding-top: 180px;
  padding-bottom: 180px;
  .sub-title{
    text-transform: initial;
    &:after{
      content: none;
    }
  }

  .productSingle {
    position: relative;
    margin-bottom: 45px;

    &__img {
      position: relative;
      padding-top: calc(300 / 370 * 100%);
      overflow: hidden;

      img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1.01);
        transition: transform .4s ease;
      }
    }

    &__title {
      padding-left: 10px;
      padding-top: 20px;
      p{
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: ${hover};
        margin-bottom: 10px;
      }
      h3 {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        color: ${secondaryText};
        margin-bottom: 20px;
      }
      &__btn{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        svg {
          transform: rotate(0deg);
          transition: transform .4s ease;

          circle#Hover {
            cx: 15;
            cy: 15;
            r: 0.1;
            transform: translate(0, 0) !important;
            fill: ${whiteText};
            stroke: ${whiteText};
            opacity: 0;
            transition: all .3s ease;
          }

          #Icon {
            line, path {
              stroke: ${whiteText};
            }
          }
        }
        span{
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          color: ${primaryText};
          margin-left: 10px;
        }
      }
    }

    a {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      display: flex;
      justify-content: flex-end;
      
    }

    &:hover {
      .productSingle {
        &__img {
          img {
            transform: scale(1.04);
          }
        }
        &__title{
          &__btn{
            svg {
              transform: rotate(45deg);

              circle#Hover {
                r: 15;
                opacity: 1;
              }

              #news-icon{
                fill:${whiteText};
                stroke: ${whiteText};
              }
              #Icon {
                line, path {
                  stroke: ${hover};
                }
              }
            }
          }
        }
      }

    }
  }

  @media (max-width: 1366px) {
    padding-top: 150px;
    padding-bottom: 150px;

    .productSingle {
      position: relative;
      margin-bottom: 45px;

      &__img {
        position: relative;
        padding-top: calc(300 / 370 * 100%);
        overflow: hidden;

        img {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transform: scale(1.01);
          transition: transform .4s ease;
        }
      }

      &__title {
        padding-left: 10px;
        padding-top: 20px;

        p {
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          color: ${hover};
          margin-bottom: 10px;
        }

        h3 {
          font-size: 18px;
          line-height: 24px;
        }
        &__btn{
          span{
            font-size: 16px;
          }
        }
      }
    }
  }
  
  @media (max-width: 991px) {
    
  }

  @media (max-width: 767px) {
    padding-top: 80px;
    padding-bottom: 80px;
    .productSingle {
      &__img {
        padding-top: calc(250 / 374 * 100%);
      }
    }
  }
`;

export default NewsEventListing;
