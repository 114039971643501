import {CONTACT} from "../../constants/contcat";

let initialState = {
  contactMain: '',
  contactMainLoading: false,
  contactMainErr: '',
  contactChild: '',
  contactChildLoading: false,
  contactChildErr: ''
};

export default function contactReducer(state = initialState, action) {
  switch (action.type) {
    case CONTACT.FETCH_CONTACT_PAGE.MAIN:
      return {
        ...state,
        contactMain: '',
        contactMainLoading: true,
        contactMainErr: ''
      };

    case CONTACT.FETCH_CONTACT_PAGE.SUCCESS:
      return {
        ...state,
        contactMain: action.result,
        contactMainLoading: false,
        contactMainErr: ''
      };

    case CONTACT.FETCH_CONTACT_PAGE.FAILURE:
      return {
        ...state,
        contactMain: '',
        contactMainLoading: false,
        contactMainErr: 'Error from Server'
      };

    case CONTACT.FETCH_CONTACT_CHILD.MAIN:
      return {
        ...state,
        contactChild: '',
        contactChildLoading: true,
        contactChildErr: ''
      };

    case  CONTACT.FETCH_CONTACT_CHILD.SUCCESS:
      return {
        ...state,
        contactChild: action.result,
        contactChildLoading: false,
        contactChildErr: ''
      };

    case CONTACT.FETCH_CONTACT_CHILD.FAILURE:
      return {
        ...state,
        contactChild: '',
        contactChildLoading: false,
        contactChildErr: 'Error from server'
      };

    default:
      return state
  }
}
