import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import ReactHtmlParser from "react-html-parser";

// components
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {ParallaxProvider} from 'react-scroll-parallax';
import InnerBanner from "../../components/InnerBanner";
import Listing from "./components/Listing";
import {useParams} from "react-router-dom";


import BlurImg from "../../assets/images/blur.jpg";
// Dynamic Image
import BannerImage from "../../assets/images/dynamic/AboutBanner.jpg";

// Action
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {fetchMediaMain, fetchMediaChild, fetchMediaChildOfChild} from "../../redux/actions/media";


const MediaPage = ({
                       getMediaMain,
                       getMediaChild,
                       mediaMain,
                       mediaChild,
                       getMediaChildOfChild,
                       mediaChildOfChild,
                       offset
                   }) => {
    const {mediaSlug} = useParams();

    // const [childId, setChildId] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);

        let mediaParam = {
            [ApiParams.page_type]: 'media'
        };
        getMediaMain(mediaParam);
    }, []);


    useEffect(() => {
        let mediaChildParam = {
            [ApiParams.page_slug]: mediaSlug
        };
        if (mediaChild) {
            getMediaChild(mediaChildParam);
        }

    }, [mediaSlug]);

    useEffect(() => {
        let mediaChildOfChildParam = {
            [ApiParams.page_id]: mediaChild?.page_data?.id
        };
        getMediaChildOfChild(mediaChildOfChildParam);
    }, [mediaChild]);



    let bannerImageData = mediaMain?.images?.list ? mediaMain?.images?.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = bannerImageData?.path ? baseUrl + bannerImageData?.path + bannerImageData?.img_name : BlurImg;


    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <ParallaxProvider>
                <InnerBanner image={bannerImagePath} subTitle={mediaMain?.page_data?.title}
                             text={mediaMain?.page_data?.sub_title}/>
                <Listing data={mediaChildOfChild} parentData={mediaChild} mediaSlug={mediaSlug}/>
            </ParallaxProvider>
        </motion.div>
    );
};


function mapStateToProps(state) {
    return {
        mediaMain: state.mediaReducer.mediaMain,
        mediaChild: state.mediaReducer.mediaChild,
        mediaChildOfChild: state.mediaReducer.mediaChildOfChild,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getMediaMain: params => dispatch(fetchMediaMain(params)),
        getMediaChild: params => dispatch(fetchMediaChild(params)),
        getMediaChildOfChild: params => dispatch(fetchMediaChildOfChild(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaPage);