import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from './SubTitle';
import htmlParser from "react-html-parser";
import VisibilitySensor from "react-visibility-sensor";
import Title from './Title';
import TextSlideChildrenAnimation from "./animations/TextSlideChildrenAnimation";
import {hover} from "../globalStyleVars";


const InnerBanner = ({subTitle, text='', paddingTop, image, children, height}) => {
    return (
        <StyledBanner height={height} paddingTop={paddingTop} className="inner-banner">
            <img src={image} alt=""/>
            <Container>
                <Row>
                    <Col xs={8}>
                        <div className="inner-banner__content">

                            <SubTitle color={'#FFF'} text={subTitle} fontSize={18} fontWeight={500} lineHeight={26} margin={'0 0 20px 0'}/>

                            {/*<TextSlideChildrenAnimation partialVisibility={{top: 0, bottom: 60}}>*/}
                            <div className="inner-banner__content__title">
                                {
                                    text.split("*").map(function (partialValue, key) {
                                        return (
                                            <VisibilitySensor key={key} partialVisibility={{top: 0, bottom: 60}}>
                                                {({isVisible}) =>
                                                    <h2 className={`${isVisible ? 'anim-active' : ''} fade-right title`}>{partialValue.split("").map(function (char, index) {
                                                        return <span aria-hidden="true" key={index}>{char}</span>;
                                                    })}</h2>
                                                }
                                            </VisibilitySensor>
                                        );
                                    })

                                }
                            </div>

                                {/*<Title color={'#FFF'} text={htmlParser(text)} fontSize={80} lineHeight={80}/>*/}
                            {/*</TextSlideChildrenAnimation>*/}
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledBanner>
    )
};

const StyledBanner = styled.section`
  margin-top: 100px;
  height: ${props => props.height || 'auto'};
  position: relative;
  padding-top: ${props => props.paddingTop || 'calc(500 / 1366 * 100%)'};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    //background-color: rgba(0, 0, 0, 0.2);
    //background: linear-gradient(125deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  }

  .container {
    position: relative;
    height: 100%;

    .row {
      height: 100%;
    }
  }
  
  .inner-banner__content {
    position: absolute;
    z-index: 2;
    bottom: 180px;

    .sub-title {
      padding-bottom: 0;

      &:after {
        content: none;
      }
    }

    &__title{
      h2{
        font-size: 80px;
        line-height: 80px;
        color: ${hover};
        font-weight: 300;
        text-transform: uppercase;
        margin-bottom: 0;
        &:last-child {
          color: #ffffff;
        }
      }
    }
  }

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    object-fit: cover;
  }

  @media (max-width: 1660px) {
    .inner-banner__content {
      .sub-title {
        font-size: 16px;
      }

      .title {
        font-size: 50px;
        line-height: 50px;
      }
    }
  }

  @media (max-width: 991px) {
    .inner-banner__content {
      bottom: 110px;
    }
  }

  @media (max-width: 840px) {
    .inner-banner__content {
      bottom: 80px;

      .title {
        font-size: 30px;
        line-height: 30px;
      }
    }
  }

  @media (max-width: 768px) {
    padding-top: 0 !important;
    height: 600px;
    margin-top: 60px;
  }

  @media (max-width: 767px) {
    margin-top: 60px;
    padding-top: calc(400 / 414 * 100%) !important;
    height: auto;
    .container {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;

      .col-8 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .inner-banner__content {
      //padding-left: 15px;
      padding-right: 15px;
      bottom: 85px;

      .sub-title {
        font-size: 16px;
        line-height: 26px;
      }

      .title {
        font-size: 30px !important;
        line-height: 30px !important;
      }
    }

  }

`;

export default InnerBanner;
