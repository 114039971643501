import {combineReducers} from 'redux'
import homeReducer from './home'
import aboutReducer from "./about";
import productReducer from "./product";
import formReducer from './form';
import contactReducer from './contact';
import mediaReducer from './media';
import galleryReducer from './gallery';


export default combineReducers({
  homeReducer,
  aboutReducer,
  productReducer,
  formReducer,
  contactReducer,
  mediaReducer,
  galleryReducer
})
