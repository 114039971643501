import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import TextSlideChildrenAnimation from "../../../components/animations/TextSlideChildrenAnimation";
import SubTitle from '../../../components/SubTitle';
import ReactHtmlParser from "react-html-parser";
import {hover, primaryText, secondaryText, whiteText} from "../../../globalStyleVars";

// Images
import Quote from "../../../assets/images/quote.svg";

// Dynamic Images
import CEOImages from "../../../assets/images/dynamic/ceo-dummy.jpg";
import {baseUrl} from "../../../components/BaseUrl";
import BlurImg from "../../../assets/images/blur.jpg";
import LinkWithIcon from "../../../components/LinkWithIcon";
import Modal from "../../../components/Modal";

const CEO = ({data, offset}) => {
    const quotes = <svg xmlns="http://www.w3.org/2000/svg" width="70" height="50" viewBox="0 0 70 50">
        <g id="Group_385" data-name="Group 385" transform="translate(-2206.583 2659.984)" opacity="0.15">
            <path id="Path_2056" data-name="Path 2056"
                  d="M2206.583-2631.945c0-20.028,11.281-27.486,29.359-28.039l2.169,10.083c-9.69,1.1-14.463,5.524-14.029,12.983h10.7v26.934h-28.2Zm38.326,0c0-20.028,11.426-27.486,29.36-28.039l2.314,10.083c-9.69,1.1-14.463,5.524-14.173,12.983h10.7v26.934h-28.2Z"
                  fill="#fff"/>
        </g>
    </svg>;

    let thumbImageData = data?.images?.list ? data?.images?.list.find(e => e.is_thumb === 'on') : '';
    let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <StyledCEO className="ceo" offset={offset} quotes={quotes}>
            <div className="ceo__left">
                <div className="ceo__left__message-box">
                    <div className="ceo__left__message-box__name">
                        <SubTitle text={data?.page_data?.title} fontSize={24} fontWeight={900} lineHeight={32}
                                  color={hover} margin={'0 0 10px 0'}/>
                        <p>{data?.page_data?.sub_title}</p>
                    </div>
                    <div className="ceo__left__message-box__text">
                        <TextSlideChildrenAnimation>
                            {ReactHtmlParser(data?.page_data?.short_desc)}
                        </TextSlideChildrenAnimation>
                        <div className="popupButton" onClick={handleShow}>
                            <LinkWithIcon LinkTextColor={whiteText} LinkText={'Read More'}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ceo__right">
                <div className="ceo__right__img">
                    <img src={thumbImagePath} alt=""/>
                </div>
            </div>

            <Modal handleClose={handleClose} show={show} postData={data?.page_data?.description}
                   title={data?.page_data?.title}/>
        </StyledCEO>
    )
};

const StyledCEO = styled.section`
  display: flex;
  flex-wrap: wrap;

  .ceo__left {
    padding-left: ${props => props.offset}px;
    background-color: ${primaryText};
    padding-top: 80px;
    padding-right: 70px;
    padding-bottom: 80px;
    flex: 0 0 44%;

    &__message-box {
      position: relative;
      padding-left: 70px;
      padding-top: 120px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 70px;
        height: 50px;
        z-index: 9;
        background-image: url(${Quote});
        background-repeat: no-repeat;
      }

      &__name {
        margin-bottom: 30px;

        .sub-title {
          padding: 0;

          &:after {
            content: none;
          }
        }

        p {
          font-size: 18px;
          line-height: 28px;
          font-weight: 300;
          color: ${whiteText};
        }
      }

      &__text {
        p {
          font-size: 18px;
          line-height: 28px;
          font-weight: 500;
          color: ${whiteText};
          margin-bottom: 32px;
        }
      }
    }
  }

  .ceo__right {
    //width: 100%;
    flex: 0 0 56%;

    &__img {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  @media (max-width: 1680px) {
    .ceo__left {

      &__message-box {
        padding-top: 100px;

        &__name {

          .sub-title {
            font-size: 18px;
            line-height: 24px;
          }

          p {
            font-size: 16px;
            line-height: 24px;
          }
        }

        &__text {
          p {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }

  @media (min-width: 1660px) {
    .ceo__right__img {
      min-height: 800px;
    }
  }
  @media (max-width: 1200px) {

  }

  @media (max-width: 768px) {

  }

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    .ceo__left {
      padding-top: 40px;

      &__message-box {
        padding-left: 0;
        padding-top: 70px;

        &__text {
          p {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }

    .ceo__right {
      flex: 0 0 100%;

      &__img {
        padding-top: calc(400 / 414 * 100%);
      }
    }
  }

`;

export default CEO;