import React from 'react';
import {Modal} from "react-bootstrap";
import {Col, Row, Button} from "react-bootstrap";
import Title from "./Title";
import ReactHtmlParser from 'react-html-parser'
import {hover} from "../globalStyleVars";
import Swiper from "react-id-swiper";
import 'swiper/css/swiper.css';
import {baseUrl} from "./BaseUrl";
import BlurImg from "../assets/images/blur.jpg";


const ImagePopup = ({show, handleClose, title, thumbImage}) => {

    const params = {
        speed: 1500,
        slidesPerView: 1,
        spaceBetween: 30,
        // freeMode: true,
        // loop: true,
        slidesPerGroup: 1,
        grabCursor: false,
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },
    };

    return (
        <Modal show={show} onHide={handleClose} className="customModal imageModal">
            <Modal.Header>
                <Button onClick={handleClose}>
                    <svg id="Component_32_3" data-name="Component 32 – 3" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                        <rect id="Rectangle_121" data-name="Rectangle 121" width="40" height="40" rx="20" fill="#80c42b"/>
                        <rect id="Rectangle_123" data-name="Rectangle 123" width="2" height="2" rx="1" transform="translate(19 19)" fill="#313033"/>
                        <g id="Group_399" data-name="Group 399" transform="translate(-769.362 -471.362)">
                            <line id="Line_44" data-name="Line 44" y2="16.971" transform="translate(795.343 485.343) rotate(45)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5"/>
                            <line id="Line_45" data-name="Line 45" y2="16.971" transform="translate(795.343 497.343) rotate(135)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5"/>
                        </g>
                    </svg>

                </Button>
            </Modal.Header>
            <Modal.Body>
                <Col>
                    <div className="modalContentWrap">
                        <Title text={title} color={hover} textTransform="uppercase" fontWeight={300}
                               fontSize={24} lineHeight={32} margin={'0 0 40px 0'}/>
                        <img src={thumbImage} alt=""/>
                    </div>

                </Col>
            </Modal.Body>
        </Modal>
    )
};


export default ImagePopup;
