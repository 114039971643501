import React from 'react';
import styled from 'styled-components';
import {hover, primaryText, whiteText, secondaryText} from '../../../globalStyleVars';
import {Col, Container, Row} from "react-bootstrap";
import SubTitle from "../../../components/SubTitle";
import {baseUrl} from "../../../components/BaseUrl";
import {Parallax} from "react-scroll-parallax";
import {Link} from "react-router-dom";
import {LightgalleryProvider, LightgalleryItem} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";

// Image
import BlurImg from "../../../assets/images/blur.jpg";

const PhotoItem = ({image, thumb, group}) => (
    <Col md={6}>
        <div className="productSingle">
            <div className="productSingle__img">
                <LightgalleryItem group={group} src={image} thumb={thumb}>
                    <Link to={'#'}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             xmlnsXlink="http://www.w3.org/1999/xlink"
                             viewBox="0 0 85 85">
                            <defs>
                                <filter id="BG" x="0" y="0" width="85" height="85"
                                        filterUnits="userSpaceOnUse">
                                    <feOffset dy="5" input="SourceAlpha"/>
                                    <feGaussianBlur stdDeviation="7.5" result="blur"/>
                                    <feFlood flood-color="#80c42b" flood-opacity="0.302"/>
                                    <feComposite operator="in" in2="blur"/>
                                    <feComposite in="SourceGraphic"/>
                                </filter>
                            </defs>
                            <g id="Arrow_Icon_Component" data-name="Arrow Icon Component"
                               transform="translate(22.5 17.5)">
                                <g id="Icon" transform="translate(0 0)">
                                    <g transform="matrix(1, 0, 0, 1, -22.5, -17.5)"
                                       filter="url(#BG)">
                                        <circle id="BG-2" data-name="BG" cx="20" cy="20"
                                                r="20"
                                                transform="translate(22.5 17.5)"
                                                fill="#80c42b"/>
                                    </g>
                                    <circle id="Hover2" cx="1.333" cy="1.333" r="1.333"
                                            transform="translate(18.666 18.667)" fill="#fff"
                                            opacity="0"/>
                                    <circle id="Hover1" cx="2" cy="2" r="2"
                                            transform="translate(18 18)"
                                            fill="#313033" opacity="0"/>
                                    <g id="Arrow"
                                       transform="translate(11.673 23.908) rotate(-45)">
                                        <line id="Line_11" data-name="Line 11" x2="16.512"
                                              transform="translate(0 3.691)" fill="none"
                                              stroke="#fff"
                                              stroke-linecap="round" stroke-width="1.5"/>
                                        <path id="Path_7" data-name="Path 7"
                                              d="M0,0,3.794,3.77,0,7.381"
                                              transform="translate(13.543)" fill="none"
                                              stroke="#fff"
                                              stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="1.5"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </Link>
                    <img src={image} />
                </LightgalleryItem>
            </div>
        </div>
    </Col>

);

const GalleryListing = ({data, parentData, gallerySlug}) => {
        return (
            <>
                <StyledGalleryMenu className="">
                    <Container fluid>
                        <Row>
                            <div className="gallerySingle">
                                <Parallax y={[0, 0]} tagOuter="figure">
                                    <Link to={'/gallery/photo'}>
                                        <div
                                            className={`gallerySingle__wrap ${'photo' === gallerySlug ? 'active' : ''}`}>
                                            <div className="gallerySingle__wrap__inner">
                                                <h4>01</h4>
                                                <p>Photo</p>
                                            </div>
                                        </div>
                                    </Link>

                                </Parallax>
                            </div>
                            <div className="gallerySingle">
                                <Parallax y={[0, 0]} tagOuter="figure">
                                    <Link to={'/gallery/video'}>
                                        <div
                                            className={`gallerySingle__wrap ${'video' === gallerySlug ? 'active' : ''}`}>
                                            <div className="gallerySingle__wrap__inner">
                                                <h4>02</h4>
                                                <p>Video</p>
                                            </div>
                                        </div>
                                    </Link>

                                </Parallax>
                            </div>
                        </Row>
                    </Container>
                </StyledGalleryMenu>
                <StyledGalleryList>
                    <Container>
                        {
                            data?.images?.list.length && data.images.list ?
                                <Row>
                                    <LightgalleryProvider
                                        galleryClassName="my_custom_classname"
                                    >
                                        {
                                            data.images.list.map((item, iKey) => {

                                                let imagePath = item?.path ? baseUrl + item?.path + item?.img_name : BlurImg;
                                                return (
                                                    <PhotoItem key={iKey} image={imagePath} thumb={imagePath} group="group1"/>
                                                )
                                            })
                                        }
                                    </LightgalleryProvider>
                                </Row>
                                : <SubTitle color={'#545355'} fontSize={45} lineHeight={56} fontWeight={'500'}
                                            text={parentData?.page_data?.sub_title}/>
                        }
                    </Container>
                </StyledGalleryList>
            </>
        )
    }
;

const StyledGalleryMenu = styled.section`
  background-color: #F1F1F1;

  .gallerySingle {
    flex: 0 0 50%;

    &__wrap {
      position: relative;
      //flex: 0 0 25%;
      padding-top: calc(300 / 683 * 100%);
      background-color: ${primaryText};
      transition: all .4s ease;

      &.active {
        background-color: ${hover};

        .gallerySingle {
          &__wrap {
            &__inner {
              h4 {
                background-color: ${whiteText};
                color: ${hover};
              }
            }
          }
        }
      }

      &__inner {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        padding: 50px 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h4 {
          font-size: 16px;
          font-weight: 500;
          color: ${whiteText};
          line-height: 50px;
          width: 50px;
          height: 50px;
          border: 1px solid ${whiteText};
          border-radius: 50%;
          padding-left: 15px;
          transition: all .4s ease;
        }

        p {
          font-size: 32px;
          line-height: 40px;
          font-weight: 500;
          color: ${whiteText};
          margin-bottom: 0;
          text-transform: uppercase;
        }
      }
    }

    a {
      &:hover {
        .gallerySingle {
          &__wrap {
              //background-color: ${hover};

            &__inner {
              h4 {
                background-color: ${whiteText};
                color: ${hover};
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1366px) {
    .gallerySingle {
      &__wrap {
        &__inner {
          p {
            font-size: 24px;
            line-height: 32px;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .gallerySingle {
      &__wrap {
        &__inner {
          padding: 40px 40px;
        }
      }
    }
  }

  @media (max-width: 768px) {

  }

  @media (max-width: 767px) {
    .gallerySingle {
      flex: 0 0 50%;

      &__wrap {
        padding-top: calc(123 / 207 * 100%);

        &__inner {
          padding: 20px 20px;

          h4 {
            width: 30px;
            height: 30px;
            font-size: 12px;
            line-height: 30px;
            padding-left: 7px;
          }

          p {
            font-size: 20px;
            line-height: 26px;
          }
        }
      }

    }
  }

`;
const StyledGalleryList = styled.section`
  padding-top: 180px;
  padding-bottom: 180px;

  .sub-title {
    text-transform: initial;

    &:after {
      content: none;
    }
  }

  .productSingle {
    position: relative;
    margin-bottom: 30px;
    cursor: pointer;

    &__img {
      position: relative;
      padding-top: calc(350 / 570 * 100%);
      overflow: hidden;

      img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1.01);
        transition: transform .4s ease;
      }
    }

    &__title {
      padding-left: 10px;
      padding-top: 20px;

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: ${hover};
        margin-bottom: 10px;
      }

      h3 {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        color: ${secondaryText};
        margin-bottom: 20px;
      }

      &__btn {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        svg {
          transform: rotate(0deg);
          transition: transform .4s ease;

          circle#Hover {
            cx: 15;
            cy: 15;
            r: 0.1;
            transform: translate(0, 0) !important;
            fill: ${whiteText};
            stroke: ${whiteText};
            opacity: 0;
            transition: all .3s ease;
          }

          #Icon {
            line, path {
              stroke: ${whiteText};
            }
          }
        }

        span {
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          color: ${primaryText};
          margin-left: 10px;
        }
      }
    }

    a {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      display: flex;
      justify-content: flex-end;
      svg{
        width: 80px;
        height: 80px;
        transition: transform .4s ease;
        transform: rotate(0deg);
        circle#Hover1 {
          cx: 20;
          cy: 20;
          r: 1;
          transform: translate(0, 0) !important;
          fill: ${whiteText};
          opacity: 0;
          transition: all .3s ease;
        }

        #Arrow {
          line, path {
            stroke: ${whiteText};
          }
        }
      }

    }

    &:hover {
      .productSingle {
        &__img {
          img {
            transform: scale(1.04);
          }
        }

        &__title {
          &__btn {
            svg {
              transform: rotate(45deg);

              circle#Hover {
                r: 15;
                opacity: 1;
              }

              #news-icon {
                fill: ${whiteText};
                stroke: ${whiteText};
              }

              #Icon {
                line, path {
                  stroke: ${hover};
                }
              }
            }
          }
        }
      }
      a{
        svg{
          transform: rotate(45deg);
          circle#Hover1 {
            r: 20;
            opacity: 1;
          }

          #Arrow {
            line, path {
              stroke: ${hover};
            }
          }
        }
      }
    }
  }

  @media (max-width: 1680px) {
    padding-top: 150px;
    padding-bottom: 150px;

    .productSingle {
      position: relative;
      margin-bottom: 45px;

      &__img {
        position: relative;
        padding-top: calc(300 / 370 * 100%);
        overflow: hidden;

        img {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transform: scale(1.01);
          transition: transform .4s ease;
        }
      }

      &__title {
        padding-left: 10px;
        padding-top: 20px;

        p {
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          color: ${hover};
          margin-bottom: 10px;
        }

        h3 {
          font-size: 18px;
          line-height: 24px;
        }

        &__btn {
          span {
            font-size: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {

  }

  @media (max-width: 767px) {
    padding-top: 80px;
    padding-bottom: 80px;
    .productSingle {
      &__img {
        padding-top: calc(250 / 374 * 100%);
      }
    }
  }
`;

export default GalleryListing;
