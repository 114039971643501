import {all} from 'redux-saga/effects';
import homeSagas from './home';
import aboutSagas from "./about";
import productSagas from "./product";
import formSaga from './form';
import contactSaga from './contact';
import mediaSaga from './media';
import gallerySaga from './gallery';

function* rootSaga() {
    yield all([
        homeSagas(),
        aboutSagas(),
        productSagas(),
        formSaga(),
        contactSaga(),
        mediaSaga(),
        gallerySaga(),
    ])
}

export default rootSaga
