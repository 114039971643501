import React, {useEffect, useRef, useState} from 'react';
import Splitting from 'splitting';
import styled from "styled-components";
import VisibilitySensor from 'react-visibility-sensor';
import ReactHtmlParser from "react-html-parser";

const TextSlideChildrenAnimation = ({
                                        by = 'lines',
                                        direction = "up", // currently support only up and down
                                        duration = "1.6s", // in second or millisecond like 1.6s or 1600ms
                                        className = "",
                                        children,
                                        once = false,
                                        offset = {},
                                        minTopValue = 10,
                                        partialVisibility = true
                                    }) => {
    const [isActive, setActive] = useState(true);
    let elRef = useRef(null),
        splittings,
        timeOutId,
        durationToMilliSecond = parseFloat(duration) * (duration.indexOf('ms') === -1 ? 1000 : 1);
    useEffect(() => {
        splittings = Splitting({
            target: elRef.current,
            by
        });
        splittings.forEach((splitting) => {
            splitting.words.forEach((word) => {
                /*
                let span = document.createElement('span');
                span.classList.add('word-wrap');
                span.innerHTML = ReactHtmlParser(word.innerHTML);
                word.innerHTML = span.outerHTML;
                */
                word.innerHTML = `<span class="word-wrap">${ReactHtmlParser(word.innerHTML)}</span>`;
            });
        });
        return () => {
            window.clearTimeout(timeOutId);
        };
    }, []);
    return (
        <VisibilitySensor partialVisibility={partialVisibility} minTopValue={minTopValue} active={isActive} offset={offset} onChange={(inView) => {
            elRef.current.classList[inView ? 'remove' : 'add']("dc-animation--disabled");
            elRef.current.classList[inView ? 'add' : 'remove']("dc-animation--active");
            if (inView) {
                timeOutId = window.setTimeout(() => {
                    elRef.current.classList.add("word-no-padding-margin"); // if set once, elRef is set to null and get error. need to fix it
                }, durationToMilliSecond);
                if(once && isActive) {
                    setActive(false);
                }
            } else {
                elRef.current.classList.remove("word-no-padding-margin");
            }
        }}>
            <StyledDiv duration={duration} ref={elRef}
                       className={`dc-animation text-slide text-slide--${direction} ${className}`}
                       style={{'--duration': duration}}>
                {children}
            </StyledDiv>
        </VisibilitySensor>

    );
};

const StyledDiv = styled.div`
`;

export default TextSlideChildrenAnimation;