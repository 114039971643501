import React, {useState,useEffect} from 'react';
import {hover, hoverNd} from '../../../globalStyleVars';
import styled from 'styled-components';
import {Container, Row, Col} from "react-bootstrap";
import errorImg from '../../../assets/images/404.png';
import Button from '../../../components/Button';
import {useLocation} from 'react-router-dom';


const ErrorPage = () => {

    useEffect(()=>{
        var body = document.body;
        body.classList.add("MyClass");
    });

    return (
        <StyledErrorPage>
            <Container>
                <div className="StyledErrorPage__wrap">
                    <img src={errorImg} alt=""/>
                    <h4>Looks like something went completely wrong...</h4>
                    <p>The page you are looking for was removed, moved, renamed, or has never existed</p>
                    <div className="btn-group">
                        <Button link={'/'} text="Home" color={'#ffffff'} border={'1px solid #ffffff'} />
                        {/*<Button link={'/contact'} text="Contact" width='150px' />*/}
                    </div>
                </div>
            </Container>
        </StyledErrorPage>
    )
};

const StyledErrorPage = styled.div`
  
  .StyledErrorPage__wrap{
    margin-top: 120px;
    min-height: 70vh;
    border: 1px solid #DDD;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 78px;
    padding: 40px 15px 50px 15px;
    position: relative;
    z-index: 2;
    h4{
      font-size: 18px;
      font-weight: 400; 
      margin: 0 0 10px 0;
      line-height: 27px;
      color: #ffffff;
    }
    p{
      font-size: 14px;
      font-weight: 300;
      margin: 0;
      line-height: 21px;
      color: #ffffff;
    }
    .btn-group{
    margin-top: 50px;
       
    }
      
  }
  @media(max-width:500px){
    .StyledErrorPage__wrap{
      text-align: center;
      margin-top: 80px;
    }
    .btn-group{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
      .dc-btn{
        width: 100%;
        margin-right: 0 !important;
        margin-bottom: 20px;
      }
    }
  }
`;
export default ErrorPage;