import React from 'react';
import {hover, darkGreen} from '../globalStyleVars';
import styled from 'styled-components';
import VisibilitySensor from "react-visibility-sensor";

const SubTitle = ({text='', fontSize, fontWeight, color, letterSpacing, lineHeight, margin}) => {
    return (
        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
            {({isVisible}) =>
                <StyledSubTitle
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                    color={color}
                    lineHeight={lineHeight}
                    letterSpacing={letterSpacing}
                    margin={margin}
                    className={`${isVisible ? 'anim-active' : ''} fade-right sub-title`}>
                    {text.split("").map(function (char, index) {
                        return <span aria-hidden="true" key={index}>{char}</span>;
                    })}
                    {/*{text}*/}
                </StyledSubTitle>
            }
        </VisibilitySensor>
    )
};

const StyledSubTitle = styled.h3`
  font-size: ${props => props.fontSize || 15}px;
  font-weight: ${props => props.fontWeight || 400};
  margin: ${props => props.margin || '0 0 30px 0'};;
  line-height: ${props => props.lineHeight || 15}px;
  color: ${props => props.color || '#FFF'};
  text-transform: uppercase;
  letter-spacing: ${props => props.letterSpacing || 0}px;
  position: relative;
  padding-bottom: 15px;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 70px;
    height: 1px;
    background-color:${props => props.color || '#FFF'};
  }
`;

export default SubTitle;
