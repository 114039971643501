import React, {useState} from 'react';
import {hover, primaryText, whiteText} from '../globalStyleVars';
import styled from 'styled-components';
import {Col, Container, Row} from "react-bootstrap";

// Image
import FooterPattern from "../assets/images/footer-pattern.svg";

const Footer = ({offset}) => {


        return (
            <StyledFooter offset={offset}>
                <Container fluid>
                    <Row>
                        <Col lg={5} className="footer-left" style={{paddingLeft: offset + 15}}>
                            <div className="footer-left__logo">
                                <svg id="Logo" xmlns="http://www.w3.org/2000/svg" width="238.728" height="60"
                                     viewBox="0 0 238.728 60">
                                    <g id="Group_351" data-name="Group 351" transform="translate(-67.745 -369.247)">
                                        <g id="Group_346" data-name="Group 346" transform="translate(131.828 419.505)">
                                            <path id="Path_2008" data-name="Path 2008"
                                                  d="M186.506,470.261l-1.744-5.453h1.416l1.033,3.574.95-3.574h1.406l.918,3.574,1.053-3.574h1.438l-1.77,5.453h-1.4l-.949-3.507-.934,3.507Z"
                                                  transform="translate(-184.762 -462.734)" fill="#80c42b"/>
                                            <path id="Path_2009" data-name="Path 2009"
                                                  d="M208.129,470.261l-1.744-5.453H207.8l1.032,3.574.95-3.574h1.406l.918,3.574,1.053-3.574H214.6l-1.77,5.453h-1.4l-.949-3.507-.934,3.507Z"
                                                  transform="translate(-194.543 -462.734)" fill="#80c42b"/>
                                            <path id="Path_2010" data-name="Path 2010"
                                                  d="M229.752,470.261l-1.744-5.453h1.417l1.032,3.574.95-3.574h1.406l.918,3.574,1.053-3.574h1.438l-1.77,5.453h-1.4l-.949-3.507-.934,3.507Z"
                                                  transform="translate(-204.325 -462.734)" fill="#80c42b"/>
                                            <path id="Path_2011" data-name="Path 2011"
                                                  d="M250.939,473.574v-1.443H252.4v1.443Z"
                                                  transform="translate(-214.698 -466.046)" fill="#80c42b"/>
                                            <path id="Path_2012" data-name="Path 2012"
                                                  d="M265.7,468.425l1.453.242a2.36,2.36,0,0,1-.884,1.2,2.617,2.617,0,0,1-1.513.413,2.474,2.474,0,0,1-2.127-.929,3.1,3.1,0,0,1-.545-1.879,3,3,0,0,1,.715-2.124,2.372,2.372,0,0,1,1.812-.767,2.46,2.46,0,0,1,1.941.8,3.53,3.53,0,0,1,.679,2.462h-3.652a1.449,1.449,0,0,0,.353,1,1.109,1.109,0,0,0,.84.357.9.9,0,0,0,.576-.185A1.136,1.136,0,0,0,265.7,468.425Zm.083-1.458a1.38,1.38,0,0,0-.327-.953,1.059,1.059,0,0,0-1.547.018,1.326,1.326,0,0,0-.306.935Z"
                                                  transform="translate(-219.739 -462.632)" fill="#80c42b"/>
                                            <path id="Path_2013" data-name="Path 2013"
                                                  d="M284.72,466.319l-1.438.257a1.032,1.032,0,0,0-.329-.642,1,1,0,0,0-.667-.215,1.093,1.093,0,0,0-.869.372,1.9,1.9,0,0,0-.325,1.246,2.189,2.189,0,0,0,.33,1.371,1.088,1.088,0,0,0,.885.4.986.986,0,0,0,.68-.234,1.392,1.392,0,0,0,.373-.8l1.432.241a2.5,2.5,0,0,1-.856,1.473,2.666,2.666,0,0,1-1.7.5,2.539,2.539,0,0,1-1.928-.754,3.412,3.412,0,0,1,0-4.193,2.576,2.576,0,0,1,1.951-.752,2.688,2.688,0,0,1,1.6.428A2.378,2.378,0,0,1,284.72,466.319Z"
                                                  transform="translate(-227.66 -462.632)" fill="#80c42b"/>
                                            <path id="Path_2014" data-name="Path 2014"
                                                  d="M296.887,467.357a2.917,2.917,0,0,1,.358-1.392,2.447,2.447,0,0,1,1.014-1.027,3.045,3.045,0,0,1,1.465-.354,2.884,2.884,0,0,1,2.042,4.885,2.74,2.74,0,0,1-2.031.814,3.222,3.222,0,0,1-1.445-.339,2.337,2.337,0,0,1-1.045-.993A3.274,3.274,0,0,1,296.887,467.357Zm1.495.077a1.792,1.792,0,0,0,.389,1.242,1.281,1.281,0,0,0,1.917,0,1.819,1.819,0,0,0,.387-1.252,1.787,1.787,0,0,0-.387-1.232,1.281,1.281,0,0,0-1.917,0A1.793,1.793,0,0,0,298.382,467.434Z"
                                                  transform="translate(-235.483 -462.632)" fill="#80c42b"/>
                                            <path id="Path_2015" data-name="Path 2015"
                                                  d="M314.947,468.6l1.464-.221a1.054,1.054,0,0,0,.378.639,1.289,1.289,0,0,0,.8.219,1.451,1.451,0,0,0,.85-.205.458.458,0,0,0,.192-.385.38.38,0,0,0-.1-.273,1.172,1.172,0,0,0-.488-.189,7.991,7.991,0,0,1-2.237-.7,1.4,1.4,0,0,1-.653-1.227,1.5,1.5,0,0,1,.565-1.192,2.659,2.659,0,0,1,1.754-.482,3.09,3.09,0,0,1,1.681.364,1.863,1.863,0,0,1,.757,1.078l-1.375.252a.844.844,0,0,0-.335-.488,1.233,1.233,0,0,0-.7-.169,1.59,1.59,0,0,0-.825.159.338.338,0,0,0-.166.292.317.317,0,0,0,.145.262,5.491,5.491,0,0,0,1.362.406,4.1,4.1,0,0,1,1.627.641,1.33,1.33,0,0,1,.456,1.073,1.641,1.641,0,0,1-.633,1.289,2.825,2.825,0,0,1-1.873.539,3.1,3.1,0,0,1-1.783-.452A2.084,2.084,0,0,1,314.947,468.6Z"
                                                  transform="translate(-243.653 -462.632)" fill="#80c42b"/>
                                            <path id="Path_2016" data-name="Path 2016"
                                                  d="M332.593,467.357a2.923,2.923,0,0,1,.358-1.392,2.45,2.45,0,0,1,1.014-1.027,3.046,3.046,0,0,1,1.465-.354,2.884,2.884,0,0,1,2.042,4.885,2.741,2.741,0,0,1-2.031.814,3.222,3.222,0,0,1-1.445-.339,2.337,2.337,0,0,1-1.045-.993A3.28,3.28,0,0,1,332.593,467.357Zm1.494.077a1.792,1.792,0,0,0,.389,1.242,1.281,1.281,0,0,0,1.917,0,1.817,1.817,0,0,0,.387-1.252,1.784,1.784,0,0,0-.387-1.232,1.281,1.281,0,0,0-1.917,0A1.793,1.793,0,0,0,334.087,467.434Z"
                                                  transform="translate(-251.635 -462.632)" fill="#80c42b"/>
                                            <path id="Path_2017" data-name="Path 2017"
                                                  d="M355.191,470.261v-.817a2.115,2.115,0,0,1-.791.688,2.229,2.229,0,0,1-1.035.251,2.039,2.039,0,0,1-1-.241,1.431,1.431,0,0,1-.638-.678,3,3,0,0,1-.2-1.207v-3.451h1.458v2.506a6.911,6.911,0,0,0,.081,1.41.77.77,0,0,0,.293.411.912.912,0,0,0,.54.152,1.153,1.153,0,0,0,.669-.2,1.034,1.034,0,0,0,.405-.5,5.777,5.777,0,0,0,.109-1.471v-2.3h1.458v5.453Z"
                                                  transform="translate(-260.203 -462.734)" fill="#80c42b"/>
                                            <path id="Path_2018" data-name="Path 2018"
                                                  d="M371.317,470.16h-1.458v-5.453h1.354v.776a2.341,2.341,0,0,1,.625-.725,1.164,1.164,0,0,1,.631-.174,1.868,1.868,0,0,1,.959.272l-.451,1.258a1.292,1.292,0,0,0-.685-.237.815.815,0,0,0-.519.167,1.136,1.136,0,0,0-.335.6,8.458,8.458,0,0,0-.122,1.828Z"
                                                  transform="translate(-268.493 -462.632)" fill="#80c42b"/>
                                            <path id="Path_2019" data-name="Path 2019"
                                                  d="M388.593,466.319l-1.438.257a1.03,1.03,0,0,0-.329-.642,1,1,0,0,0-.667-.215,1.093,1.093,0,0,0-.869.372,1.9,1.9,0,0,0-.324,1.246,2.19,2.19,0,0,0,.329,1.371,1.088,1.088,0,0,0,.885.4.986.986,0,0,0,.68-.234,1.392,1.392,0,0,0,.373-.8l1.433.241a2.506,2.506,0,0,1-.857,1.473,2.666,2.666,0,0,1-1.7.5,2.538,2.538,0,0,1-1.928-.754,3.412,3.412,0,0,1,0-4.193,2.576,2.576,0,0,1,1.951-.752,2.687,2.687,0,0,1,1.6.428A2.378,2.378,0,0,1,388.593,466.319Z"
                                                  transform="translate(-274.648 -462.632)" fill="#80c42b"/>
                                            <path id="Path_2020" data-name="Path 2020"
                                                  d="M401.376,462.355V461.02h1.458v1.335Zm0,6.193v-5.453h1.458v5.453Z"
                                                  transform="translate(-282.75 -461.02)" fill="#80c42b"/>
                                            <path id="Path_2021" data-name="Path 2021"
                                                  d="M418.3,470.16h-1.458v-2.783a4.25,4.25,0,0,0-.094-1.143.8.8,0,0,0-.3-.4.877.877,0,0,0-.505-.144,1.175,1.175,0,0,0-.68.206,1.032,1.032,0,0,0-.413.544,4.654,4.654,0,0,0-.111,1.252v2.47h-1.458v-5.453h1.354v.8a2.277,2.277,0,0,1,2.7-.752,1.487,1.487,0,0,1,.6.439,1.592,1.592,0,0,1,.286.606,4.488,4.488,0,0,1,.081.97Z"
                                                  transform="translate(-288.134 -462.632)" fill="#80c42b"/>
                                            <path id="Path_2022" data-name="Path 2022"
                                                  d="M431.275,470.52l1.665.2a.564.564,0,0,0,.192.4,1.108,1.108,0,0,0,.654.154,1.709,1.709,0,0,0,.857-.17.7.7,0,0,0,.29-.364,2.178,2.178,0,0,0,.067-.663v-.8a1.978,1.978,0,0,1-1.65.883,2.038,2.038,0,0,1-1.759-.929,3.142,3.142,0,0,1-.508-1.829,2.988,2.988,0,0,1,.667-2.1,2.161,2.161,0,0,1,1.658-.723,2.023,2.023,0,0,1,1.686.888v-.766h1.365V469.6a4.824,4.824,0,0,1-.162,1.443,1.787,1.787,0,0,1-.451.75,2.016,2.016,0,0,1-.776.426,4.134,4.134,0,0,1-1.227.154,3.208,3.208,0,0,1-1.988-.475,1.487,1.487,0,0,1-.586-1.2C431.27,470.646,431.272,470.588,431.275,470.52Zm1.3-3.2a1.955,1.955,0,0,0,.34,1.271,1.056,1.056,0,0,0,.838.4,1.164,1.164,0,0,0,.9-.413,1.79,1.79,0,0,0,.369-1.225,1.9,1.9,0,0,0-.353-1.258,1.128,1.128,0,0,0-.893-.411,1.082,1.082,0,0,0-.864.4A1.881,1.881,0,0,0,432.578,467.321Z"
                                                  transform="translate(-296.188 -462.632)" fill="#80c42b"/>
                                            <path id="Path_2023" data-name="Path 2023"
                                                  d="M450.063,473.574v-1.443h1.458v1.443Z"
                                                  transform="translate(-304.774 -466.046)" fill="#80c42b"/>
                                            <path id="Path_2024" data-name="Path 2024"
                                                  d="M461.368,467.357a2.924,2.924,0,0,1,.358-1.392,2.447,2.447,0,0,1,1.014-1.027,3.046,3.046,0,0,1,1.465-.354,2.884,2.884,0,0,1,2.042,4.885,2.74,2.74,0,0,1-2.031.814,3.222,3.222,0,0,1-1.445-.339,2.337,2.337,0,0,1-1.045-.993A3.28,3.28,0,0,1,461.368,467.357Zm1.494.077a1.792,1.792,0,0,0,.389,1.242,1.281,1.281,0,0,0,1.917,0,1.817,1.817,0,0,0,.387-1.252,1.784,1.784,0,0,0-.387-1.232,1.281,1.281,0,0,0-1.917,0A1.793,1.793,0,0,0,462.862,467.434Z"
                                                  transform="translate(-309.888 -462.632)" fill="#80c42b"/>
                                            <path id="Path_2025" data-name="Path 2025"
                                                  d="M481.711,470.16h-1.458v-5.453h1.354v.776a2.334,2.334,0,0,1,.625-.725,1.161,1.161,0,0,1,.63-.174,1.867,1.867,0,0,1,.959.272l-.451,1.258a1.3,1.3,0,0,0-.686-.237.817.817,0,0,0-.519.167,1.139,1.139,0,0,0-.335.6,8.47,8.47,0,0,0-.122,1.828Z"
                                                  transform="translate(-318.43 -462.632)" fill="#80c42b"/>
                                            <path id="Path_2026" data-name="Path 2026"
                                                  d="M494.043,470.52l1.665.2a.56.56,0,0,0,.192.4,1.108,1.108,0,0,0,.654.154,1.706,1.706,0,0,0,.856-.17.7.7,0,0,0,.291-.364,2.178,2.178,0,0,0,.067-.663v-.8a1.978,1.978,0,0,1-1.65.883,2.038,2.038,0,0,1-1.759-.929,3.137,3.137,0,0,1-.508-1.829,2.985,2.985,0,0,1,.667-2.1,2.161,2.161,0,0,1,1.658-.723,2.024,2.024,0,0,1,1.686.888v-.766h1.365V469.6a4.823,4.823,0,0,1-.162,1.443,1.792,1.792,0,0,1-.451.75,2.018,2.018,0,0,1-.777.426,4.128,4.128,0,0,1-1.226.154,3.208,3.208,0,0,1-1.988-.475,1.485,1.485,0,0,1-.586-1.2C494.038,470.646,494.039,470.588,494.043,470.52Zm1.3-3.2a1.954,1.954,0,0,0,.34,1.271,1.055,1.055,0,0,0,.838.4,1.164,1.164,0,0,0,.9-.413,1.79,1.79,0,0,0,.368-1.225,1.9,1.9,0,0,0-.352-1.258,1.129,1.129,0,0,0-.893-.411,1.082,1.082,0,0,0-.864.4A1.881,1.881,0,0,0,495.346,467.321Z"
                                                  transform="translate(-324.582 -462.632)" fill="#80c42b"/>
                                        </g>
                                        <g id="Group_347" data-name="Group 347" transform="translate(67.745 369.247)">
                                            <path id="Path_2027" data-name="Path 2027"
                                                  d="M112.333,383.64l-5.979-5.768L88.8,396.056l-5.564.1,20.4-21.134-5.979-5.773-29.915,31,30.048,29,5.771-5.978L83.844,404.235l5.643-.1,16.847,16.26,6.833-7.08L97.908,398.588Z"
                                                  transform="translate(-67.745 -369.247)" fill="#80c42b"/>
                                            <path id="Path_2028" data-name="Path 2028"
                                                  d="M145.308,401.148l-11.542,11.959,11.959,11.538,11.538-11.958Zm.267,15-3.306-3.192,3.189-3.3,3.3,3.188Z"
                                                  transform="translate(-97.61 -383.678)" fill="#80c42b"/>
                                        </g>
                                        <g id="Group_349" data-name="Group 349" transform="translate(131.828 415.794)">
                                            <g id="Group_348" data-name="Group 348">
                                                <rect id="Rectangle_107" data-name="Rectangle 107" width="174.645"
                                                      height="0.625" fill="#585857"/>
                                            </g>
                                        </g>
                                        <g id="Group_350" data-name="Group 350" transform="translate(133.205 397.664)">
                                            <path id="Path_2029" data-name="Path 2029"
                                                  d="M194.773,434.47a3.654,3.654,0,0,1-.025.473,1.183,1.183,0,0,1-.073.308.391.391,0,0,1-.123.171.263.263,0,0,1-.16.054h-6.352a.807.807,0,0,1-.543-.19.787.787,0,0,1-.22-.621V423.643a.787.787,0,0,1,.22-.621.807.807,0,0,1,.543-.19h6.313a.261.261,0,0,1,.157.049.381.381,0,0,1,.117.171,1.3,1.3,0,0,1,.074.313,4.016,4.016,0,0,1,.024.484,3.809,3.809,0,0,1-.024.463,1.229,1.229,0,0,1-.074.308.422.422,0,0,1-.117.171.248.248,0,0,1-.157.054h-4.515v3.088h3.821a.273.273,0,0,1,.162.054.408.408,0,0,1,.122.167,1.153,1.153,0,0,1,.074.3,3.857,3.857,0,0,1,.024.464,3.8,3.8,0,0,1-.024.469,1.144,1.144,0,0,1-.074.3.369.369,0,0,1-.122.162.287.287,0,0,1-.162.049h-3.821v3.567h4.554a.263.263,0,0,1,.16.053.4.4,0,0,1,.123.171,1.194,1.194,0,0,1,.073.308A3.663,3.663,0,0,1,194.773,434.47Z"
                                                  transform="translate(-187.277 -421.904)" fill="#80c42b"/>
                                            <path id="Path_2030" data-name="Path 2030"
                                                  d="M212.814,433.392q0,.244-.014.416a2.084,2.084,0,0,1-.044.294.817.817,0,0,1-.078.21,1.083,1.083,0,0,1-.157.2,1.969,1.969,0,0,1-.416.288,4.938,4.938,0,0,1-.752.337,6.685,6.685,0,0,1-2.252.376,6.513,6.513,0,0,1-2.346-.4,4.63,4.63,0,0,1-1.778-1.2,5.326,5.326,0,0,1-1.124-1.988,8.919,8.919,0,0,1-.391-2.775,8.781,8.781,0,0,1,.43-2.863,5.965,5.965,0,0,1,1.2-2.1,5.023,5.023,0,0,1,1.851-1.29,6.261,6.261,0,0,1,2.38-.44,5.74,5.74,0,0,1,1.016.088,6.214,6.214,0,0,1,.9.229,4.56,4.56,0,0,1,.748.328,2.235,2.235,0,0,1,.464.317,1.109,1.109,0,0,1,.181.22.808.808,0,0,1,.078.225,2.588,2.588,0,0,1,.044.322c.009.124.014.278.014.459s-.006.361-.02.5a1.276,1.276,0,0,1-.068.332.465.465,0,0,1-.117.186.233.233,0,0,1-.156.059.675.675,0,0,1-.371-.171,5.892,5.892,0,0,0-.582-.38,4.681,4.681,0,0,0-.849-.382,3.614,3.614,0,0,0-1.178-.17,2.784,2.784,0,0,0-2.35,1.187,4.144,4.144,0,0,0-.63,1.383,8.031,8.031,0,0,0,.015,3.757,3.737,3.737,0,0,0,.653,1.344,2.654,2.654,0,0,0,1.027.786,3.374,3.374,0,0,0,1.353.259,3.827,3.827,0,0,0,1.182-.162,4.89,4.89,0,0,0,.855-.356q.357-.2.587-.352a.731.731,0,0,1,.357-.156.28.28,0,0,1,.156.039.289.289,0,0,1,.1.157,1.591,1.591,0,0,1,.059.327C212.807,432.97,212.814,433.157,212.814,433.392Z"
                                                  transform="translate(-194.598 -421.734)" fill="#80c42b"/>
                                            <path id="Path_2031" data-name="Path 2031"
                                                  d="M234.525,428.822a9.367,9.367,0,0,1-.39,2.814,5.679,5.679,0,0,1-1.163,2.106,5.006,5.006,0,0,1-1.91,1.32,7.088,7.088,0,0,1-2.634.454,7.766,7.766,0,0,1-2.576-.387,4.452,4.452,0,0,1-1.832-1.177,5.05,5.05,0,0,1-1.1-2.013,10.165,10.165,0,0,1-.366-2.893,9.084,9.084,0,0,1,.39-2.76,5.639,5.639,0,0,1,1.163-2.087,5.079,5.079,0,0,1,1.911-1.319,7.018,7.018,0,0,1,2.643-.46,7.736,7.736,0,0,1,2.536.381,4.467,4.467,0,0,1,1.837,1.173,5.122,5.122,0,0,1,1.114,2A9.611,9.611,0,0,1,234.525,428.822Zm-2.7.137a9.671,9.671,0,0,0-.156-1.812,3.9,3.9,0,0,0-.532-1.4,2.552,2.552,0,0,0-1-.9,3.438,3.438,0,0,0-1.564-.317,3.205,3.205,0,0,0-1.583.356,2.914,2.914,0,0,0-1.026.953,3.919,3.919,0,0,0-.551,1.392,8.422,8.422,0,0,0-.162,1.686,10.087,10.087,0,0,0,.156,1.862,4,4,0,0,0,.528,1.411,2.435,2.435,0,0,0,1,.895,3.567,3.567,0,0,0,1.573.307,3.234,3.234,0,0,0,1.583-.352,2.834,2.834,0,0,0,1.026-.958,4.064,4.064,0,0,0,.553-1.412A8.784,8.784,0,0,0,231.828,428.959Z"
                                                  transform="translate(-203.235 -421.718)" fill="#80c42b"/>
                                            <path id="Path_2032" data-name="Path 2032"
                                                  d="M263.484,431.5a3.905,3.905,0,0,1-.371,1.749,3.611,3.611,0,0,1-1.007,1.255,4.383,4.383,0,0,1-1.485.758,6.306,6.306,0,0,1-1.818.254,6.465,6.465,0,0,1-1.216-.107,6.94,6.94,0,0,1-.992-.26,4.262,4.262,0,0,1-.719-.317,2.094,2.094,0,0,1-.415-.293.732.732,0,0,1-.18-.367,3.279,3.279,0,0,1-.054-.688c0-.2.006-.371.019-.508a1.55,1.55,0,0,1,.064-.332.372.372,0,0,1,.117-.18.279.279,0,0,1,.171-.054.822.822,0,0,1,.386.16,6.674,6.674,0,0,0,.64.358,5.906,5.906,0,0,0,.933.357,4.474,4.474,0,0,0,1.256.16,2.88,2.88,0,0,0,.841-.113,1.875,1.875,0,0,0,.631-.317,1.358,1.358,0,0,0,.4-.508,1.612,1.612,0,0,0,.136-.674,1.176,1.176,0,0,0-.234-.737,2.3,2.3,0,0,0-.61-.548,6.254,6.254,0,0,0-.855-.449q-.479-.211-.987-.445a9.169,9.169,0,0,1-.987-.532,4.027,4.027,0,0,1-.855-.714,3.361,3.361,0,0,1-.61-.982,3.537,3.537,0,0,1-.235-1.358,3.6,3.6,0,0,1,.337-1.6,3.287,3.287,0,0,1,.91-1.144,3.876,3.876,0,0,1,1.349-.679,5.88,5.88,0,0,1,1.646-.225,6.053,6.053,0,0,1,.9.068,6.193,6.193,0,0,1,.841.186,4.758,4.758,0,0,1,.694.264,1.644,1.644,0,0,1,.4.244.717.717,0,0,1,.133.167.823.823,0,0,1,.059.181,1.934,1.934,0,0,1,.034.283c.006.115.01.256.01.425,0,.19,0,.348-.015.479a1.736,1.736,0,0,1-.048.323.4.4,0,0,1-.1.186.245.245,0,0,1-.171.059.813.813,0,0,1-.342-.137c-.156-.091-.348-.19-.576-.3a5.764,5.764,0,0,0-.792-.294,3.526,3.526,0,0,0-.987-.131,2.321,2.321,0,0,0-.733.1,1.489,1.489,0,0,0-.522.282,1.11,1.11,0,0,0-.313.435,1.4,1.4,0,0,0-.1.538,1.181,1.181,0,0,0,.229.727,2.187,2.187,0,0,0,.617.547,6.431,6.431,0,0,0,.874.45q.489.211,1,.445a9.867,9.867,0,0,1,1,.532,3.987,3.987,0,0,1,.87.714,3.318,3.318,0,0,1,.616.977A3.424,3.424,0,0,1,263.484,431.5Z"
                                                  transform="translate(-218.015 -421.718)" fill="#80c42b"/>
                                            <path id="Path_2033" data-name="Path 2033"
                                                  d="M282,433.208a6.677,6.677,0,0,1-.293,2.033,4.3,4.3,0,0,1-.889,1.583,3.941,3.941,0,0,1-1.5,1.026,5.612,5.612,0,0,1-2.1.361,5.745,5.745,0,0,1-2.022-.323,3.634,3.634,0,0,1-1.427-.938,3.85,3.85,0,0,1-.84-1.515,7.051,7.051,0,0,1-.274-2.052,6.594,6.594,0,0,1,.3-2.037,4.274,4.274,0,0,1,.894-1.583,3.968,3.968,0,0,1,1.491-1.022,5.578,5.578,0,0,1,2.1-.361,5.873,5.873,0,0,1,2.032.317,3.565,3.565,0,0,1,1.422.934,3.887,3.887,0,0,1,.835,1.515A7.089,7.089,0,0,1,282,433.208Zm-2.531.1a6.5,6.5,0,0,0-.1-1.188,2.839,2.839,0,0,0-.347-.943,1.731,1.731,0,0,0-.65-.625,2.082,2.082,0,0,0-1.022-.225,2.148,2.148,0,0,0-.957.2,1.717,1.717,0,0,0-.675.586,2.787,2.787,0,0,0-.4.928,5.217,5.217,0,0,0-.132,1.237,6.1,6.1,0,0,0,.107,1.187,2.943,2.943,0,0,0,.347.944,1.646,1.646,0,0,0,.65.62,2.123,2.123,0,0,0,1.016.221,2.173,2.173,0,0,0,.967-.2,1.731,1.731,0,0,0,.675-.581,2.669,2.669,0,0,0,.39-.924A5.452,5.452,0,0,0,279.471,433.306Z"
                                                  transform="translate(-225.901 -424.414)" fill="#80c42b"/>
                                            <path id="Path_2034" data-name="Path 2034"
                                                  d="M301.5,437.792a.3.3,0,0,1-.049.17.355.355,0,0,1-.172.123,1.269,1.269,0,0,1-.321.073,4.134,4.134,0,0,1-.5.025,4.337,4.337,0,0,1-.523-.025,1.214,1.214,0,0,1-.317-.073.354.354,0,0,1-.166-.123.3.3,0,0,1-.049-.17v-1.007a5.542,5.542,0,0,1-1.47,1.173,3.308,3.308,0,0,1-1.549.391,3.477,3.477,0,0,1-1.51-.293,2.609,2.609,0,0,1-.986-.8,3.226,3.226,0,0,1-.538-1.177,7.342,7.342,0,0,1-.16-1.666v-5.336a.273.273,0,0,1,.054-.17.385.385,0,0,1,.195-.123,1.713,1.713,0,0,1,.381-.073,7.165,7.165,0,0,1,1.188,0,1.7,1.7,0,0,1,.376.073.424.424,0,0,1,.2.123.261.261,0,0,1,.059.17V434a4.709,4.709,0,0,0,.088,1.045,1.829,1.829,0,0,0,.269.621,1.23,1.23,0,0,0,.458.4,1.413,1.413,0,0,0,.65.142,1.607,1.607,0,0,0,.944-.342,5.676,5.676,0,0,0,1.011-1v-5.8a.279.279,0,0,1,.053-.17.4.4,0,0,1,.2-.123,1.718,1.718,0,0,1,.377-.073,7.218,7.218,0,0,1,1.192,0,1.623,1.623,0,0,1,.371.073.425.425,0,0,1,.2.123.264.264,0,0,1,.059.17Z"
                                                  transform="translate(-235.184 -424.551)" fill="#80c42b"/>
                                            <path id="Path_2035" data-name="Path 2035"
                                                  d="M318.48,429.749c0,.235-.007.427-.02.577a1.807,1.807,0,0,1-.059.352.346.346,0,0,1-.1.176.255.255,0,0,1-.162.049.532.532,0,0,1-.176-.034c-.065-.022-.139-.047-.22-.073s-.171-.051-.268-.073a1.381,1.381,0,0,0-.323-.035,1.1,1.1,0,0,0-.41.083,1.765,1.765,0,0,0-.426.26,3.2,3.2,0,0,0-.459.469,7.545,7.545,0,0,0-.513.723v5.433a.264.264,0,0,1-.059.17.426.426,0,0,1-.2.123,1.7,1.7,0,0,1-.376.073,7.061,7.061,0,0,1-1.192,0,1.7,1.7,0,0,1-.376-.073.426.426,0,0,1-.2-.123.264.264,0,0,1-.059-.17v-8.717a.3.3,0,0,1,.049-.17.359.359,0,0,1,.176-.123,1.36,1.36,0,0,1,.327-.073,4.128,4.128,0,0,1,.5-.025,4.355,4.355,0,0,1,.518.025,1.141,1.141,0,0,1,.317.073.353.353,0,0,1,.162.123.3.3,0,0,1,.049.17v1.085a6.983,6.983,0,0,1,.645-.806,3.617,3.617,0,0,1,.577-.5,1.788,1.788,0,0,1,.547-.258,2.069,2.069,0,0,1,.548-.074,2.446,2.446,0,0,1,.273.015,2.574,2.574,0,0,1,.3.048,2.274,2.274,0,0,1,.274.078.556.556,0,0,1,.171.093.316.316,0,0,1,.078.108.866.866,0,0,1,.043.162,2.159,2.159,0,0,1,.03.307C318.476,429.329,318.48,429.515,318.48,429.749Z"
                                                  transform="translate(-244.095 -424.414)" fill="#80c42b"/>
                                            <path id="Path_2036" data-name="Path 2036"
                                                  d="M331.514,436.275c0,.17,0,.311-.015.425a2.167,2.167,0,0,1-.039.289.876.876,0,0,1-.059.191.706.706,0,0,1-.151.185,2.073,2.073,0,0,1-.4.274,3.866,3.866,0,0,1-.645.289,5.341,5.341,0,0,1-.786.205,4.85,4.85,0,0,1-.875.078,4.742,4.742,0,0,1-1.783-.313,3.34,3.34,0,0,1-1.3-.924,4.068,4.068,0,0,1-.8-1.49,6.917,6.917,0,0,1-.268-2.013,7.012,7.012,0,0,1,.326-2.263,4.34,4.34,0,0,1,.915-1.578,3.646,3.646,0,0,1,1.388-.928,4.94,4.94,0,0,1,1.758-.3,4.285,4.285,0,0,1,.767.068,4.37,4.37,0,0,1,.7.186,3.743,3.743,0,0,1,.587.264,1.77,1.77,0,0,1,.367.254,1.092,1.092,0,0,1,.152.18.642.642,0,0,1,.067.191,2.15,2.15,0,0,1,.039.289c.01.114.015.252.015.415a2.278,2.278,0,0,1-.1.8q-.1.229-.254.229a.6.6,0,0,1-.352-.137c-.124-.091-.271-.192-.44-.3a3.277,3.277,0,0,0-.606-.3,2.311,2.311,0,0,0-.84-.137,1.671,1.671,0,0,0-1.466.738,3.817,3.817,0,0,0-.508,2.165,5.45,5.45,0,0,0,.127,1.241,2.664,2.664,0,0,0,.376.9,1.594,1.594,0,0,0,.625.542,2,2,0,0,0,.875.181,2.3,2.3,0,0,0,.875-.151,3.633,3.633,0,0,0,.64-.337q.274-.186.459-.338a.546.546,0,0,1,.313-.151.218.218,0,0,1,.146.049.343.343,0,0,1,.093.18,2.228,2.228,0,0,1,.054.338C331.507,435.884,331.514,436.06,331.514,436.275Z"
                                                  transform="translate(-249.301 -424.422)" fill="#80c42b"/>
                                            <path id="Path_2037" data-name="Path 2037"
                                                  d="M343.14,422.96a1.381,1.381,0,0,1-.3,1.026,2.395,2.395,0,0,1-2.243.01,1.335,1.335,0,0,1-.289-.987,1.418,1.418,0,0,1,.3-1.032,2.363,2.363,0,0,1,2.243-.009A1.34,1.34,0,0,1,343.14,422.96Zm-.186,11.668a.264.264,0,0,1-.059.17.426.426,0,0,1-.2.123,1.692,1.692,0,0,1-.376.073,7.07,7.07,0,0,1-1.192,0,1.7,1.7,0,0,1-.376-.073.426.426,0,0,1-.2-.123.264.264,0,0,1-.059-.17v-8.7a.264.264,0,0,1,.059-.17.457.457,0,0,1,.2-.128,1.694,1.694,0,0,1,.376-.083,6.13,6.13,0,0,1,1.192,0,1.686,1.686,0,0,1,.376.083.457.457,0,0,1,.2.128.264.264,0,0,1,.059.17Z"
                                                  transform="translate(-256.501 -421.387)" fill="#80c42b"/>
                                            <path id="Path_2038" data-name="Path 2038"
                                                  d="M357.947,437.655a.264.264,0,0,1-.059.17.425.425,0,0,1-.2.123,1.635,1.635,0,0,1-.377.073,5.732,5.732,0,0,1-.591.025,5.886,5.886,0,0,1-.6-.025,1.637,1.637,0,0,1-.376-.073.422.422,0,0,1-.2-.123.264.264,0,0,1-.059-.17v-4.974a4.194,4.194,0,0,0-.093-1,1.964,1.964,0,0,0-.268-.62,1.187,1.187,0,0,0-.455-.4,1.4,1.4,0,0,0-.65-.142,1.627,1.627,0,0,0-.948.342,5.135,5.135,0,0,0-1,1v5.795a.261.261,0,0,1-.059.17.424.424,0,0,1-.2.123,1.7,1.7,0,0,1-.376.073,7.061,7.061,0,0,1-1.192,0,1.7,1.7,0,0,1-.376-.073.424.424,0,0,1-.2-.123.261.261,0,0,1-.059-.17v-8.717a.3.3,0,0,1,.049-.17.357.357,0,0,1,.176-.123,1.366,1.366,0,0,1,.328-.073,4.122,4.122,0,0,1,.5-.025,4.362,4.362,0,0,1,.518.025,1.151,1.151,0,0,1,.317.073.354.354,0,0,1,.162.123.3.3,0,0,1,.049.17v1.007a5.535,5.535,0,0,1,1.47-1.172,3.329,3.329,0,0,1,1.559-.391,3.455,3.455,0,0,1,1.5.294,2.609,2.609,0,0,1,.986.8,3.212,3.212,0,0,1,.543,1.177,6.845,6.845,0,0,1,.167,1.622Z"
                                                  transform="translate(-260.715 -424.414)" fill="#80c42b"/>
                                            <path id="Path_2039" data-name="Path 2039"
                                                  d="M376.289,429.577a1.682,1.682,0,0,1-.108.694c-.072.15-.16.225-.264.225h-1.026a1.4,1.4,0,0,1,.308.533,2.088,2.088,0,0,1,.092.62,3.409,3.409,0,0,1-.254,1.354,2.7,2.7,0,0,1-.729,1,3.19,3.19,0,0,1-1.142.614,4.944,4.944,0,0,1-1.491.211,3.093,3.093,0,0,1-.8-.1,2.04,2.04,0,0,1-.587-.24,1.406,1.406,0,0,0-.209.3.821.821,0,0,0-.094.391.551.551,0,0,0,.25.465,1.278,1.278,0,0,0,.7.2l2.12.078a5.144,5.144,0,0,1,1.325.211,2.828,2.828,0,0,1,.977.508,2.2,2.2,0,0,1,.606.786,2.505,2.505,0,0,1,.209,1.046,2.707,2.707,0,0,1-.283,1.217,2.772,2.772,0,0,1-.85.991,4.4,4.4,0,0,1-1.422.665,7.344,7.344,0,0,1-2,.244,8.85,8.85,0,0,1-1.9-.176,4.177,4.177,0,0,1-1.29-.489,1.905,1.905,0,0,1-.962-1.7,2.274,2.274,0,0,1,.082-.616,2.307,2.307,0,0,1,.245-.561,2.82,2.82,0,0,1,.4-.513,4.355,4.355,0,0,1,.537-.469,1.644,1.644,0,0,1-.65-.611,1.622,1.622,0,0,1-.229-.845,2.127,2.127,0,0,1,.264-1.051,3.362,3.362,0,0,1,.684-.854,2.593,2.593,0,0,1-.547-.821,3.033,3.033,0,0,1-.205-1.192,3.312,3.312,0,0,1,.268-1.363,2.881,2.881,0,0,1,.748-1.016,3.318,3.318,0,0,1,1.143-.636,4.674,4.674,0,0,1,1.466-.219,6.039,6.039,0,0,1,.786.049,5.255,5.255,0,0,1,.7.137h2.765c.111,0,.2.072.268.215A1.8,1.8,0,0,1,376.289,429.577Zm-2.58,9.02a.762.762,0,0,0-.352-.675,1.844,1.844,0,0,0-.967-.254l-1.749-.049a2.509,2.509,0,0,0-.405.357,2.243,2.243,0,0,0-.245.327.986.986,0,0,0-.122.3,1.536,1.536,0,0,0-.029.3.8.8,0,0,0,.494.743,3.094,3.094,0,0,0,1.392.254,3.35,3.35,0,0,0,.938-.113,1.815,1.815,0,0,0,.616-.3,1.06,1.06,0,0,0,.332-.415A1.227,1.227,0,0,0,373.709,438.6Zm-.577-6.958a1.479,1.479,0,0,0-.391-1.095,1.494,1.494,0,0,0-1.1-.391,1.552,1.552,0,0,0-.635.122,1.281,1.281,0,0,0-.454.332,1.379,1.379,0,0,0-.269.489,1.926,1.926,0,0,0-.088.582,1.409,1.409,0,0,0,.391,1.051,1.477,1.477,0,0,0,1.084.385,1.623,1.623,0,0,0,.645-.117,1.247,1.247,0,0,0,.454-.323,1.388,1.388,0,0,0,.274-.473A1.714,1.714,0,0,0,373.133,431.639Z"
                                                  transform="translate(-268.787 -424.446)" fill="#80c42b"/>
                                            <path id="Path_2040" data-name="Path 2040"
                                                  d="M402.035,434.354a4.026,4.026,0,0,1-.025.494,1.482,1.482,0,0,1-.073.332.436.436,0,0,1-.122.19.266.266,0,0,1-.171.059h-5.795a.806.806,0,0,1-.543-.19.785.785,0,0,1-.22-.621V423.135a.277.277,0,0,1,.063-.176.449.449,0,0,1,.21-.127,2.071,2.071,0,0,1,.4-.078,6.706,6.706,0,0,1,1.246,0,2.043,2.043,0,0,1,.391.078.446.446,0,0,1,.21.127.274.274,0,0,1,.064.176v10.173h3.977a.276.276,0,0,1,.171.054.385.385,0,0,1,.122.176,1.276,1.276,0,0,1,.073.321A4.026,4.026,0,0,1,402.035,434.354Z"
                                                  transform="translate(-281.282 -421.855)" fill="#80c42b"/>
                                            <path id="Path_2041" data-name="Path 2041"
                                                  d="M412.767,422.96a1.379,1.379,0,0,1-.3,1.026,2.394,2.394,0,0,1-2.242.01,1.335,1.335,0,0,1-.289-.987,1.42,1.42,0,0,1,.3-1.032,2.363,2.363,0,0,1,2.243-.009A1.34,1.34,0,0,1,412.767,422.96Zm-.186,11.668a.264.264,0,0,1-.059.17.426.426,0,0,1-.2.123,1.7,1.7,0,0,1-.376.073,7.07,7.07,0,0,1-1.192,0,1.692,1.692,0,0,1-.376-.073.423.423,0,0,1-.2-.123.264.264,0,0,1-.059-.17v-8.7a.264.264,0,0,1,.059-.17.453.453,0,0,1,.2-.128,1.687,1.687,0,0,1,.376-.083,6.13,6.13,0,0,1,1.192,0,1.694,1.694,0,0,1,.376.083.457.457,0,0,1,.2.128.264.264,0,0,1,.059.17Z"
                                                  transform="translate(-287.998 -421.387)" fill="#80c42b"/>
                                            <path id="Path_2042" data-name="Path 2042"
                                                  d="M433.114,437.655a.264.264,0,0,1-.059.17.425.425,0,0,1-.2.123,1.623,1.623,0,0,1-.371.073,5.729,5.729,0,0,1-.6.025,5.839,5.839,0,0,1-.606-.025,1.725,1.725,0,0,1-.377-.073.4.4,0,0,1-.2-.123.279.279,0,0,1-.053-.17v-5.169a3.557,3.557,0,0,0-.083-.8,1.878,1.878,0,0,0-.254-.62,1.192,1.192,0,0,0-.43-.4,1.262,1.262,0,0,0-.62-.142,1.469,1.469,0,0,0-.889.342,5.944,5.944,0,0,0-.977,1v5.795a.264.264,0,0,1-.059.17.425.425,0,0,1-.2.123,1.718,1.718,0,0,1-.377.073,5.592,5.592,0,0,1-.586.025,5.729,5.729,0,0,1-.6-.025,1.7,1.7,0,0,1-.376-.073.426.426,0,0,1-.2-.123.264.264,0,0,1-.059-.17v-5.169a3.557,3.557,0,0,0-.083-.8,1.959,1.959,0,0,0-.249-.62,1.15,1.15,0,0,0-.43-.4,1.273,1.273,0,0,0-.616-.142,1.479,1.479,0,0,0-.9.342,5.692,5.692,0,0,0-.968,1v5.795a.264.264,0,0,1-.059.17.423.423,0,0,1-.2.123,1.694,1.694,0,0,1-.376.073,7.061,7.061,0,0,1-1.192,0,1.7,1.7,0,0,1-.376-.073.429.429,0,0,1-.2-.123.264.264,0,0,1-.059-.17v-8.717a.3.3,0,0,1,.049-.17.357.357,0,0,1,.176-.123,1.36,1.36,0,0,1,.327-.073,4.131,4.131,0,0,1,.5-.025,4.351,4.351,0,0,1,.518.025,1.141,1.141,0,0,1,.317.073.355.355,0,0,1,.162.123.3.3,0,0,1,.049.17v1.007a5.741,5.741,0,0,1,1.442-1.172,3.1,3.1,0,0,1,1.5-.391,3.8,3.8,0,0,1,.967.113,2.75,2.75,0,0,1,.767.321,2.407,2.407,0,0,1,.582.5,2.931,2.931,0,0,1,.41.655,7.488,7.488,0,0,1,.767-.723,4.689,4.689,0,0,1,.743-.494,3.406,3.406,0,0,1,.734-.282,3,3,0,0,1,.747-.094,3.3,3.3,0,0,1,1.466.294,2.556,2.556,0,0,1,.962.8,3.167,3.167,0,0,1,.523,1.177,6.311,6.311,0,0,1,.157,1.427Z"
                                                  transform="translate(-292.211 -424.414)" fill="#80c42b"/>
                                            <path id="Path_2043" data-name="Path 2043"
                                                  d="M451.47,422.96a1.381,1.381,0,0,1-.3,1.026,2.395,2.395,0,0,1-2.243.01,1.335,1.335,0,0,1-.289-.987,1.418,1.418,0,0,1,.3-1.032,2.363,2.363,0,0,1,2.243-.009A1.34,1.34,0,0,1,451.47,422.96Zm-.186,11.668a.264.264,0,0,1-.059.17.426.426,0,0,1-.2.123,1.692,1.692,0,0,1-.376.073,7.069,7.069,0,0,1-1.192,0,1.7,1.7,0,0,1-.376-.073.426.426,0,0,1-.2-.123.264.264,0,0,1-.059-.17v-8.7a.264.264,0,0,1,.059-.17.457.457,0,0,1,.2-.128,1.693,1.693,0,0,1,.376-.083,6.13,6.13,0,0,1,1.192,0,1.686,1.686,0,0,1,.376.083.457.457,0,0,1,.2.128.263.263,0,0,1,.059.17Z"
                                                  transform="translate(-305.505 -421.387)" fill="#80c42b"/>
                                            <path id="Path_2044" data-name="Path 2044"
                                                  d="M462.279,435.035a3.088,3.088,0,0,1-.053.659.725.725,0,0,1-.142.324.766.766,0,0,1-.259.165,2.406,2.406,0,0,1-.4.128,4.3,4.3,0,0,1-.5.083,4.948,4.948,0,0,1-.542.03,3.912,3.912,0,0,1-1.271-.186,2.12,2.12,0,0,1-.889-.571,2.393,2.393,0,0,1-.518-.973,5.132,5.132,0,0,1-.166-1.388V428.84h-1.045q-.186,0-.284-.229a2.158,2.158,0,0,1-.1-.767,3.932,3.932,0,0,1,.024-.479,1.158,1.158,0,0,1,.073-.308.37.37,0,0,1,.122-.16.3.3,0,0,1,.172-.049h1.036V424.9a.3.3,0,0,1,.054-.176.4.4,0,0,1,.2-.133,1.59,1.59,0,0,1,.38-.078q.24-.024.591-.024c.241,0,.442.008.6.024a1.518,1.518,0,0,1,.377.078.438.438,0,0,1,.2.133.287.287,0,0,1,.059.176v1.945h1.9a.3.3,0,0,1,.17.049.355.355,0,0,1,.123.16,1.139,1.139,0,0,1,.073.308,3.812,3.812,0,0,1,.025.479,2.158,2.158,0,0,1-.1.767q-.1.229-.284.229h-1.905v4.094a2.039,2.039,0,0,0,.225,1.071.873.873,0,0,0,.8.357,1.636,1.636,0,0,0,.352-.035,1.97,1.97,0,0,0,.278-.078c.082-.028.15-.055.206-.078a.4.4,0,0,1,.151-.034.206.206,0,0,1,.112.034.229.229,0,0,1,.084.137,2.163,2.163,0,0,1,.053.279A3.257,3.257,0,0,1,462.279,435.035Z"
                                                  transform="translate(-308.888 -422.654)" fill="#80c42b"/>
                                            <path id="Path_2045" data-name="Path 2045"
                                                  d="M477.806,433.043a.945.945,0,0,1-.2.664.717.717,0,0,1-.551.215h-5.306a3.648,3.648,0,0,0,.131,1.011,1.938,1.938,0,0,0,.421.773,1.821,1.821,0,0,0,.743.483,3.164,3.164,0,0,0,1.089.166,6.084,6.084,0,0,0,1.134-.094,7.3,7.3,0,0,0,.846-.2c.237-.076.434-.143.591-.206a1.105,1.105,0,0,1,.381-.093.289.289,0,0,1,.146.034.254.254,0,0,1,.1.123.864.864,0,0,1,.054.249c.009.108.014.244.014.406,0,.143,0,.266-.01.367a2.159,2.159,0,0,1-.03.258.619.619,0,0,1-.059.181.629.629,0,0,1-.1.142,1.172,1.172,0,0,1-.347.186,5.489,5.489,0,0,1-.723.23,10.017,10.017,0,0,1-1.007.2,8.371,8.371,0,0,1-1.212.083,6.406,6.406,0,0,1-2.037-.293,3.631,3.631,0,0,1-1.462-.889,3.711,3.711,0,0,1-.875-1.5,7.08,7.08,0,0,1-.288-2.13,6.786,6.786,0,0,1,.3-2.1,4.392,4.392,0,0,1,.879-1.583,3.793,3.793,0,0,1,1.4-.991,4.817,4.817,0,0,1,1.861-.342,4.877,4.877,0,0,1,1.872.323,3.4,3.4,0,0,1,1.275.894,3.621,3.621,0,0,1,.733,1.354,5.906,5.906,0,0,1,.234,1.7Zm-2.384-.7a2.527,2.527,0,0,0-.416-1.628,1.607,1.607,0,0,0-1.372-.591,1.78,1.78,0,0,0-.817.176,1.668,1.668,0,0,0-.576.473,2.149,2.149,0,0,0-.352.7,3.508,3.508,0,0,0-.142.865Z"
                                                  transform="translate(-314.829 -424.414)" fill="#80c42b"/>
                                            <path id="Path_2046" data-name="Path 2046"
                                                  d="M496.427,434.378a.32.32,0,0,1-.049.181.363.363,0,0,1-.166.122,1.127,1.127,0,0,1-.318.068q-.2.02-.493.02c-.209,0-.377-.007-.508-.02a1.174,1.174,0,0,1-.317-.068.4.4,0,0,1-.176-.122.3.3,0,0,1-.054-.181v-.968a5.628,5.628,0,0,1-1.412,1.124,3.7,3.7,0,0,1-3.323.014,3.21,3.21,0,0,1-1.124-1.04,4.523,4.523,0,0,1-.63-1.529,8.4,8.4,0,0,1-.2-1.842,8.038,8.038,0,0,1,.25-2.091,4.709,4.709,0,0,1,.727-1.583,3.224,3.224,0,0,1,1.192-1.007,3.659,3.659,0,0,1,1.642-.352,2.937,2.937,0,0,1,1.329.3,4.733,4.733,0,0,1,1.182.875v-4.72a.3.3,0,0,1,.053-.176.4.4,0,0,1,.2-.131,1.641,1.641,0,0,1,.377-.084,6.136,6.136,0,0,1,1.192,0,1.553,1.553,0,0,1,.371.084.432.432,0,0,1,.2.131.287.287,0,0,1,.059.176Zm-2.443-5.824a5.461,5.461,0,0,0-1.011-1.016,1.7,1.7,0,0,0-1.012-.352,1.415,1.415,0,0,0-.825.24,1.744,1.744,0,0,0-.558.64,3.493,3.493,0,0,0-.313.894,5.034,5.034,0,0,0-.1,1.011,6.355,6.355,0,0,0,.088,1.065,3.353,3.353,0,0,0,.288.924,1.757,1.757,0,0,0,.533.649,1.383,1.383,0,0,0,.841.244,1.7,1.7,0,0,0,.494-.073,1.907,1.907,0,0,0,.488-.24,3.308,3.308,0,0,0,.513-.435q.264-.269.577-.659Z"
                                                  transform="translate(-323.159 -421.137)" fill="#80c42b"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className="footer-left__locations">
                                <ul>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.082" height="15"
                                             viewBox="0 0 11.082 15">
                                            <g id="_004-pin" data-name="004-pin" transform="translate(-1.959)">
                                                <g id="Group_360" data-name="Group 360">
                                                    <g id="Group_359" data-name="Group 359">
                                                        <path id="Path_2047" data-name="Path 2047"
                                                              d="M7.5,0A5.547,5.547,0,0,0,1.959,5.541a6.393,6.393,0,0,0,.47,2.133,8.936,8.936,0,0,0,.64,1.2l3.8,5.757a.723.723,0,0,0,1.263,0l3.8-5.757a8.9,8.9,0,0,0,.64-1.2,6.391,6.391,0,0,0,.47-2.133A5.547,5.547,0,0,0,7.5,0Zm4.47,7.421a8.188,8.188,0,0,1-.584,1.092l-3.8,5.757c-.075.114-.1.114-.174,0l-3.8-5.757A8.191,8.191,0,0,1,3.03,7.421a5.8,5.8,0,0,1-.419-1.88,4.889,4.889,0,0,1,9.778,0A5.808,5.808,0,0,1,11.97,7.421Z"
                                                              fill="#fff"/>
                                                        <path id="Path_2048" data-name="Path 2048"
                                                              d="M7.5,2.608a2.934,2.934,0,1,0,2.934,2.934A2.937,2.937,0,0,0,7.5,2.608Zm0,5.215A2.282,2.282,0,1,1,9.782,5.541,2.284,2.284,0,0,1,7.5,7.823Z"
                                                              fill="#fff"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>

                                        <p>Tower of Aakash, 14th Floor, Plot: 54, <br/>Road: 132, Gulshan-1,
                                            Dhaka-1212, <br/>Bangladesh</p>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11.25"
                                             viewBox="0 0 15 11.25">
                                            <g id="_001-envelope" data-name="001-envelope" transform="translate(0 -1.875)">
                                                <g id="Group_361" data-name="Group 361">
                                                    <path id="Path_2049" data-name="Path 2049"
                                                          d="M13.75,1.875H1.25A1.251,1.251,0,0,0,0,3.125v8.75a1.251,1.251,0,0,0,1.25,1.25h12.5A1.251,1.251,0,0,0,15,11.875V3.125A1.251,1.251,0,0,0,13.75,1.875ZM1.25,2.5h12.5a.6.6,0,0,1,.13.026c-1.082.99-4.671,4.273-5.926,5.4a.627.627,0,0,1-.907,0C5.791,6.8,2.2,3.516,1.12,2.526A.6.6,0,0,1,1.25,2.5ZM.625,11.875V3.125A.6.6,0,0,1,.66,2.952C1.488,3.71,3.992,6,5.616,7.477,4,8.867,1.493,11.242.658,12.038A.6.6,0,0,1,.625,11.875ZM13.75,12.5H1.25a.6.6,0,0,1-.141-.029c.863-.822,3.383-3.211,4.973-4.571l.546.494a1.248,1.248,0,0,0,1.744,0L8.918,7.9c1.59,1.36,4.11,3.748,4.973,4.571A.6.6,0,0,1,13.75,12.5Zm.625-.625a.6.6,0,0,1-.033.163c-.835-.8-3.339-3.171-4.958-4.561C11.008,6,13.511,3.711,14.34,2.952a.6.6,0,0,1,.035.173Z"
                                                          fill="#fff"/>
                                                </g>
                                            </g>
                                        </svg>

                                        <p><a href="mailto:info@ecosourcing.org">info@ecosourcing.org</a></p>
                                    </li>
                                    <li>
                                        <svg id="_003-fax" data-name="003-fax" xmlns="http://www.w3.org/2000/svg" width="15"
                                             height="15" viewBox="0 0 15 15">
                                            <path id="Path_2057" data-name="Path 2057"
                                                  d="M11.016,3.105a.293.293,0,1,0,.293.293A.293.293,0,0,0,11.016,3.105Zm0,0"
                                                  fill="#fff"/>
                                            <path id="Path_2058" data-name="Path 2058"
                                                  d="M14.121,3.691H13.066V.293A.293.293,0,0,0,12.773,0H6.914a.293.293,0,0,0-.293.293v3.4H5.244A1.467,1.467,0,0,0,3.809,2.52H3.223A1.467,1.467,0,0,0,1.787,3.691H.879A.88.88,0,0,0,0,4.57v8.379a.88.88,0,0,0,.879.879h.908A1.467,1.467,0,0,0,3.223,15h.586a1.467,1.467,0,0,0,1.435-1.172h8.877A.88.88,0,0,0,15,12.949V4.57A.88.88,0,0,0,14.121,3.691ZM7.207.586H12.48V4.863H7.207ZM.586,12.949V4.57a.293.293,0,0,1,.293-.293h.879v8.965H.879A.293.293,0,0,1,.586,12.949Zm3.223,1.465H3.223a.88.88,0,0,1-.879-.879V3.984a.88.88,0,0,1,.879-.879h.586a.88.88,0,0,1,.879.879v9.551A.88.88,0,0,1,3.809,14.414Zm10.605-1.465a.293.293,0,0,1-.293.293H5.273V4.277H6.621v.879a.293.293,0,0,0,.293.293h5.859a.293.293,0,0,0,.293-.293V4.277h1.055a.293.293,0,0,1,.293.293Zm0,0"
                                                  fill="#fff"/>
                                            <path id="Path_2059" data-name="Path 2059"
                                                  d="M12.773,6.035H6.914a.293.293,0,0,0-.293.293V8.086a.293.293,0,0,0,.293.293h5.859a.293.293,0,0,0,.293-.293V6.328A.293.293,0,0,0,12.773,6.035ZM12.48,7.793H7.207V6.621H12.48Zm0,0"
                                                  fill="#fff"/>
                                            <path id="Path_2060" data-name="Path 2060"
                                                  d="M8.086,8.965H6.914a.293.293,0,0,0,0,.586H8.086a.293.293,0,0,0,0-.586Zm0,0"
                                                  fill="#fff"/>
                                            <path id="Path_2061" data-name="Path 2061"
                                                  d="M8.086,10.137H6.914a.293.293,0,0,0,0,.586H8.086a.293.293,0,0,0,0-.586Zm0,0"
                                                  fill="#fff"/>
                                            <path id="Path_2062" data-name="Path 2062"
                                                  d="M8.086,11.309H6.914a.293.293,0,0,0,0,.586H8.086a.293.293,0,0,0,0-.586Zm0,0"
                                                  fill="#fff"/>
                                            <path id="Path_2063" data-name="Path 2063"
                                                  d="M10.43,8.965H9.258a.293.293,0,0,0,0,.586H10.43a.293.293,0,0,0,0-.586Zm0,0"
                                                  fill="#fff"/>
                                            <path id="Path_2064" data-name="Path 2064"
                                                  d="M10.43,10.137H9.258a.293.293,0,0,0,0,.586H10.43a.293.293,0,0,0,0-.586Zm0,0"
                                                  fill="#fff"/>
                                            <path id="Path_2065" data-name="Path 2065"
                                                  d="M10.43,11.309H9.258a.293.293,0,0,0,0,.586H10.43a.293.293,0,0,0,0-.586Zm0,0"
                                                  fill="#fff"/>
                                            <path id="Path_2066" data-name="Path 2066"
                                                  d="M12.773,8.965H11.6a.293.293,0,0,0,0,.586h1.172a.293.293,0,0,0,0-.586Zm0,0"
                                                  fill="#fff"/>
                                            <path id="Path_2067" data-name="Path 2067"
                                                  d="M12.773,10.137H11.6a.293.293,0,0,0,0,.586h1.172a.293.293,0,0,0,0-.586Zm0,0"
                                                  fill="#fff"/>
                                            <path id="Path_2068" data-name="Path 2068"
                                                  d="M12.773,11.309H11.6a.293.293,0,0,0,0,.586h1.172a.293.293,0,0,0,0-.586Zm0,0"
                                                  fill="#fff"/>
                                            <path id="Path_2069" data-name="Path 2069"
                                                  d="M8.672,2.52h2.344a.293.293,0,0,0,0-.586H8.672a.293.293,0,0,0,0,.586Zm0,0"
                                                  fill="#fff"/>
                                            <path id="Path_2070" data-name="Path 2070"
                                                  d="M8.672,3.691H9.844a.293.293,0,0,0,0-.586H8.672a.293.293,0,0,0,0,.586Zm0,0"
                                                  fill="#fff"/>
                                        </svg>
                                        <p><a href="tel:+88 096 7811 3366">+88 096 7811 3366</a></p>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.943" height="15"
                                             viewBox="0 0 14.943 15">
                                            <g id="_002-telephone" data-name="002-telephone" transform="translate(-0.028)">
                                                <path id="Path_2050" data-name="Path 2050"
                                                      d="M3.057,9.971A15.913,15.913,0,0,0,8.35,14.117a8.238,8.238,0,0,0,2.962.877c.071,0,.14.006.211.006a2.453,2.453,0,0,0,1.9-.817.068.068,0,0,0,.012-.016,8.009,8.009,0,0,1,.6-.622c.146-.14.3-.286.438-.435a1.5,1.5,0,0,0-.006-2.235L12.6,9.007a1.512,1.512,0,0,0-1.094-.5A1.563,1.563,0,0,0,10.4,9L9.288,10.117c-.1-.059-.208-.112-.308-.162a3.843,3.843,0,0,1-.342-.186A11.776,11.776,0,0,1,5.826,7.208a6.782,6.782,0,0,1-.951-1.517c.292-.264.566-.541.83-.811.093-.1.19-.193.286-.289a1.593,1.593,0,0,0,.516-1.119,1.581,1.581,0,0,0-.516-1.119l-.926-.926c-.109-.109-.211-.214-.317-.323C4.542.892,4.328.674,4.116.479A1.55,1.55,0,0,0,3.022,0,1.6,1.6,0,0,0,1.916.482L.753,1.644A2.388,2.388,0,0,0,.042,3.173,5.752,5.752,0,0,0,.474,5.66,14.466,14.466,0,0,0,3.057,9.971ZM.8,3.239a1.644,1.644,0,0,1,.494-1.057L2.45,1.026A.837.837,0,0,1,3.022.762a.794.794,0,0,1,.559.27c.208.193.4.395.615.609.106.109.214.218.323.329l.926.926a.844.844,0,0,1,.292.581.844.844,0,0,1-.292.581c-.1.1-.193.2-.289.292-.289.292-.559.569-.858.833L4.284,5.2a.608.608,0,0,0-.155.69c0,.009.006.016.009.025A7.315,7.315,0,0,0,5.229,7.69a12.429,12.429,0,0,0,3,2.729,4.449,4.449,0,0,0,.41.224,3.844,3.844,0,0,1,.342.186l.034.019a.65.65,0,0,0,.3.078.659.659,0,0,0,.463-.211l1.162-1.162a.823.823,0,0,1,.569-.277.781.781,0,0,1,.55.277l1.874,1.871a.75.75,0,0,1-.009,1.172c-.131.14-.267.274-.413.413a8.951,8.951,0,0,0-.65.674,1.706,1.706,0,0,1-1.333.566c-.053,0-.109,0-.162-.006a7.484,7.484,0,0,1-2.679-.8A15.118,15.118,0,0,1,3.647,9.492,13.846,13.846,0,0,1,1.192,5.405,5.013,5.013,0,0,1,.8,3.239Z"
                                                      fill="#fff"/>
                                            </g>
                                        </svg>
                                        <p>
                                            <a href="tel:+88 02 4881 2160">+88 02 4881 2160</a><br/>
                                            <a href="tel:+88 02 4881 2162">+88 02 4881 2162</a>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={7} className="footer-right" style={{paddingRight: offset + 15}}>
                            <div className="footer-right__menu">
                                <ul>
                                    <li><a href="/about-us">About Us</a></li>
                                    <li><a href="/products/mens">Products</a></li>
                                    <li><a href="/services">Service</a></li>
                                    <li><a href="/media/events">Media</a></li>
                                    {/*<li><a href="/gallery">Gallery</a></li>*/}
                                    <li><a href="/contact-us">Contact Us</a></li>
                                </ul>
                            </div>
                            <div className="footer-right__social">
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/ecosouringlimited1" target="_blank">
                                            <svg id="Component_42_1" data-name="Component 42 – 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                <circle id="Ellipse_14" data-name="Ellipse 14" cx="15" cy="15" r="15" fill="#545355"/>
                                                <circle id="Ellipse_18" data-name="Ellipse 18" cx="1" cy="1" r="1" transform="translate(15 15)" fill="#80c42b"/>
                                                <path id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f" d="M9.117,8.438l.417-2.715h-2.6V3.961a1.357,1.357,0,0,1,1.53-1.467H9.643V.183A14.441,14.441,0,0,0,7.541,0,3.314,3.314,0,0,0,3.994,3.654V5.723H1.609V8.438H3.994V15H6.929V8.438Z" transform="translate(9.224 7.5)" fill="#f1f1f1"/>
                                            </svg>

                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/eco_sourcing/" target="_blank">
                                            <svg id="Component_41_1" data-name="Component 41 – 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                <circle id="Ellipse_15" data-name="Ellipse 15" cx="15" cy="15" r="15" fill="#545355"/>
                                                <circle id="Ellipse_19" data-name="Ellipse 19" cx="1" cy="1" r="1" transform="translate(15 15)" fill="#80c42b" opacity="0"/>
                                                <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M7.5,5.891a3.845,3.845,0,1,0,3.845,3.845A3.839,3.839,0,0,0,7.5,5.891Zm0,6.345a2.5,2.5,0,1,1,2.5-2.5,2.5,2.5,0,0,1-2.5,2.5Zm4.9-6.5a.9.9,0,1,1-.9-.9A.895.895,0,0,1,12.4,5.734Zm2.547.91A4.438,4.438,0,0,0,13.731,3.5,4.467,4.467,0,0,0,10.588,2.29c-1.238-.07-4.949-.07-6.187,0A4.461,4.461,0,0,0,1.259,3.5,4.453,4.453,0,0,0,.047,6.641c-.07,1.238-.07,4.949,0,6.187A4.438,4.438,0,0,0,1.259,15.97,4.473,4.473,0,0,0,4.4,17.182c1.238.07,4.949.07,6.187,0a4.438,4.438,0,0,0,3.142-1.211,4.467,4.467,0,0,0,1.211-3.142c.07-1.238.07-4.946,0-6.184Zm-1.6,7.513a2.531,2.531,0,0,1-1.426,1.426,16.528,16.528,0,0,1-4.421.3,16.656,16.656,0,0,1-4.421-.3A2.531,2.531,0,0,1,1.65,14.157a16.528,16.528,0,0,1-.3-4.421,16.656,16.656,0,0,1,.3-4.421A2.531,2.531,0,0,1,3.076,3.89a16.528,16.528,0,0,1,4.421-.3,16.656,16.656,0,0,1,4.421.3,2.531,2.531,0,0,1,1.426,1.426,16.527,16.527,0,0,1,.3,4.421A16.518,16.518,0,0,1,13.342,14.157Z" transform="translate(7.505 5.262)" fill="#f1f1f1"/>
                                            </svg>

                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/watch?v=yteJDsK7218" target="_blank">
                                            <svg id="Component_40_1" data-name="Component 40 – 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                <circle id="Ellipse_16" data-name="Ellipse 16" cx="15" cy="15" r="15" fill="#545355"/>
                                                <circle id="Ellipse_20" data-name="Ellipse 20" cx="1" cy="1" r="1" transform="translate(15 15)" fill="#80c42b" opacity="0"/>
                                                <path id="Icon_awesome-youtube" data-name="Icon awesome-youtube" d="M15.737,6.15A1.885,1.885,0,0,0,14.41,4.815,44.541,44.541,0,0,0,8.55,4.5a44.541,44.541,0,0,0-5.86.315A1.885,1.885,0,0,0,1.363,6.15,19.772,19.772,0,0,0,1.05,9.784a19.772,19.772,0,0,0,.313,3.634A1.857,1.857,0,0,0,2.69,14.731a44.541,44.541,0,0,0,5.86.315,44.541,44.541,0,0,0,5.86-.315,1.857,1.857,0,0,0,1.326-1.313,19.772,19.772,0,0,0,.313-3.634,19.772,19.772,0,0,0-.313-3.634ZM7.016,12.014V7.554l3.92,2.23-3.92,2.23Z" transform="translate(6.45 5.5)" fill="#f1f1f1"/>
                                            </svg>


                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/ecosourcingltd/mycompany/?viewAsMember=true" target="_blank">
                                            <svg id="Component_39_1" data-name="Component 39 – 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                <circle id="Ellipse_17" data-name="Ellipse 17" cx="15" cy="15" r="15" fill="#545355"/>
                                                <circle id="Ellipse_21" data-name="Ellipse 21" cx="1" cy="1" r="1" transform="translate(15 15)" fill="#80c42b"/>
                                                <path id="Icon_zocial-linkedin" data-name="Icon zocial-linkedin" d="M-.18,1.881A1.559,1.559,0,0,1,.327.687,1.859,1.859,0,0,1,1.644.216,1.8,1.8,0,0,1,2.933.679,1.633,1.633,0,0,1,3.44,1.924a1.525,1.525,0,0,1-.492,1.158,1.862,1.862,0,0,1-1.332.478H1.6A1.772,1.772,0,0,1,.312,3.083a1.6,1.6,0,0,1-.492-1.2ZM.008,14.55V4.878H3.223V14.55H.008Zm5,0H8.218v-5.4a2.084,2.084,0,0,1,.116-.782,2.1,2.1,0,0,1,.615-.833,1.573,1.573,0,0,1,1.035-.34q1.622,0,1.622,2.186V14.55H14.82V9a4.66,4.66,0,0,0-1.014-3.25,3.457,3.457,0,0,0-2.679-1.108,3.286,3.286,0,0,0-2.91,1.607v.029H8.2l.014-.029V4.878H5q.029.463.029,2.881T5,14.55Z" transform="translate(7.68 7.284)" fill="#f1f1f1"/>
                                            </svg>


                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-right__copyright">
                                <p>©{new Date().getFullYear()} ECO Sourcing Ltd.</p>
                                <p><a href="https://dcastalia.com/" rel="nofollow">Designed & Developed by Dcastalia</a></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <svg xmlns="http://www.w3.org/2000/svg" width="584.138" height="600" viewBox="0 0 584.138 600">
                    <path id="Path_49" data-name="Path 49"
                          d="M272.469,301.98,436.874,464.5l-56.6,58.287L192.325,335.35H148.053l209.3,203.281L301.19,600,0,301.98H.3L300.712,0l56.9,56.107-210.7,212.5H192.2L380.067,79.566,436.952,135.7,272.547,301.98Zm311.669-1.057-132.191,132.2-132.169-132.2L451.947,168.732Zm-121.9-29.889-28.543,29.889,28.543,29.9,28.543-29.9Z"
                          fill="#e1e1e1" opacity="0.3"/>
                </svg>


            </StyledFooter>
        )
    }
;

const StyledFooter = styled.section`
  position: relative;
  background-color: #F1F1F1;

  .footer-left {
    background-color: ${primaryText};
    padding-top: 100px;
    padding-bottom: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__logo {
      margin-bottom: 80px;
    }

    &__locations {
      ul {
        li {
          display: flex;
          margin-bottom: 20px;
          align-items: flex-start;

          svg {
            margin-top: 5px;
            margin-right: 10px;
            margin-top: 7px;
            margin-right: 10px;
            width: 15px;
            height: 15px;
          }

          p, a {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: ${whiteText};
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .footer-right {
    padding-top: 150px;
    padding-left: 100px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__menu {
      ul {
        display: flex;
        //justify-content: space-between;

        li {
          margin-right: 40px;
          a {
            font-size: 16px;
            line-height: 24px;
            color: ${primaryText};
          }
        }
      }
    }

    &__social {
      ul {
        display: flex;

        li {
          margin-right: 20px;
          
              svg{
             circle#Ellipse_18 , circle#Ellipse_19 ,circle#Ellipse_20,circle#Ellipse_21{
                cx: 15;
                cy: 15;
                r: 1;
                transform: translate(0, 0) !important;
                fill: rgb(128, 196, 43);
                stroke: rgb(128, 196, 43);
                transition: all .3s ease;
                
              }
        }
        
        a:hover{
            svg{
                circle#Ellipse_18 , circle#Ellipse_19 ,circle#Ellipse_20,circle#Ellipse_21{
                  r: 15;
                      opacity: 1;
                }
                
                g{
                fill: rgb(84, 83, 85) !important;
                stroke: rgb(84, 83, 85) !important;
                }
            }
        
        }
        
        
        }
      }
    }

    &__copyright {
      display: flex;
      justify-content: space-between;

      p {
        font-size: 14px;
        line-height: 15px;
        color: ${primaryText};
        margin-bottom: 0;
      }
    }

  }

  > svg {
    position: absolute;
    right: -130px;
    top: 0;
    bottom: 0;
    pointer-events: none;
  }


  @media(min-width: 1920px){
    .footer-right{
      &__social ul > li > a > svg {
        height: 40px !important;
        width: 40px !important;
      }
    }
  }
  @media (max-width: 1366px) {
    .footer-left {
      padding-top: 80px;
      padding-bottom: 90px;
      &__locations {
        ul {
          li {
            p, a {
              font-size: 14px;
              font-weight: 500;
              line-height: 24px;
              color: ${whiteText};
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .footer-right{
      padding-top: 122px;
      padding-bottom: 30px;
      &__menu {
        ul {
          li {
            a {
              font-size: 14px;
              line-height: 24px;
            }
          }
        }
      }
    }
    
  }

  @media (max-width: 991px) {
    .footer-right {
      padding-left: ${props => props.offset + 15}px !important;
      //padding-right: 15px !important;
      padding-top: 60px !important;
      padding-bottom: 30px !important;
      flex-direction: row;
      flex-wrap: wrap;

      &__menu {
        flex: 0 0 calc(50% - 30px);

        ul {
          flex-direction: column;

          li {
            margin-bottom: 15px;
          }
        }
      }

      &__social {
        flex: 0 0 calc(50% - 30px);

        ul {
          flex-direction: column;
          align-items: flex-end;

          li {
            margin-bottom: 15px;
            margin-right: 0;
          }
        }
      }

      &__copyright {
        flex: 0 0 100%;
        margin-top: 60px;
        flex-direction: column;

        p {
          margin-bottom: 0;
        }
      }
    }

    > svg {
      display: none;
    }
  }

  @media (max-width: 768px) {

  }

  @media (max-width: 767px) {
    .footer-left {
      padding: 60px 15px !important;

      &__logo {
        margin-bottom: 60px;
      }
    }

    .footer-right {
      padding-left: 15px !important;
      padding-right: 15px !important;
      padding-top: 60px !important;
      padding-bottom: 30px !important;
      flex-direction: row;
      flex-wrap: wrap;

      &__menu {
        flex: 0 0 calc(50% - 30px);

        ul {
          flex-direction: column;

          li {
            margin-bottom: 15px;
          }
        }
      }

      &__social {
        flex: 0 0 calc(50% - 30px);

        ul {
          flex-direction: column;
          align-items: flex-end;

          li {
            margin-bottom: 15px;
            margin-right: 0;
          }
        }
      }

      &__copyright {
        flex: 0 0 100%;
        margin-top: 60px;
        flex-direction: column;

        p {
          margin-bottom: 0;
        }
      }
    }
  }

`;


export default Footer;
