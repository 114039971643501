import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {hover, primaryText, secondaryText, whiteText} from "../../../globalStyleVars";
import SubTitle from '../../../components/SubTitle';
import LinkWithIcon from '../../../components/LinkWithIcon';
import {Parallax, useController} from "react-scroll-parallax";
import ReactHtmlParser from "react-html-parser";

// Dynamic Image
import {baseUrl} from "../../../components/BaseUrl";
import BlurImg from "../../../assets/images/blur.jpg";
import VisibilitySensor from "react-visibility-sensor";


const WhyChoose = ({data, offset}) => {
    const {parallaxController} = useController();
    if (window.screen.width < 769) {
        parallaxController.destroy();
    }
    let thumbImageData = data?.images?.list[0] ? data?.images?.list[0] : '';
    let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;
    return (
        <StyledServices offset={offset} className="">
            <Container fluid>
                <Row>
                    <Col sm={5} className='service__left p-0'>
                        <div className="service__left__inner">
                            <img src={thumbImagePath} alt=""/>
                            <div className="service__left__inner__content">
                                <div className="service__left__inner__content__text">
                                    <SubTitle color={'#FFF'} fontSize={32} lineHeight={40} fontWeight={'300'}
                                              text={data?.page_data?.title} margin={'0 0 40px 0'}/>
                                    <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                        {
                                            ({isVisible}) =>
                                                <div className={`${isVisible ? 'anim-active' : ''} fade-up`}>
                                                    {ReactHtmlParser(data?.page_data?.description)}
                                                </div>

                                        }
                                    </VisibilitySensor>

                                </div>
                                <LinkWithIcon LinkTo={'/about-us'} LinkFontSize={23} LinkLineheight={35}/>
                            </div>
                        </div>
                    </Col>

                    {
                        data.posts?.list ?
                            <Col sm={7} className='service__right'>
                                <div className='d-flex'>

                                    <Col sm={12} md={7} className='service__right__item p-0'>
                                        <Parallax y={[0, -15]} tagOuter="figure">
                                            <div className="service__right__item__wrapper">


                                                {(() => {
                                                    if (data.posts.list[0]) {
                                                        let bannerImageData0 = data.posts.list[0]?.images[0] ? data.posts.list[0]?.images[0] : '';
                                                        let bannerImagePath0 = bannerImageData0?.path ? baseUrl + bannerImageData0?.path + bannerImageData0?.img_name : BlurImg;
                                                        return (
                                                            <div className="service__right__item__inner">
                                                                <div className="service__right__item__inner__content">
                                                                    <h4>
                                                                        <img src={bannerImagePath0} alt=""/>
                                                                    </h4>
                                                                    <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                                                        {({isVisible}) =>
                                                                            <p className={`${isVisible ? 'anim-active' : ''} fade-right`}>{data.posts.list[0]?.data?.title.split("").map(function (char, index) {
                                                                                return <span aria-hidden="true" key={index}>{char}</span>;
                                                                            })}</p>
                                                                        }
                                                                    </VisibilitySensor>
                                                                </div>
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <></>
                                                        )
                                                    }
                                                })()}

                                                {(() => {
                                                    if (data.posts.list[1]) {
                                                        let bannerImageData1 = data.posts.list[1]?.images[0] ? data.posts.list[1]?.images[0] : '';
                                                        let bannerImagePath1 = bannerImageData1?.path ? baseUrl + bannerImageData1?.path + bannerImageData1?.img_name : BlurImg;
                                                        return (
                                                            <div className="service__right__item__inner">
                                                                <div className="service__right__item__inner__content">
                                                                    <h4>
                                                                        <img src={bannerImagePath1} alt=""/>
                                                                    </h4>
                                                                    <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                                                        {({isVisible}) =>
                                                                            <p className={`${isVisible ? 'anim-active' : ''} fade-right`}>{data.posts.list[1]?.data?.title.split("").map(function (char, index) {
                                                                                return <span aria-hidden="true" key={index}>{char}</span>;
                                                                            })}</p>
                                                                        }
                                                                    </VisibilitySensor>
                                                                </div>
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <></>
                                                        )
                                                    }
                                                })()}
                                            </div>
                                        </Parallax>
                                    </Col>

                                    <Col md={5} sm={12} className='service__right__item p-0'>
                                        <Parallax y={[0, -30]} offsetYMax={5} tagOuter="figure">
                                            <div className="service__right__item__wrapper">

                                                {(() => {
                                                    if (data.posts.list[2]) {
                                                        let bannerImageData2 = data.posts.list[2]?.images[0] ? data.posts.list[2]?.images[0] : '';
                                                        let bannerImagePath2 = bannerImageData2?.path ? baseUrl + bannerImageData2?.path + bannerImageData2?.img_name : BlurImg;
                                                        return (
                                                            <div className="service__right__item__inner">
                                                                <div className="service__right__item__inner__content">
                                                                    <h4>
                                                                        <img src={bannerImagePath2} alt=""/>
                                                                    </h4>
                                                                    <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                                                        {({isVisible}) =>
                                                                            <p className={`${isVisible ? 'anim-active' : ''} fade-right`}>{data.posts.list[2]?.data?.title.split("").map(function (char, index) {
                                                                                return <span aria-hidden="true" key={index}>{char}</span>;
                                                                            })}</p>
                                                                        }
                                                                    </VisibilitySensor>
                                                                </div>
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <></>
                                                        )
                                                    }
                                                })()}

                                                {(() => {
                                                    if (data.posts.list[3]) {
                                                        let bannerImageData3 = data.posts.list[3]?.images[0] ? data.posts.list[3]?.images[0] : '';
                                                        let bannerImagePath3 = bannerImageData3?.path ? baseUrl + bannerImageData3?.path + bannerImageData3?.img_name : BlurImg;
                                                        return (
                                                            <div className="service__right__item__inner">
                                                                <div className="service__right__item__inner__content">
                                                                    <h4>
                                                                        <img src={bannerImagePath3} alt=""/>
                                                                    </h4>
                                                                    <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                                                        {({isVisible}) =>
                                                                            <p className={`${isVisible ? 'anim-active' : ''} fade-right`}>{data.posts.list[3]?.data?.title.split("").map(function (char, index) {
                                                                                return <span aria-hidden="true" key={index}>{char}</span>;
                                                                            })}</p>
                                                                        }
                                                                    </VisibilitySensor>
                                                                </div>
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <></>
                                                        )
                                                    }
                                                })()}


                                            </div>
                                        </Parallax>

                                    </Col>


                                </div>
                            </Col>
                            : ''
                    }
                </Row>
            </Container>
        </StyledServices>
    );
};

const StyledServices = styled.section`
  //padding-top: 210px;
  //padding-bottom: 150px;

  .service__left {
    display: flex;
    flex-direction: column;
    //justify-content: center;
    flex: 0 0 calc(41.666667% + 12px);
    max-width: calc(41.666667% + 12px);
    &__inner {
      padding-top: calc(600 / 568 * 100%);
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(49, 48, 51, 0.8);
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &__content {
        padding: 150px ${props => props.offset || '170'}px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .sub-title {
          padding: 0;

          &:after {
            content: none;
          }
        }

        p {
          font-size: 18px;
          font-weight: 500;
          color: ${whiteText};
        }
      }
    }
  }

  .service__right {
    padding: 0;
    flex: 0 0 calc(58.333333% - 12px);
    max-width: calc(58.333333% - 12px);
    .service__right__item {
      &:nth-of-type(1) {
        .service__right__item__inner {
          padding-top: calc(600 / 580 * 100%);
          position: relative;
          background-color: ${hover};

          &__content {
            position: absolute;
            padding: 70px 70px;
            top: 0;
            left: 0;
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;

            h4 {
              //font-size: 70px;
              //font-weight: 500;
              color: #ffffff;
              //line-height: 30px;
              width: 100%;
              display: flex;

              img {
                width: 100px;
              }
            }

            P {
              font-size: 36px;
              line-height: 40px;
              width: 80%;
              margin: 0;
              text-transform: uppercase;
            }
          }

          &:nth-of-type(1) {
            margin-top: 0;
            background-color: ${whiteText};

            .service__right__item__inner {
              &__content {
                p {
                  color: ${secondaryText};
                }
              }
            }

          }

          &:nth-of-type(2) {
            background-color: ${hover};

            .service__right__item__inner {
              &__content {
                p {
                  color: ${whiteText};
                }
              }
            }
          }
        }
      }


      &:nth-of-type(2) {
        .service__right__item__inner {
          padding-top: calc(555 / 494 * 100%);
          position: relative;
          background-color: ${primaryText};

          &__content {
            position: absolute;
            padding: 70px 70px;
            top: 0;
            left: 0;
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;

            h4 {
              //font-size: 70px;
              //font-weight: 500;
              //line-height: 30px;
              width: 100%;
              display: flex;

              img {
                width: 100px;
              }
            }

            P {
              font-size: 36px;
              line-height: 40px;
              width: 80%;
              color: ${whiteText};
              margin: 0;
              text-transform: uppercase;
            }
          }

          &:nth-of-type(1) {

          }

        }
      }
    }
  }

  
  @media(min-width: 1660px){
    .service__left__inner__content__text{
      .sub-title{
        font-size: 45px;
        font-weight: 300;
        line-height: 50px;
      }
    }
  }
  
  @media (max-width: 1600px) {
    .service__left {
      &__inner {
        padding-top: calc(600 / 450 * 100%);
      }
    }
  }

  @media (max-width: 1680px) {
    .service__left {
      &__inner {
        padding-top: calc(600 / 568 * 100%);
        &__content {
          padding: 100px ${props => props.offset || '170'}px;
          .sub-title {
            font-size: 32px;
            line-height: 40px;
          }

          p {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            color: ${whiteText};
          }

          .BtnTextWithIcon{
            span{
              font-size: 16px;
            }
          }
        }
      }
    }
    
    .service__right {
      .service__right__item {
        &:nth-of-type(1) {
          .service__right__item__inner {
            padding-top: calc(430 / 430 * 100%);
            &__content {
              padding: 40px 40px;
              h4 {

                img {
                  width: 60px;
                }
              }

              P {
                font-size: 24px;
                line-height: 26px;
              }
            }
          }
        }
        
        &:nth-of-type(2) {
          .service__right__item__inner {
            padding-top: calc(368 / 368 * 100%);
            &__content {
              padding: 40px 40px;
              h4 {
                img {
                  width: 60px;
                }
              }

              P {
                font-size: 24px;
                line-height: 26px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .service__left {
      &__inner {
        padding-top: calc(700 / 490 * 100%);

        &__content {
          padding: 85px 40px;
        }
      }
    }
  }

  @media (max-width: 840px) {
    .service__left {
      &__inner {
        padding-top: calc(700 / 470 * 100%);
        &__content{
          padding-top: 50px;
          &__text{
            .sub-title{
              margin-bottom: 30px;
              line-height: 30px;
            }
            p{
              line-height: 21px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {

    padding-top: 0;
    padding-bottom: 0;

    .service__left, .service__right {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .service__left__inner {
      padding-top: calc(532 / 414 * 100%);

      &__content {
        bottom: 0;
        top: auto;
        padding: 85px 15px;
        &__text{
          .sub-title{
            margin-bottom: 40px;
            line-height: 40px;
          }
          p{
            line-height: 24px;
          }
        }
      }
    }
    
    .service__right {
      .d-flex {
        flex-direction: column;
      }

      &__item {
        &__wrapper {
          display: flex;
          flex-wrap: wrap;
        }

        &__inner {
          flex: 0 0 50%;
          padding-top: 50% !important;

          &:nth-of-type(1) {
            border-right: 1px solid rgba(255, 255, 255, 0.2);
          }
        }
      }
      .service__right__item {
        &:nth-of-type(1) {
          .service__right__item__inner {
            padding-top: calc(230 / 207 * 100%);
            &__content{
              padding: 30px 15px;
            }
          }
        }
        &:nth-of-type(2) {
          .service__right__item__inner {
            padding-top: calc(230 / 207 * 100%);
            &__content{
              padding: 30px 15px;
            }
          }
        }
      }
    }

    .service__right__item__inner {
      margin-top: 0 !important;

      &__content {

        h4 {
          img{
            width: 40px !important;
          }
        }

        p {
          width: 100% !important;
          font-size: 20px !important;
          line-height: 22px !important;
        }
      }
    }


    .service__right__item {
      //flex: 0 0 50%;
      //max-width: 50%;
    }

  }



`;
export default WhyChoose;
