import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {Parallax, useController} from "react-scroll-parallax";
import {Link} from 'react-router-dom';
import {baseUrl} from "../../../components/BaseUrl";
import {hover, primaryText, secondaryText, tertiaryText, whiteText} from "../../../globalStyleVars";

// dynamic Image
import pant from "../../../assets/images/dynamic/mens-removebg-preview.png";

// Image
import BlurImg from "../../../assets/images/blur.jpg";
import Modal from "../../../components/Modal";


const ProductListing = ({data, categoryData, categorySlug, offset}) => {
    const [show, setShow] = useState(false);
    const [slug, setSlug] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = (show, slug) => {
        setShow(show);
        setSlug(slug)
    };

    let filterDetail = data && data.length ? data.find(f => f.product_data.slug === slug) : '';
    let productPopupImagesData = filterDetail?.images?.list ? filterDetail?.images?.list.filter(e => e.is_thumb === 'on' && e.is_banner !== 'on') : '';


    const {parallaxController} = useController();
    if (window.screen.width < 769) {
        parallaxController.destroy();
    }

    return (
        <>
            <StyledProductCat className="">
                <Container fluid>
                    {
                        categoryData?.list && categoryData?.list.length ?
                            <Row>
                                {
                                    categoryData?.list.map((category, cKey) => {
                                        return (
                                            <div className="productCatSingle">
                                                <Parallax y={[0, 0]} tagOuter="figure">
                                                    <Link to={'/products/' + category?.category_data?.slug}>
                                                        <div
                                                            className={`productCatSingle__wrap ${category?.category_data?.slug === categorySlug ? 'active' : ''}`}>
                                                            <div className="productCatSingle__wrap__inner">
                                                                <h4>
                                                                    {'0' + (cKey + 1)}
                                                                </h4>
                                                                <p>{category?.category_data?.title}</p>
                                                            </div>
                                                        </div>
                                                    </Link>

                                                </Parallax>
                                            </div>
                                        )
                                    })
                                }
                            </Row>
                            : ''
                    }
                </Container>
            </StyledProductCat>
            <StyledProductList>
                <Container>
                    {
                        data.length !== 0 ?
                            <Row>
                                {
                                    data.map((product) => {

                                        let thumbImageData = product?.images?.list ? product?.images?.list.find(e => e.is_thumb === 'on') : '';
                                        let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;
                                        return (
                                            <Col xs={6} md={3} className="productSingleCol">
                                                <div className="productSingle">
                                                    <Link to={'#'}
                                                          onClick={() => handleShow(true, product?.product_data?.slug)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             xmlnsXlink="http://www.w3.org/1999/xlink"
                                                             viewBox="0 0 85 85">
                                                            <defs>
                                                                <filter id="BG" x="0" y="0" width="85" height="85"
                                                                        filterUnits="userSpaceOnUse">
                                                                    <feOffset dy="5" input="SourceAlpha"/>
                                                                    <feGaussianBlur stdDeviation="7.5" result="blur"/>
                                                                    <feFlood flood-color="#80c42b"
                                                                             flood-opacity="0.302"/>
                                                                    <feComposite operator="in" in2="blur"/>
                                                                    <feComposite in="SourceGraphic"/>
                                                                </filter>
                                                            </defs>
                                                            <g id="Arrow_Icon_Component"
                                                               data-name="Arrow Icon Component"
                                                               transform="translate(22.5 17.5)">
                                                                <g id="Icon" transform="translate(0 0)">
                                                                    <g transform="matrix(1, 0, 0, 1, -22.5, -17.5)"
                                                                       filter="url(#BG)">
                                                                        <circle id="BG-2" data-name="BG" cx="20" cy="20"
                                                                                r="20"
                                                                                transform="translate(22.5 17.5)"
                                                                                fill="#80c42b"/>
                                                                    </g>
                                                                    <circle id="Hover2" cx="1.333" cy="1.333" r="1.333"
                                                                            transform="translate(18.666 18.667)"
                                                                            fill="#fff"
                                                                            opacity="0"/>
                                                                    <circle id="Hover1" cx="2" cy="2" r="2"
                                                                            transform="translate(18 18)"
                                                                            fill="#313033" opacity="0"/>
                                                                    <g id="Arrow"
                                                                       transform="translate(11.673 23.908) rotate(-45)">
                                                                        <line id="Line_11" data-name="Line 11"
                                                                              x2="16.512"
                                                                              transform="translate(0 3.691)" fill="none"
                                                                              stroke="#fff"
                                                                              stroke-linecap="round"
                                                                              stroke-width="1.5"/>
                                                                        <path id="Path_7" data-name="Path 7"
                                                                              d="M0,0,3.794,3.77,0,7.381"
                                                                              transform="translate(13.543)" fill="none"
                                                                              stroke="#fff"
                                                                              stroke-linecap="round"
                                                                              stroke-linejoin="round"
                                                                              stroke-width="1.5"/>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </Link>
                                                    <div className="productSingle__img">
                                                        <img src={thumbImagePath} alt=""/>
                                                    </div>
                                                    <div className="productSingle__title">
                                                        <h3>{product?.product_data?.title}</h3>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                            : 'Loading..'
                    }
                </Container>
                <Modal handleClose={handleClose} show={show} postData={filterDetail?.product_data?.description}
                       title={filterDetail?.product_data?.title} thumbImage={productPopupImagesData}/>
            </StyledProductList>
        </>
    )
};

const StyledProductCat = styled.section`
  background-color: #F1F1F1;

  .productCatSingle {
    flex: 0 0 25%;

    &__wrap {
      position: relative;
      //flex: 0 0 25%;
      padding-top: calc(300 / 341 * 100%);
      background-color: ${primaryText};
      transition: all .4s ease;

      &.active {
        background-color: ${hover};

        .productCatSingle {
          &__wrap {
            &__inner {
              h4 {
                background-color: ${whiteText};
                color: ${hover};
              }
            }
          }
        }
      }

      &__inner {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        padding: 50px 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h4 {
          font-size: 16px;
          font-weight: 500;
          color: ${whiteText};
          line-height: 50px;
          width: 50px;
          height: 50px;
          border: 1px solid ${whiteText};
          border-radius: 50%;
          padding-left: 15px;
          transition: all .4s ease;
        }

        p {
          font-size: 32px;
          line-height: 40px;
          font-weight: 500;
          color: ${whiteText};
          margin-bottom: 0;
          text-transform: uppercase;
        }
      }
    }

    a {
      &:hover {
        .productCatSingle {
          &__wrap {
              // background-color: ${hover};

            &__inner {
              h4 {
                background-color: ${whiteText};
                color: ${hover};
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1680px) {
    .productCatSingle {
      &__wrap {
        &__inner {
          p {
            font-size: 24px;
            line-height: 36px;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .productCatSingle {
      &__wrap {
        &__inner {
          padding: 40px 40px;

          h4 {
            line-height: 40px;
            width: 40px;
            height: 40px;
            padding-left: 10px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .productCatSingle {
      //display: none;
    }
  }

  @media (max-width: 767px) {
    .productCatSingle {
      //display: none;
      flex: 0 0 50%;
      border-bottom: 1px solid rgba(84, 83, 85, 0.1);

      &:nth-child(odd) {
        border-right: 1px solid rgba(84, 83, 85, 0.1);
      }

      &__wrap {
        padding-top: calc(123 / 207 * 100%);

        &__inner {
          padding: 20px;

          h4 {
            font-size: 12px;
            line-height: 30px;
            width: 30px;
            height: 30px;
            padding-left: 8px;
          }

          p {
            font-size: 20px;
            line-height: 26px;
          }
        }
      }
    }
  }

`;

const StyledProductList = styled.section`
  padding-top: 180px;
  padding-bottom: 180px;

  .productSingle {
    position: relative;
    margin-bottom: 45px;

    &__img {
      position: relative;
      padding-top: calc(300 / 270 * 100%);
      background: rgb(241, 241, 241);
      background: radial-gradient(circle, rgba(241, 241, 241, 1) 0%, rgba(225, 225, 225, 1) 100%);

      img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        padding: 30px;
        transform: scale(1.01);
        transition: transform .4s ease;
      }
    }

    &__title {
      padding-left: 10px;
      padding-top: 20px;

      h3 {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        color: ${secondaryText};
      }
    }

    a {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      display: flex;
      justify-content: flex-end;

      svg {
        width: 85px;
        height: 85px;
        transform: rotate(0deg);
        transition: transform .4s ease;

        circle#Hover1 {
          cx: 20;
          cy: 20;
          r: 1;
          transform: translate(0, 0) !important;
          fill: ${whiteText};
          opacity: 0;
          transition: all .3s ease;
        }

        #Arrow {
          line, path {
            stroke: ${whiteText};
          }
        }
      }
    }

    &:hover {
      .productSingle {
        &__img {
          img {
            transform: scale(1.04);
          }
        }
      }

      a {
        svg {
          transform: rotate(45deg);

          circle#Hover1 {
            r: 20;
            opacity: 1;
          }

          #Arrow_Icon_Component, #Icon {
            stroke: white;
          }

          #Arrow {
            line, path {
              stroke: ${hover};
            }
          }
        }
      }

    }
  }

  @media (max-width: 1366px) {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  @media (max-width: 767px) {
    .productSingleCol {
      &:nth-child(odd) {
        padding-right: 10px;
      }

      &:nth-child(even) {
        padding-left: 10px;
      }
    }

    .productSingle {
      &__title {
        h3 {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
`;

export default ProductListing;