import React, {useEffect, useState} from 'react';
import StyledGlobal from './globalStyle';
import AnimationStyledGlobal from './AnimationGlobalStyle';
import Error from './container/error';
import {Route, Switch, useLocation} from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';
import {ParallaxProvider} from 'react-scroll-parallax';
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import Index from './container/home/';
import About from './container/about/';
import Contact from './container/contact/';
import ProductPage from './container/product/';
import Service from './container/service/';
import Media from './container/media/';
import MediaDetail from './container/media/detail';
import Gallery from './container/gallery/';



function App() {
    const location = useLocation();
    let [offset, setOffset] = useState(0);

    return (
        <ParallaxProvider>
            <React.Fragment>
                <StyledGlobal/>
                <AnimationStyledGlobal/>
                <Menu setOffset={setOffset}/>
                <AnimatePresence exitBeforeEnter>
                    <Switch location={location} key={location.pathname}>
                        <Route exact path="/"><Index offset={offset}/></Route>
                        <Route exact path="/about-us"><About offset={offset}/></Route>
                        <Route exact path="/products"><ProductPage/></Route>
                        <Route exact path="/products/:categories"><ProductPage/></Route>
                        <Route exact path="/contact-us"><Contact offset={offset}/></Route>
                        <Route exact path="/services"><Service offset={offset}/></Route>
                        <Route exact path="/services/:servicesSlug"><Service offset={offset}/></Route>
                        <Route exact path="/media/:mediaSlug"><Media offset={offset}/></Route>
                        <Route exact path="/media/:mediaSlug/:mediaDetailSlug"><MediaDetail offset={offset}/></Route>
                        <Route exact path="/gallery/:gallerySlug"><Gallery offset={offset}/></Route>
                        <Route component={Error}/>
                    </Switch>
                </AnimatePresence>
                <Footer offset={offset}/>
            </React.Fragment>
        </ParallaxProvider>
    );
}

export default App;
