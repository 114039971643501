import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from "react-bootstrap";
import {Link, useParams} from 'react-router-dom';
import ReactHtmlParser from "react-html-parser";
import {baseUrl} from "../../../components/BaseUrl";
import VisibilitySensor from "react-visibility-sensor";
import 'swiper/css/swiper.css';
// Internal Component

import {hover, primaryText, secondaryText, whiteText} from "../../../globalStyleVars";
// Static Images
import BlurImg from "../../../assets/images/blur.jpg";
import Modal from "../../../components/Modal";
import Swiper from "react-id-swiper";

const BannerSlider = ({data, serviceData, serviceChildData, offset, executeScroll}) => {
    let {servicesSlug} = useParams();
    const params = {
        speed: 2000,
        slidesPerView: 1,
        // freeMode: true,
        autoplay: true,
        effect: "fade",
        loop: true,
        slidesPerGroup: 1,
        grabCursor: false,
        autoplayDisableOnInteraction: false,

    };

    const [show, setShow] = useState(false);
    const [slug, setSlug] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = (show, slug) => {
        setShow(show);
        setSlug(slug)
    };

    let filterDetail = serviceChildData && serviceChildData.length ? serviceChildData.find(f => f.page_data.slug === slug) : '';
    let servicePopupImagesData = filterDetail?.images?.list ? filterDetail?.images?.list.filter(e => e.is_thumb !== 'on' && e.is_banner !== 'on') : '';

    let bannerImageData = data?.images?.list ? data?.images?.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = bannerImageData?.path ? baseUrl + bannerImageData?.path + bannerImageData?.img_name : BlurImg;


    return (
        <StyledSlide offset={offset} className="landing-banner">
            <Container fluid>
                <Row>
                    <Col className={'landing-banner__left'} md={8}>
                        <div className="landing-banner__img">


                            {data?.images?.list?.length &&
                                <Swiper  {...params}>
                                    {

                                        data?.images?.list.map((ListImage, key) => {
                                            let bannerImagePath_Slider = ListImage?.path ? baseUrl + ListImage?.path + ListImage?.img_name : BlurImg;
                                            return (
                                                <div className="slider_item">
                                                    <div className="slider_item__image_wrapper">
                                                        <img src={bannerImagePath_Slider} alt=""/>
                                                    </div>
                                                    <div className="landing-banner__img__text">

                                                        <div className="landing-banner__img__text__title">
                                                            {
                                                                ListImage.short_title.split("*").map(function (partialValue, pKey) {
                                                                    return(
                                                                        <h2>{pKey === 0 ? partialValue : <strong>{partialValue}</strong>}</h2>
                                                                    )
                                                                })

                                                            }
                                                            <p><span>{ListImage.short_desc}</span></p>
                                                        </div>


                                                        <div className="banner-box">
                                                            <Link to={'#'} onClick={executeScroll}>

                                                                <svg id="Component_24_2" data-name="Component 24 – 2"
                                                                     xmlns="http://www.w3.org/2000/svg" width="30"
                                                                     height="30" viewBox="0 0 30 30">
                                                                    <g id="Group_109" data-name="Group 109"
                                                                       transform="translate(-0.48 -0.48)">
                                                                        <circle id="Ellipse_1" data-name="Ellipse 1"
                                                                                cx="15" cy="15" r="15"
                                                                                transform="translate(0.48 0.48)"
                                                                                fill="#313033"/>
                                                                        <circle id="Ellipse_4" data-name="Ellipse 4"
                                                                                cx="1" cy="1" r="1"
                                                                                transform="translate(14.48 14.48)"
                                                                                fill="#fff" opacity="0"/>
                                                                        <g id="Arrow_Iocn" data-name="Group 20"
                                                                           transform="translate(9.235 18.411) rotate(-45)">
                                                                            <line id="Line_11" data-name="Line 11"
                                                                                  x2="12.384"
                                                                                  transform="translate(0 2.768)"
                                                                                  fill="none" stroke="#fff"
                                                                                  stroke-linecap="round"
                                                                                  stroke-width="1"/>
                                                                            <path id="Path_7" data-name="Path 7"
                                                                                  d="M0,0,2.846,2.828,0,5.536"
                                                                                  transform="translate(10.157 0)"
                                                                                  fill="none" stroke="#fff"
                                                                                  stroke-linecap="round"
                                                                                  stroke-width="1"/>
                                                                        </g>
                                                                    </g>
                                                                </svg>

                                                                <p>Our <br/>Products</p>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Swiper>
                            }


                        </div>
                    </Col>
                    <Col className={'landing-banner__right'} md={4}>
                        <div className="landing-banner__text">
                            <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                {({isVisible}) =>
                                    <h3 className={`${isVisible ? 'anim-active' : ''} fade-right`}>{'Since 2010'.split("").map(function (char, index) {
                                        return <span aria-hidden="true" key={index}>{char}</span>;
                                    })}</h3>
                                }
                            </VisibilitySensor>
                            <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                {({isVisible}) =>
                                    <p className={`${isVisible ? 'anim-active' : ''} fade-right`}>{serviceData?.page_data?.short_desc.split("").map(function (char, index) {
                                        return <span aria-hidden="true" key={index}>{char}</span>;
                                    })}</p>
                                }
                            </VisibilitySensor>
                        </div>
                        <div className="landing-banner__service">
                            {
                                serviceChildData.length && serviceChildData?.map((service, serviceIndex) => {
                                    return (
                                        <Col sm={6} xs={6}
                                             className={'landing-banner__service__item'}
                                        >
                                            <Link
                                                to={'#'}
                                                onClick={() => handleShow(true, service?.page_data?.slug)}
                                                className="landing-banner__service__item__inner">
                                                <span>{'0' + (serviceIndex + 1)}</span>
                                                <p>{service?.page_data?.title}</p>
                                            </Link>
                                        </Col>
                                    )
                                })
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
            <Modal handleClose={handleClose} show={show} postData={filterDetail?.page_data?.description}
                   title={filterDetail?.page_data?.title} thumbImage={servicePopupImagesData}/>
        </StyledSlide>
    )

}

const StyledSlide = styled.section`
  &.landing-banner {
    margin-top: 100px;
    height: calc(100vh - 100px);
  }

  .landing-banner__left {
    padding: 0;
  }
  
  .landing-banner__img {
    position: relative;
    padding-top: 0 !important;
    height: 100%;
    .swiper-container{
      height: 100%;
      .swiper-slide,.swiper-wrapper{
        height: 100%;
      }
    }
    .slider_item__image_wrapper{
      padding-top: calc(100vh - 100px);
      img{
        position: absolute;
        object-fit: cover;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 1;
      }
    }
    
    &__text {
      position: absolute;
      left: ${props => props.offset || '170'}px;
      bottom: 150px;
      z-index: 2;

      .swiper-container {
        position: relative !important;
        top: unset;
        left: unset;
        bottom: unset;
        right: unset;
      }

      &__title {
        h2 {
          font-size: 80px;
          font-weight: 300;
          color: ${hover};
          line-height: 84px;
          text-transform: uppercase;

          strong{
            color: #FFFFFF;
            font-weight: bold;
          }
          &:first-child {
            margin-bottom: 0;
          }

          &:last-child {
            margin-bottom: 20px;
            font-weight: bold;
            color: ${whiteText};
          }
        }

      }

      p {
        margin-bottom: 40px;
        font-size: 16px;
        font-weight: 400;
        color: ${whiteText};
        line-height: 26px;
      }

      .banner-box {
        width: 230px;
        height: 230px;

        a {
          display: flex;
          width: 100%;
          height: 100%;
          background-color: ${hover};
          padding: 30px;
          align-items: flex-end;
          position: relative;
          overflow: hidden;
          flex-direction: column;
          justify-content: space-between;

          &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: ${primaryText};
            opacity: 0;
            left: 0;
            top: 100%;
            z-index: 1;
            transition: all .3s cubic-bezier(0.5, 1, 0.89, 1);
          }

          p {
            color: ${whiteText};
            font-size: 20px;
            font-weight: bold;
            line-height: 24px;
            margin-bottom: 0;
            z-index: 2;
            width: 100%;
          }

          svg {
            position: relative;
            z-index: 2;
            transition: all .1s cubic-bezier(0.5, 1, 0.89, 1);

            circle#Ellipse_4 {
              cx: 15;
              cy: 15;
              r: 1;
              transform: translate(0, 0) !important;
              fill: ${whiteText};
                //stroke: ${whiteText};
              transition: all .3s ease;

            }
          }

          &:hover {
            &:before {
              opacity: 1;
              top: 0;
            }

            svg {
              transform: rotate(45deg);

              circle#Ellipse_4 {
                r: 15;
                opacity: 1;
              }

              g {
                fill: inherit !important;
                stroke: inherit !important;
              }

              circle {
                  //fill: ${whiteText};
              }

              #Arrow_Iocn {
                rotation: 5deg;

                line, path {
                  stroke: ${hover};
                }
              }
            }
          }
        }
      }
    }
  }

  .landing-banner__right {
    background-color: ${primaryText};
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .landing-banner__text {
    padding-right: ${props => props.offset || '170'}px;
    padding-left: 55px;
    padding-top: 150px;

    > h3 {
      font-size: 40px;
      font-weight: 300;
      line-height: 62px;
      text-transform: uppercase;
      color: ${secondaryText};
      margin-bottom: 20px;
    }

    p {
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      color: ${hover};
    }
  }

  .landing-banner__service {
    display: flex;
    flex-wrap: wrap;

    &__item {
      padding-top: 40%;
      position: relative;

      &__inner {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        padding: 40px 40px;
        flex-direction: column;
        justify-content: space-between;
        border-top: 1px solid rgba(84, 83, 85, 0.5);
        text-transform: uppercase;
        transition: all .3s cubic-bezier(0.5, 1, 0.89, 1);
        overflow: hidden;

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: ${hover};
          opacity: 0;
          left: 0;
          top: 100%;
          z-index: 1;
          transition: all .3s cubic-bezier(0.5, 1, 0.89, 1);
        }


        &:not(:nth-child(even)) {
          border-right: 1px solid rgba(84, 83, 85, 0.5);
        }

        span {
          position: relative;
          font-size: 58px;
          font-weight: 300;
          line-height: 58px;
          color: rgba(165, 165, 166, 0.3);
          z-index: 2;
        }

        p {
          position: relative;
          font-size: 18px;
          line-height: 18px;
          font-weight: 300;
          color: ${whiteText};
          margin-bottom: 0;
          z-index: 2;
        }

        &:hover {
            //background-color: ${hover};

          &:before {
            opacity: 1;
            top: 0;
          }

          span {
            color: ${whiteText};
          }
        }

      }
    }
  }


  @media (min-width: 1920px) {
    .landing-banner__img {
      .slider_item__image_wrapper{
        padding-top: calc(100vh - 100px);
      }
      &__text {
        h2 {
          font-size: 80px;
          line-height: 84px;
        }

        p {
          font-size: 18px;
        }
      }
    }

    .landing-banner__text {
      > h3 {
        font-size: 40px;
      }

      p {
        font-size: 20px;
        line-height: 30px;
      }
    }

    .landing-banner__service {
      &__item {
        &__inner {
          span {
            font-size: 58px;
            line-height: 84px;
          }

          p {
            font-size: 25px;
            line-height: 25px;
          }
        }
      }
    }
  }

  @media (max-width: 1920px) {

  }

  @media (max-width: 1680px) {

  }

  @media (max-width: 1536px) {

  }

  @media (max-width: 1440px) {
    .landing-banner__text {
      padding-top: 60px;
    }
  }

  @media (max-width: 1680px) {
    .landing-banner__img {

      &__text {
        bottom: 80px;

        h2 {
          font-size: 50px;
          line-height: 54px;
        }

        > p {
          font-size: 16px;
          margin-bottom: 40px;
        }
      }
    }


    .landing-banner__text {
      padding-top: 90px;
      padding-left: 40px;

      > h3 {
        font-size: 24px;
        line-height: 44px;
        margin-bottom: 20px;
      }

      p {
        font-size: 18px;
        line-height: 24px;
      }
    }

    .landing-banner__service {
      &__item {
        &__inner {
          padding: 40px 40px;

          span {
            font-size: 40px;
            line-height: 58px;
          }
        }
      }
    }

  }

  @media (max-width: 1280px) {
    .landing-banner__img {
      &__text {
        bottom: 70px;

        .banner-box {
          width: 200px;
          height: 200px;
        }
      }
    }

    .landing-banner__text {
      padding-left: 30px;
    }

    .landing-banner__service {
      &__item {
        &__inner {
          padding: 30px 30px;

          p {
            //font-size: 16px;
            //line-height: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {

  }

  @media (max-width: 992px) {
    .landing-banner__img {
      &__text {
        bottom: 50px;

        h2 {
          font-size: 40px;
          line-height: 40px;
        }

        .banner-box {
          width: 150px;
          height: 150px;

          a {
            p {
              font-size: 15px;
              line-height: 18px;
            }
          }
        }

        > p {
          font-size: 15px;
          margin-bottom: 30px;
        }
      }
    }

    .landing-banner__text {
      padding-left: 15px;
      padding-top: 30px;

      > h3 {
        //font-size: 20px;
      }

      p {
        font-size: 15px;
        line-height: 18px;
      }
    }

    .landing-banner__service {
      &__item {
        &__inner {
          padding: 15px 15px;

          p {
            font-size: 14px;
            line-height: 14px;
          }
        }
      }
    }
  }

  @media (max-width: 840px) {
    .landing-banner {
      &__service {
        &__item {
          &__inner {
            span {
              font-size: 30px;
              line-height: 38px;
            }
          }
        }
      }
    }

  }

  @media (max-width: 768px) {
    &.landing-banner {
      margin-top: 60px;
    }

  }

  @media (max-width: 767px) {
    &.landing-banner{
      height: auto;
    }
    .landing-banner__img {
      .slider_item__image_wrapper{
        padding-top: calc(500 / 414 * 100%);
      }
      &__text {
        bottom: 60px;

        .banner-box {
          width: 220px;
          height: 220px;

          a {
            p {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }

        h2 {
          font-size: 40px;
          line-height: 42px;
        }

        > p {
          font-size: 16px;
        }
      }
    }

    .landing-banner__text {
      padding-top: 65px;
      padding-bottom: 65px;

      > h3 {
        font-size: 24px;
        line-height: 32px;
      }

      p {
        font-size: 18px;
        line-height: 24px;
      }
    }

    .landing-banner__service {
      &__item {
        padding-top: 50%;

        &__inner {
          padding: 40px 15px;

          span {
            font-size: 50px;
          }

          p {
            font-size: 20px;
            line-height: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .landing-banner__img {
      &__text {
        bottom: 60px;

        .banner-box {
          width: 180px;
          height: 180px;

          a {
            p {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }

        h2 {
          font-size: 40px;
          line-height: 42px;
        }

        > p {
          font-size: 16px;
        }
      }
    }

    .landing-banner__text {
      padding-top: 60px;
      padding-bottom: 60px;

      > h3 {
        font-size: 24px;
      }

      p {
        font-size: 18px;
        line-height: 24px;
      }
    }

    .landing-banner__service {
      &__item {
        &__inner {
          padding: 40px 15px;

          span {

          }

          p {
            font-size: 18px;
            line-height: 18px;
            width: 60%;
          }
        }
      }
    }
  }
`;


export default BannerSlider;
