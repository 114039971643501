import {MEDIA} from "../../constants/media";

let initialState = {
  mediaMain: {},
  mediaMainLoading: false,
  mediaMainErr: '',
  mediaChild: {},
  mediaChildLoading: false,
  mediaChildErr: '',
  mediaChildOfChild: {},
  mediaChildOfChildLoading: false,
  mediaChildOfChildErr: '',
  mediaDetail: {},
  mediaDetailLoading: false,
  mediaDetailErr: ''
};

export default function mediaReducer(state = initialState, action) {
  switch (action.type) {
    case MEDIA.FETCH_MEDIA_PAGE.MAIN:
      return {
        ...state,
        mediaMain: '',
        mediaMainLoading: true,
        mediaMainErr: ''
      };

    case MEDIA.FETCH_MEDIA_PAGE.SUCCESS:
      return {
        ...state,
        mediaMain: action.result,
        mediaMainLoading: false,
        mediaMainErr: ''
      };

    case MEDIA.FETCH_MEDIA_PAGE.FAILURE:
      return {
        ...state,
        mediaMain: '',
        mediaMainLoading: false,
        mediaMainErr: 'Error from Server'
      };

    case MEDIA.FETCH_MEDIA_CHILD.MAIN:
      return {
        ...state,
        mediaChild: '',
        mediaChildLoading: true,
        mediaChildErr: ''
      };

    case  MEDIA.FETCH_MEDIA_CHILD.SUCCESS:
      return {
        ...state,
        mediaChild: action.result,
        mediaChildLoading: false,
        mediaChildErr: ''
      };

    case MEDIA.FETCH_MEDIA_CHILD.FAILURE:
      return {
        ...state,
        mediaChild: '',
        mediaChildLoading: false,
        mediaChildErr: 'Error from server'
      };

    case MEDIA.FETCH_MEDIA_CHILD_OF_CHILD.MAIN:
      return {
        ...state,
        mediaChildOfChild: '',
        mediaChildOfChildLoading: true,
        mediaChildOfChildErr: ''
      };

    case  MEDIA.FETCH_MEDIA_CHILD_OF_CHILD.SUCCESS:
      return {
        ...state,
        mediaChildOfChild: action.result,
        mediaChildOfChildLoading: false,
        mediaChildOfChildErr: ''
      };

    case MEDIA.FETCH_MEDIA_CHILD_OF_CHILD.FAILURE:
      return {
        ...state,
        mediaChildOfChild: '',
        mediaChildOfChildLoading: false,
        mediaChildOfChildErr: 'Error from server'
      };

    case MEDIA.FETCH_MEDIA_DETAIL.MAIN:
      return {
        ...state,
        mediaDetail: '',
        mediaDetailLoading: true,
        mediaDetailErr: ''
      };

    case  MEDIA.FETCH_MEDIA_DETAIL.SUCCESS:
      return {
        ...state,
        mediaDetail: action.result,
        mediaDetailLoading: false,
        mediaDetailErr: ''
      };

    case MEDIA.FETCH_MEDIA_DETAIL.FAILURE:
      return {
        ...state,
        mediaDetail: '',
        mediaDetailLoading: false,
        mediaDetailErr: 'Error from server'
      };

    default:
      return state
  }
}
