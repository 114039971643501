import React, {useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import {Parallax, useController} from "react-scroll-parallax";
import {hover, primaryText, whiteText} from "../../../globalStyleVars";
import ReactHtmlParser from "react-html-parser";

// dynamic Image
import MissionVisionImg from "../../../assets/images/dynamic/mission-vision.jpg";
import {baseUrl} from "../../../components/BaseUrl";
import BlurImg from "../../../assets/images/blur.jpg";


const MissionVision = ({data, offset}) => {
    let bannerImageData = data?.images?.list ? data?.images?.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = bannerImageData?.path ? baseUrl + bannerImageData?.path + bannerImageData?.img_name : BlurImg;
    return (
        <StyledMissionVision className="mission-vision" offset={offset}>
            <Container fluid>
                <Row>
                    <div className="imgWrap">
                        <img src={bannerImagePath} alt=""/>
                    </div>
                    {
                        data?.posts?.list ?
                            <div className="mission-vision__wrap">
                                <Row>
                                    <Col md={6} className="mission-vision__wrap__single">
                                        <Parallax y={[0, -20]} tagOuter="figure">
                                            <div className="mission-vision__item">
                                                <SubTitle text={data?.posts?.list[0]?.data.title} fontSize={60}
                                                          fontWeight={300} lineHeight={70} color={'#fff'}
                                                          margin={'0 0 40px 0'}/>
                                                {ReactHtmlParser(data?.posts?.list[0]?.data.description)}
                                            </div>
                                        </Parallax>
                                    </Col>
                                    <Col md={6} className="mission-vision__wrap__single">
                                        <Parallax y={[0, -50]} tagOuter="figure">
                                            <div className="mission-vision__item">
                                                <SubTitle text={data?.posts?.list[1]?.data.title} fontSize={60}
                                                          fontWeight={300} lineHeight={70} color={'#fff'}
                                                          margin={'0 0 40px 0'}/>
                                                {ReactHtmlParser(data?.posts?.list[1]?.data.description)}
                                            </div>
                                        </Parallax>
                                    </Col>
                                </Row>
                            </div>
                            :
                            ''
                    }
                </Row>
            </Container>
        </StyledMissionVision>
    )
};

const StyledMissionVision = styled.section`
  &.mission-vision {
    .imgWrap {
      position: relative;
      padding-top: calc(550 / 1367 * 100%);
      width: 100%;

      img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;

      }
    }

    .mission-vision {
      &__wrap {
        width: 100%;
        display: flex;

        &__single {
          padding-left: 0;
          padding-right: 0;

          .mission-vision__item {
            background-color: ${hover};
            padding-top: 125px;
            padding-bottom: 125px;

            .sub-title {
              padding-bottom: 0;

              &:after {
                content: none;
              }
            }

            p {
              font-size: 18px;
              font-weight: 500;
              line-height: 28px;
              color: ${whiteText};
              margin-bottom: 32px;
            }
          }

          &:nth-child(even) {
            .mission-vision__item {
              transform: translateY(-80px);
              background-color: ${primaryText};
              padding-left: ${props => props.offset}px;
              padding-right: ${props => props.offset}px;

              .sub-title {
                color: ${hover};
              }
            }
          }

          &:nth-child(odd) {
            .mission-vision__item {
              padding-left: ${props => props.offset}px;
              padding-right: ${props => props.offset}px;
              transform: translateY(-180px);
            }
          }

        }
      }
    }
  }

  @media (max-width: 1680px) {
    &.mission-vision {
      .mission-vision {
        &__wrap {
          &__single {
            .mission-vision__item {
              .sub-title {
                font-size: 40px;
                line-height: 50px;
              }
              p{
                font-size: 16px;
                line-height: 21px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    &.mission-vision {
      .mission-vision {
        &__wrap {
          &__single {
            .mission-vision__item {
              padding-top: 85px;
              padding-bottom: 65px;
            }
            &:nth-child(even) {
              .mission-vision__item {
                transform: translateY(0);
              }
            }

            &:nth-child(odd) {
              .mission-vision__item {
                transform: translateY(-100px);
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    &.mission-vision {
      .imgWrap{
        display: none;
      }
      .mission-vision {
        &__wrap {
          flex-direction: column;

          &__single {
            padding-left: 15px;
            padding-right: 15px;
            .mission-vision__item {
              padding-top: 85px;
              padding-bottom: 65px;
              .sub-title {
                font-size: 30px;
                line-height: 50px;
              }
            }
            &:nth-child(even) {
              .mission-vision__item {
                transform: translateY(0);
              }
            }

            &:nth-child(odd) {
              .mission-vision__item {
                transform: translateY(0);
              }
            }
          }
        }
      }
    }
  }

`;

export default MissionVision;