import {PRODUCT} from "../../constants/product";

export function fetchProductMain(params) {
  return {
    type: PRODUCT.FETCH_PRODUCT.MAIN,
    params
  }
}

export function fetchProductPage(params) {
  return {
    type: PRODUCT.FETCH_PRODUCT_PAGE.MAIN,
    params
  }
}