import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import ReactHtmlParser from "react-html-parser";

// components
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {ParallaxProvider} from 'react-scroll-parallax';
import InnerBanner from "../../components/InnerBanner";
import ProductListing from "./components/";
import { useHistory, useParams } from 'react-router-dom';

// Dynamic Image
import BannerImage from "../../assets/images/dynamic/AboutBanner.jpg";

// Action
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import BlurImg from "../../assets/images/blur.jpg";
import {fetchProductCategory} from "../../redux/actions/home";
import {fetchProductMain, fetchProductPage} from "../../redux/actions/product";

const ProductPage = ({
                         getProductCategory,
                         productCategoryData,
                         getProduct,
                         productMain,
                         getProductPage,
                         productPage
                     }) => {
    let { categories } = useParams();

    useEffect(() => {
        // if (categories === undefined){
            window.scrollTo(0, 0);
        // }

        let productPageParam = {
            [ApiParams.page_type]: 'product'
        };
        getProductPage(productPageParam)
    }, []);

    // let catSlug = categories;


    useEffect(() => {
        if (productCategoryData) {
            getProductCategory();
        }
    }, []);

    useEffect(() => {
        let productCatParam = {
            [ApiParams.cat_slug]: categories && categories.length ? categories : 'mens'
        };
            getProduct(productCatParam)
    }, []);

    let bannerImageData = productPage?.images?.list ? productPage?.images?.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = bannerImageData?.path ? baseUrl + bannerImageData?.path + bannerImageData?.img_name : BlurImg;

    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <ParallaxProvider>
                <InnerBanner image={bannerImagePath} subTitle={'Products'}
                             text={'OUR SPECIALIZED* DENIM APPARELS'}/>
                <ProductListing data={productMain} categoryData={productCategoryData} categorySlug={categories}/>
            </ParallaxProvider>
        </motion.div>
    );
};


function mapStateToProps(state) {
    return {
        productCategoryData: state.homeReducer.productCategoryData,
        productMain: state.productReducer.productMain,
        productPage: state.productReducer.productPage,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getProductCategory: () => dispatch(fetchProductCategory()),
        getProduct: params => dispatch(fetchProductMain(params)),
        getProductPage: params => dispatch(fetchProductPage(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);