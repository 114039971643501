import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import CountUp from 'react-countup';
import {Parallax, useController} from "react-scroll-parallax";
import {baseUrl} from "../../../components/BaseUrl";
import htmlParser from "react-html-parser";
import VisibilitySensor from "react-visibility-sensor";
import {hover, primaryText, secondaryText, tertiaryText, whiteText} from "../../../globalStyleVars";
import ImageModal from "../../../components/ImageModal";
import BlurImg from "../../../assets/images/blur.jpg";


const AboutStrength = ({data, offset}) => {
    const [show, setShow] = useState(false);
    const [slug, setSlug] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = (show, slug) => {
        setShow(show);
        setSlug(slug)
    };

    let filterDetail = data?.posts?.list && data?.posts?.list.length ? data.posts.list.find(f => f.data.slug === slug) : '';
    let strengthImageData = filterDetail?.images ? filterDetail?.images.find(e => e.is_thumb === 'on') : '';
    let strengthImagePath = strengthImageData?.path ? baseUrl + strengthImageData?.path + strengthImageData?.img_name : BlurImg;


    const {parallaxController} = useController();
    if (window.screen.width < 769) {
        parallaxController.destroy();
    }
    return (
        <StyledStrength className="">
            <Container fluid>
                <Row>
                    {
                        data?.posts?.list ?
                            data?.posts?.list.map((strength, sKey) => {
                                let parallaxArray = {0: [0, -30], 1: [0, -80], 2:[0, -20], 3:[0, -50]}
                                return (
                                    <div className="strengthSingle" onClick={() => handleShow(true, strength?.data?.slug)}>
                                        <Parallax y={parallaxArray[sKey]} tagOuter="figure">
                                            <div className="strengthSingle__wrap">
                                                <div className="strengthSingle__wrap__inner">
                                                    <h4>
                                                        <CountUp start={0} end={strength?.data?.extra_data}>
                                                            {({countUpRef, start}) => (
                                                                <VisibilitySensor onChange={start}
                                                                                  partialVisibility={{top: 0, bottom: 20}}
                                                                                  delayedCall>
                                                                    <span ref={countUpRef}></span>
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>+
                                                    </h4>
                                                    <p>{strength?.data?.title}</p>
                                                </div>
                                            </div>
                                        </Parallax>
                                    </div>
                                )
                            })
                            :
                            ''
                    }
                </Row>
            </Container>
            <ImageModal handleClose={handleClose} show={show}
                   title={filterDetail?.data?.title} thumbImage={strengthImagePath}/>
        </StyledStrength>
    )
};

const StyledStrength = styled.section`
  background-color: #F1F1F1;

  .strengthSingle {
    flex: 0 0 25%;
    cursor: pointer;

    &__wrap {
      position: relative;
      //flex: 0 0 25%;
      padding-top: 100%;

      &__inner {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        padding: 50px 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h4 {
          font-size: 80px;
          font-weight: 600;
          color: ${hover};
          line-height: 80px;
        }

        p {
          font-size: 18px;
          line-height: 24px;
          font-weight: 500;
          color: ${whiteText};
          margin-bottom: 0;
        }
      }
    }

    &:nth-of-type(1) {
      .strengthSingle__wrap {
        background-color: ${hover};
        transform: translate(0px, -70px);

        &__inner {
          h4 {
            color: ${whiteText};
          }

          p {
            color: ${whiteText};
          }
        }
      }
    }

    &:nth-of-type(2) {
      .strengthSingle__wrap {
        background-color: ${whiteText};
        transform: translate(0px, 30px);

        &__inner {
          p {
            color: ${secondaryText};
          }
        }
      }
    }

    &:nth-of-type(3) {
      .strengthSingle__wrap {
        background-color: ${primaryText};
        transform: translate(0px, -60px);

        &__inner {
          p {
            color: ${whiteText};
          }
        }
      }
    }

    &:nth-of-type(4) {
      .strengthSingle__wrap {
        background-color: ${primaryText};
        border-left: 1px solid rgba(165, 165, 166, 0.2);
        transform: translate(0px, 0px);
      }
    }
  }

  @media (max-width: 1680px) {
    .strengthSingle {
      &__wrap {
        &__inner {
          h4 {
            font-size: 60px;
            font-weight: 600;
            color: ${hover};
            line-height: 60px;
          }
          p{
            font-size: 16px;
            line-height: 21px;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .strengthSingle {
      &__wrap {
        &__inner {
          padding: 40px 40px;

          h4 {
            font-size: 40px;
          }
        }
      }
      &:nth-of-type(1){
        .strengthSingle__wrap {
          transform: translate(0px, -40px);
        }
      }
      &:nth-of-type(2){
        .strengthSingle__wrap {
          transform: translate(0px, 30px);
        }
      }
      &:nth-of-type(3){
        .strengthSingle__wrap {
          transform: translate(0px, -35px);
        }
      }
      &:nth-of-type(4){
        .strengthSingle__wrap {
          transform: translate(0px, 15px);
        }
      }
    }
  }

  @media (max-width: 768px) {

  }

  @media (max-width: 767px) {
    .strengthSingle {
      flex: 0 0 50%;
      &__wrap {
        padding-top: calc(230 / 207 * 100%);
        &__inner {
          padding: 40px 20px;
          p{
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
      &:nth-of-type(1){
        .parallax-inner{
          transform: translate(0%, 0%) !important;
        }
        .strengthSingle__wrap {
          transform: translate(0px, 0);
        }
      }
      &:nth-of-type(2){
        .parallax-inner{
          transform: translate(0%, 0%) !important;
        }
        .strengthSingle__wrap {
          transform: translate(0px, 0px);
        }
      }
      &:nth-of-type(3){
        .parallax-inner{
          transform: translate(0%, 0%) !important;
        }
        .strengthSingle__wrap {
          transform: translate(0px, 0px);
        }
      }
      &:nth-of-type(4){
        .parallax-inner{
          transform: translate(0%, 0%) !important;
        }
        .strengthSingle__wrap {
          transform: translate(0px, 0px);
        }
      }
    }
  }

`;

export default AboutStrength;