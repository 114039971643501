import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Accordion, Col, Container, Row} from 'react-bootstrap';
import {hover, whiteText, primaryText, secondaryText, tertiaryText} from '../globalStyleVars';
import {Link, useLocation} from 'react-router-dom';
import {gsap, TimelineLite} from "gsap/gsap-core";
import {CSSPlugin} from 'gsap/CSSPlugin';

// Images
import footerImage from '../assets/images/footer-image.jpg';
import mainLogo from '../assets/images/logo.svg';
import hamburger from '../assets/images/hamburger.svg';
import hamburgerClose from '../assets/images/hamburger-close.svg';

import fbSocial from '../assets/images/fb.svg';
import insSocial from '../assets/images/ins.svg';
import ytSocial from '../assets/images/yt.svg';
import inSocial from '../assets/images/in.svg';
import socialSprite from '../assets/images/social-sprite.svg';

import caret from '../assets/images/caret-down.svg'

const Menu = ({logo, color, img, setOffset}) => {
    const location = useLocation()
    const offsetCalc = useRef();
    gsap.registerPlugin(CSSPlugin);
    const [tl] = useState(new TimelineLite());

    let {pathname} = useLocation();

    const hamburgerRef = useRef(null)
    const menuRef = useRef(null)
    const mobileMenuRaf = useRef(null)

    useEffect(() => {
        window.addEventListener('scroll', function () {
            if (window.pageYOffset > 300) {
                // setNewClass('menu-fixed')
                document.querySelector(".menu-bar").classList.add('menu-fixed')
            } else {
                // setNewClass('menu')
                // document.querySelector(".menu-bar").classList.remove('menu-fixed')
            }
        });

        // menu click on mobile
        hamburgerRef.current.addEventListener('click', () => {

            if (!mobileMenuRaf.current.classList.contains('menu-open')) {
                mobileMenuRaf.current.classList.add('menu-open');
                hamburgerRef.current.classList.add('menu-open');
                setTimeout(() => {
                    menuRef.current.style.cssText = `opacity:1`;
                }, 100)
                document.querySelector('.menu-bar').classList.add('menu-open')
                document.querySelector('body').classList.add('open')
            } else {
                menuRef.current.style.cssText = `opacity:0`;
                hamburgerRef.current.classList.remove('menu-open');
                setTimeout(() => {
                    mobileMenuRaf.current.classList.remove('menu-open')
                }, 100)
                document.querySelector('.menu-bar').classList.remove('menu-open')
                document.querySelector('body').classList.remove('open')
            }
        })

        let getLinkTag = document.querySelectorAll('.mobile-menu__items__ul li a');

        for (let i of getLinkTag) {
            i.addEventListener('click', () => {
                menuRef.current.style.cssText = `opacity:0`;
                hamburgerRef.current.classList.remove('menu-open');
                setTimeout(() => {
                    mobileMenuRaf.current.classList.remove('menu-open')
                }, 1000)
            })
        }

        // sub menu

        if (window.screen.width < 769) {
            let menuSub = document.querySelectorAll('.has-sub');
            let menuOpen = document.querySelector('.menu-down');
            let menuOpenInner = document.querySelector('.menu-down ul');

            for (let i of menuSub) {

                i.addEventListener('click', () => {

                    if (!i.classList.contains('menu-down')) {
                        let getLiLength = i.querySelectorAll('ul li').length * i.querySelector('ul li').offsetHeight - 20;
                        let ulSelect = i.querySelector('ul');
                        tl.to(ulSelect, {
                            duration: 0,
                            visibility: 'visible'
                        });

                        tl.to(ulSelect, {
                            duration: .4,
                            opacity: '1',
                            height: getLiLength,
                            marginTop: '30px'
                        });
                        i.classList.add('menu-down');
                    } else {
                        let hasMenuDown = i.querySelector('ul');
                        tl.to(hasMenuDown, {
                            duration: .4,
                            height: 0,
                            opacity: 0,
                            marginTop: '0px',
                            visibility: 'hidden'
                        });

                        setTimeout(() => {
                            i.classList.remove('menu-down')
                        }, 500)
                    }

                })

            }
        }


    }, [mobileMenuRaf]);

    useEffect(() => {
        document.querySelector('.menu-bar').classList.remove('menu-open')
        document.querySelector('body').classList.remove('open')
    }, [location])


    setOffset(offsetCalc?.current?.offsetLeft + 15);
    window.addEventListener('resize', function() {
        setOffset(offsetCalc?.current?.offsetLeft + 15);
    });


    return (
        <StyledMenu className='menu-bar'>
            <Container ref={offsetCalc}>
                <Row className='desktop-menu'>
                    <Col sm={3} className='align-items-center d-flex'>
                        <Link to={'/'}><img src={mainLogo} alt="mainLogo"/></Link>
                    </Col>

                    <Col sm={9}>
                        <ul className=''>
                            <li className={pathname === '/' ? 'active' : ''}><Link to={'/'}>Home</Link></li>
                            <li className={pathname === '/about-us' ? 'active' : ''}><Link to={'/about-us'}>About
                                Us</Link></li>
                            <li className={pathname === '/products' ? 'active' : ''}><Link
                                to={'/products/mens'}>Products</Link></li>
                            <li className={pathname === '/services' ? 'active' : ''}><Link
                                to={'/services'}>Services</Link></li>
                            <li className={pathname === '/media' ? 'active' : ''}><Link to={'/media/events'}>Media</Link></li>
                            <li className={pathname === '/gallery' ? 'active' : ''}><Link to={'/gallery/photo'}>Gallery</Link></li>
                            <li className={pathname === '/contact-us' ? 'active' : ''}><Link to={'/contact-us'}>Contact
                                Us</Link></li>
                            {/*<li className='has-sub'>About Us*/}
                            {/*    <span><img src={caret} alt={caret}/></span>*/}
                            {/*    <ul>*/}
                            {/*        <li className={pathname === '/about' ? 'active' : ''}><Link*/}
                            {/*            to={'/about'}>About</Link></li>*/}
                            {/*        <li className={pathname === '/team' ? 'active' : ''}><Link to={'/team'}>Our*/}
                            {/*            team</Link></li>*/}
                            {/*    </ul>*/}
                            {/*</li>*/}
                        </ul>
                    </Col>

                </Row>


                <Row className="mobile-menu" ref={mobileMenuRaf}>
                    <Col className='logo'>
                        <Link to={'/'}><img src={mainLogo} alt="mainLogo"/></Link>
                    </Col>

                    <Col className='hamburger' ref={hamburgerRef}>
                        <div className="hamburger__bgc">
                            <img src={hamburger} alt=""/>
                            <img src={hamburgerClose} alt=""/>
                        </div>
                    </Col>

                    <div className="mobile-menu__items" ref={menuRef}>
                        <ul className='mobile-menu__items__ul'>
                            <li className={pathname === '/' ? 'active' : ''}><Link to={'/'}>Home</Link></li>
                            <li className={pathname === '/about-us' ? 'active' : ''}><Link to={'/about-us'}>About Us</Link></li>
                            <li className={pathname === '/products' ? 'active' : ''}><Link to={'/products/mens'}>Products</Link></li>
                            <li className={pathname === '/services' ? 'active' : ''}><Link to={'/services'}>Services</Link></li>
                            <li className={pathname === '/media' ? 'active' : ''}><Link to={'/media/events'}>Media</Link></li>
                            <li className={pathname === '/gallery' ? 'active' : ''}><Link to={'/gallery/photo'}>Gallery</Link></li>
                            <li className={pathname === '/contact-us' ? 'active' : ''}><Link to={'/contact-us'}>Contact Us</Link></li>

                            {/*<Accordion defaultActiveKey="0">*/}
                            {/*    <Accordion.Item eventKey="0">*/}
                            {/*        <Accordion.Header>About Us</Accordion.Header>*/}
                            {/*        <Accordion.Body>*/}

                            {/*            <ul>*/}
                            {/*                <li><Link to={'/about'}>About</Link></li>*/}
                            {/*                <li><Link to={'/team'}>Our team</Link></li>*/}
                            {/*            </ul>*/}
                            {/*        </Accordion.Body>*/}
                            {/*    </Accordion.Item>*/}

                            {/*    <Accordion.Item eventKey="1">*/}
                            {/*        <Accordion.Header>Projects</Accordion.Header>*/}
                            {/*        <Accordion.Body>*/}

                            {/*            <ul>*/}
                            {/*                <li><Link to={'/projects/ongoing'}>Ongoing</Link></li>*/}
                            {/*                <li><Link to={'/projects/completed'}>Upcoming</Link></li>*/}
                            {/*                <li><Link to={'/projects/upcoming'}>Completed</Link></li>*/}
                            {/*            </ul>*/}
                            {/*        </Accordion.Body>*/}
                            {/*    </Accordion.Item>*/}


                            {/*    <li><Link to={'/services'}>Services</Link></li>*/}

                            {/*    <li><Link to={'/career'}>Career</Link></li>*/}


                            {/*    <Accordion.Item eventKey="2">*/}
                            {/*        <Accordion.Header>Contact</Accordion.Header>*/}
                            {/*        <Accordion.Body>*/}
                            {/*            <ul>*/}
                            {/*                <li><Link to={'/landowner'}>Landowner</Link></li>*/}
                            {/*                <li><Link to={'/buyer'}>Buyer</Link></li>*/}
                            {/*                <li><Link to={'/contact'}>Contact</Link></li>*/}
                            {/*            </ul>*/}
                            {/*        </Accordion.Body>*/}
                            {/*    </Accordion.Item>*/}


                            {/*</Accordion>*/}

                        </ul>
                        <ul className="mobile-menu__items__social">
                            <li><Link className={'fb'} to={'https://web.facebook.com/ecosourcingltd/?_rdc=1&_rdr'} target="_blank"></Link></li>
                            <li><Link className={'ins'} to={'/'}></Link></li>
                            <li><Link className={'yt'} to={'/'}></Link></li>
                            <li><Link className={'in'} to={'https://www.linkedin.com/company/ecosourcingltd/?originalSubdomain=bd'} target="_blank"></Link></li>
                        </ul>
                    </div>

                </Row>

            </Container>
        </StyledMenu>
    )
};

const StyledMenu = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 999;
  background-color: ${whiteText};
  display: flex;
  align-items: center;
  transition: background .4s ease;

  &.menu-fixed {
    position: fixed;
  }

  img {
    height: 100px;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .desktop-menu {
    ul {
      display: flex;
      justify-content: flex-end;

      li {
        display: flex;
        height: 100px;
        align-items: center;
        color: ${secondaryText};
        margin-right: 50px;
        cursor: pointer;

        &:hover {
          color: ${hover} !important;
        }

        a {
          display: flex;
          height: 80px;
          align-items: center;
          color: ${secondaryText};
          font-size: 15px;
          font-weight: 500;

          &:hover {
            color: ${hover} !important;
          }
        }

        &:nth-last-child(1) {
          margin-right: 0;
        }

        &.has-sub {
          position: relative;

          span {
            margin-left: 10px;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            border: 1px solid #FFF;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            &:after {
              content: '';
              height: 0;
              width: 0;
              background-color: #A65E58;
              position: absolute;
              border-radius: 50%;
              transition: all .3s ease;
            }

            img {
              height: 4px;
              z-index: 3;
            }
          }

          ul {
            opacity: 0;
            position: absolute;
            visibility: hidden;
            top: 84px;
            min-width: 200px;
            background-color: #5D4E4D;
            padding: 30px;
            justify-content: start;
            transition: all .6s ease;
            display: flex;
            flex-wrap: wrap;
            //box-shadow: 0px 6px 13px rgba(255, 255, 255, 0.05);
            box-shadow: 0 6px 30px rgb(0 0 0 / 24%);

            &:after {
              content: '';
              width: 0;
              height: 0;
              border-left: 0px solid transparent;
              border-right: 18px solid transparent;
              border-bottom: 16px solid ${hover};
              top: -16px;
              position: absolute;
            }

            li {
              height: auto;
              margin: 0;
              width: 100%;

              &:hover {
                color: rgba(255, 255, 255, 0.6) !important;
              }

              a {
                height: auto;
                font-size: 14px;
                margin: 0 0 10px 0;
                white-space: nowrap;

                &:hover {
                  color: rgba(255, 255, 255, 0.6) !important;
                }
              }

              &:nth-last-child(1) a {
                margin-bottom: 0;
              }
            }

          }


        }


        &:hover {
          span {
            border-color: #A65E58;

            &:after {
              height: 100%;
              width: 100%;
            }
          }

          ul {
            opacity: 1;
            visibility: visible;
          }
        }

        &:nth-last-child(1) {
          ul {
            left: -109px;

            &:after {
              right: 50px;
            }
          }
        }
      }

    }
  }

  .mobile-menu {
    display: none;

    .logo {
      a {
        display: flex;
        img{
          height: 40px;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    .hamburger {
      position: relative;
      display: flex;
      align-items: center;

      &__bgc {
        position: absolute;
        width: 60px;
        display: flex;
        height: 60px;
        background-color: ${hover};
        right: 0;
        justify-content: center;
        align-items: center;

        img {
          width: 24px;
          padding-top: 0;
          padding-bottom: 0;

          &:nth-of-type(1) {

          }

          &:nth-of-type(2) {
            display: none;
          }
        }
      }


      &.menu-open {
        .hamburger__bgc {
          background-color: ${primaryText};

          img {
            &:nth-of-type(1) {
              display: none;
            }

            &:nth-of-type(2) {
              display: block;
              right: 20px;
            }
          }
        }
      }
    }

    &__items {
      display: none;
      position: absolute;
      opacity: 0;
      background-color: ${hover};
      height: calc(100vh - 60px);
      top: 60px;
      left: 0;
      width: 100%;
      z-index: 99;
      padding: 65px 30px 60px 30px;
      overflow: auto;
      transition: opacity .5s ease;

      ul {
        position: relative;
        z-index: 9;
        li {
          padding-bottom: 15px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.25);
          margin-bottom: 15px;
          font-size: 20px;
          font-weight: 500;
          text-transform: capitalize;
          &.active{
            a{
              color: ${primaryText} !important;
            }
          }

          a {
            display: block;
            color: #ffffff;
            &:hover{
              color: ${primaryText} !important;
            }
          }
        }
      }
      &:before{
        content: '';
        position: absolute;
        background-image: url(${footerImage});
        width: 100%;
        padding-top: 83.82%;
        background-size: cover;
        bottom: 0;
        right: 0;
        z-index: 1;
        pointer-events: none;
      }
      &__social{
        position: absolute !important;
        bottom: 0;
        display: flex;
        li{
          border-bottom: none !important;
          padding-right: 20px;
          a{
            background-image: url(${socialSprite});
            width: 35px;
            height: 35px;
            display: block;
            background-repeat: no-repeat;
            transition: background-position .2s ease-in;
            &.fb{
              background-position-x: -10px;
              background-position-y: -4px;
              
              &:hover{
                background-position-y: -44px;
              }
            }
            &.ins{
              background-position-x: -55px;
              background-position-y: -4px;
              background-repeat: no-repeat;
              &:hover{
                background-position-y: -44px;
              }
            }
            &.yt{
              background-position-x: -100px;
              background-position-y: -4px;
              background-repeat: no-repeat;
              &:hover{
                background-position-y: -44px;
              }
            }
            &.in{
              background-position-x: -145px;
              background-position-y: -4px;
              background-repeat: no-repeat;
              &:hover{
                background-position-y: -44px;
              }
            }
          }
          
        }
      }
    }

    &.menu-open {

      .mobile-menu__items {
        display: block;
      }

    }

    //accordion
    .accordion-header {
      .accordion-button {
        background-color: transparent;
        font-size: 15px;
        line-height: 20px;
        color: #ffffff;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        padding: 0;
        box-shadow: none;
        border: none;
        margin-bottom: 32px;
        padding-bottom: 16px;
        border-bottom: 1px solid #CFCDCC;

        img {
          height: 20px;
        }

        &.collapsed {
          border-bottom: 1px solid #FFF;
        }
      }


    }

    .accordion-body {
      margin-bottom: 50px;

      ul {
        li {
          border: none;
          margin-bottom: 22px;
          padding-bottom: 0;

          &:after {
            display: none;
          }
        }
      }
    }


  }

  &.menu-fixed, &.menu-open {
    background-color: ${whiteText};
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.24);
  }

  .active a {
    color: ${hover} !important;
  }


  @media (min-width: 1680px) {
    .desktop-menu{
      ul{
        li{
          a{
            font-size: 18px;
          }
        }
      }
    }
  }
  
  @media (max-width: 1680px) {

  }
  
  @media (max-width: 1536px) {

  }
  
  @media (max-width: 1440px) {

  }
  
  @media (max-width: 1366px) {

  }
  
  @media (max-width: 1280px) {
    .desktop-menu {
      ul {
        li {
          margin-right: 30px;
          a{
            font-size: 18px;
          }
        }
      }
    }
  }
  
  @media (max-width: 1024px) {
    .desktop-menu {
      ul {
        li {
          margin-right: 20px;
          a{
            font-size: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    .desktop-menu {
      ul {
        li {
          margin-right: 15px;
          a{
            font-size: 14px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    background-color: ${whiteText};
    //border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    height: 60px;
    overflow: visible;
    .desktop-menu {
      display: none;
    }

    .mobile-menu {
      display: flex;
      height: 60px;
      align-items: center;
    }

  }

  @media (max-width: 767px) {
    &:after {
      right: 65px;
    }
  }


`;


export default Menu;
