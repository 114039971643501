import React, {useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {Link} from "react-router-dom";
import BlurImg from "../../../assets/images/blur.jpg";
import ModalVideo from "react-modal-video";
import SubTitle from "../../../components/SubTitle";
import {baseUrl} from "../../../components/BaseUrl";
import {Parallax} from "react-scroll-parallax";
import {hover, primaryText, secondaryText, whiteText} from "../../../globalStyleVars";
import VisibilitySensor from "react-visibility-sensor";


const VideoGallery = ({data, parentData, gallerySlug}) => {
    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');

    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
    };
    return (
        <>
            <StyledGalleryMenu className="">
                <Container fluid>
                    <Row>
                        <div className="gallerySingle">
                            <Parallax y={[0, 0]} tagOuter="figure">
                                <Link to={'/gallery/photo'}>
                                    <div
                                        className={`gallerySingle__wrap ${'photo' === gallerySlug ? 'active' : ''}`}>
                                        <div className="gallerySingle__wrap__inner">
                                            <h4>01</h4>
                                            <p>Photo</p>
                                        </div>
                                    </div>
                                </Link>

                            </Parallax>
                        </div>
                        <div className="gallerySingle">
                            <Parallax y={[0, 0]} tagOuter="figure">
                                <Link to={'/gallery/video'}>
                                    <div
                                        className={`gallerySingle__wrap ${'video' === gallerySlug ? 'active' : ''}`}>
                                        <div className="gallerySingle__wrap__inner">
                                            <h4>02</h4>
                                            <p>Video</p>
                                        </div>
                                    </div>
                                </Link>

                            </Parallax>
                        </div>
                    </Row>
                </Container>
            </StyledGalleryMenu>
            <StyledGalleryList>
                <ModalVideo channel='youtube' isOpen={open} videoId={videoId}
                            onClose={() => handelOpen(false, '')}/>
                <Container>
                    {
                        data?.images?.list.length && data.images.list ?
                            <Row>
                                {
                                    data.images.list.map((item, iKey) => {

                                        let imagePath = item?.path ? baseUrl + item?.path + item?.img_name : BlurImg;
                                        return (
                                            <Col key={iKey} md={6}>
                                                <div className="productSingle"
                                                     onClick={() => handelOpen(true, `${item ? item.short_title : ''}`)}>
                                                    <div className="productSingle__img">
                                                        <img src={imagePath}/>
                                                        <div className="video-play">

                                                            <svg id="Component_26_1" data-name="Component 26 – 1" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
                                                                <circle id="Ellipse_6" data-name="Ellipse 6" cx="35" cy="35" r="35" fill="#80c42b"/>
                                                                <circle id="Ellipse_7" data-name="Ellipse 7" cx="1" cy="1" r="1" transform="translate(34 34)" fill="#fff" opacity="0"/>
                                                                <g id="Polygon_1" data-name="Polygon 1" transform="translate(44 24) rotate(90)" fill="none">
                                                                    <path d="M9.419,1.872a1.5,1.5,0,0,1,2.5,0l7.864,11.8A1.5,1.5,0,0,1,18.531,16H2.8a1.5,1.5,0,0,1-1.248-2.332Z" stroke="none"/>
                                                                    <path d="M 10.66666603088379 2.704167366027832 L 2.80278205871582 14.49998760223389 C 2.802783966064453 14.49999046325684 2.802785873413086 14.4999942779541 2.802776336669922 14.49999713897705 L 18.53054809570312 14.49999713897705 C 18.53055000305176 14.49999523162842 18.53055191040039 14.49999332427979 18.53055572509766 14.49999713897705 L 10.66667556762695 2.704176902770996 C 10.666672706604 2.704172134399414 10.66667079925537 2.704168319702148 10.66666603088379 2.704167366027832 M 10.66666698455811 1.204167366027832 C 11.14227104187012 1.204167366027832 11.61787605285645 1.426816940307617 11.91474628448486 1.872117042541504 L 19.77863693237305 13.66794681549072 C 20.44318580627441 14.66477680206299 19.72860527038574 15.99999713897705 18.53055572509766 15.99999713897705 L 2.802776336669922 15.99999713897705 C 1.604736328125 15.99999713897705 0.8901462554931641 14.66477680206299 1.554695129394531 13.66794681549072 L 9.418596267700195 1.872117042541504 C 9.715460777282715 1.426816940307617 10.19106388092041 1.204167366027832 10.66666698455811 1.204167366027832 Z" stroke="none" fill="#fff"/>
                                                                </g>
                                                            </svg>

                                                        </div>
                                                    </div>
                                                    <div className="productSingle__title">
                                                        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                                            {({isVisible}) =>
                                                                <h3 className={`${isVisible ? 'anim-active' : ''} fade-right`}>{item?.short_desc.split("").map(function (char, index) {
                                                                    return <span aria-hidden="true"
                                                                                 key={index}>{char}</span>;
                                                                })}</h3>
                                                            }
                                                        </VisibilitySensor>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                            : <SubTitle color={'#545355'} fontSize={45} lineHeight={56} fontWeight={'500'}
                                        text={parentData?.page_data?.sub_title}/>
                    }
                </Container>
            </StyledGalleryList>
        </>
    )
};

const StyledGalleryMenu = styled.section`
  background-color: #F1F1F1;

  .gallerySingle {
    flex: 0 0 50%;

    &__wrap {
      position: relative;
      //flex: 0 0 25%;
      padding-top: calc(300 / 683 * 100%);
      background-color: ${primaryText};
      transition: all .4s ease;

      &.active {
        background-color: ${hover};

        .gallerySingle {
          &__wrap {
            &__inner {
              h4 {
                background-color: ${whiteText};
                color: ${hover};
              }
            }
          }
        }
      }

      &__inner {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        padding: 50px 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h4 {
          font-size: 16px;
          font-weight: 500;
          color: ${whiteText};
          line-height: 50px;
          width: 50px;
          height: 50px;
          border: 1px solid ${whiteText};
          border-radius: 50%;
          padding-left: 15px;
          transition: all .4s ease;
        }

        p {
          font-size: 32px;
          line-height: 40px;
          font-weight: 500;
          color: ${whiteText};
          margin-bottom: 0;
          text-transform: uppercase;
        }
      }
    }

    a {
      &:hover {
        .gallerySingle {
          &__wrap {
              //background-color: ${hover};

            &__inner {
              h4 {
                background-color: ${whiteText};
                color: ${hover};
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1366px) {
    .gallerySingle {
      &__wrap {
        &__inner {
          p {
            font-size: 24px;
            line-height: 32px;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .gallerySingle {
      &__wrap {
        &__inner {
          padding: 40px 40px;
        }
      }
    }
  }

  @media (max-width: 768px) {

  }

  @media (max-width: 767px) {
    .gallerySingle {
      flex: 0 0 50%;

      &__wrap {
        padding-top: calc(123 / 207 * 100%);

        &__inner {
          padding: 20px 20px;

          h4 {
            width: 30px;
            height: 30px;
            font-size: 12px;
            line-height: 30px;
            padding-left: 7px;
          }

          p {
            font-size: 20px;
            line-height: 26px;
          }
        }
      }

    }
  }

`;
const StyledGalleryList = styled.section`
  padding-top: 180px;
  padding-bottom: 180px;

  .sub-title {
    text-transform: initial;

    &:after {
      content: none;
    }
  }

  .productSingle {
    position: relative;
    margin-bottom: 30px;
    cursor: pointer;

    &__img {
      position: relative;
      padding-top: calc(350 / 570 * 100%);
      overflow: hidden;

      img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1.01);
        transition: transform .4s ease;
      }
      .video-play{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(49, 48, 51, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 60px;
          height: 60px;

          circle#Ellipse_7 {
            cx: 35;
            cy: 35;
            r: 1;
            transform: translate(0, 0) !important;
            fill: rgb(255, 255, 255);
            // stroke: rgb(255, 255, 255);
            transition: all .3s ease;
          }
        }
      }
    }

    &__title {
      padding-left: 10px;
      padding-top: 20px;

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: ${hover};
        margin-bottom: 10px;
      }

      h3 {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        color: ${secondaryText};
        margin-bottom: 20px;
      }

      &__btn {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        svg {
          transform: rotate(0deg);
          transition: transform .4s ease;

          circle#Hover {
            cx: 15;
            cy: 15;
            r: 0.1;
            transform: translate(0, 0) !important;
            fill: ${whiteText};
            stroke: ${whiteText};
            opacity: 0;
            transition: all .3s ease;
          }

          #Icon {
            line, path {
              stroke: ${whiteText};
            }
          }
        }

        span {
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          color: ${primaryText};
          margin-left: 10px;
        }
      }
    }

    a {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      display: flex;
      justify-content: flex-end;

    }

    &:hover {
      .productSingle {
        &__img {
          img {
            transform: scale(1.04);
          }
          .video-play{
            circle#Ellipse_7 {
              r: 35;
              opacity: 1;
            }

            g {
              fill: #545355;
            }
          }
        }

        &__title {
          &__btn {
            svg {
              transform: rotate(45deg);

              circle#Hover {
                r: 15;
                opacity: 1;
              }

              #news-icon {
                fill: ${whiteText};
                stroke: ${whiteText};
              }

              #Icon {
                line, path {
                  stroke: ${hover};
                }
              }
            }
          }
        }
      }

    }
  }

  @media (max-width: 1680px) {
    padding-top: 150px;
    padding-bottom: 150px;

    .productSingle {
      position: relative;
      margin-bottom: 45px;

      &__img {
        position: relative;
        padding-top: calc(300 / 370 * 100%);
        overflow: hidden;

        img {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transform: scale(1.01);
          transition: transform .4s ease;
        }
      }

      &__title {
        padding-left: 10px;
        padding-top: 20px;

        p {
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          color: ${hover};
          margin-bottom: 10px;
        }

        h3 {
          font-size: 18px;
          line-height: 24px;
        }

        &__btn {
          span {
            font-size: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {

  }

  @media (max-width: 767px) {
    padding-top: 80px;
    padding-bottom: 80px;
    .productSingle {
      &__img {
        padding-top: calc(250 / 374 * 100%);
      }
    }
  }
`;

export default VideoGallery;
