import {PRODUCT} from "../../constants/product";

let initialState = {
    productMain: '',
    productMainLoading: false,
    productMainErr: '',
    productPage: '',
    productPageLoading: false,
    productPageErr: '',
};

export default function productReducer(state = initialState, action) {
    switch (action.type) {
        case PRODUCT.FETCH_PRODUCT.MAIN:
            return {
                ...state,
                productMain: '',
                productMainLoading: true,
                productMainErr: ''
            };

        case PRODUCT.FETCH_PRODUCT.SUCCESS:
            return {
                ...state,
                productMain: action.result,
                productMainLoading: false,
                productMainErr: ''
            };

        case PRODUCT.FETCH_PRODUCT.FAILURE:
            return {
                ...state,
                productMain: '',
                productMainLoading: false,
                productMainErr: 'Error from Server'
            };
        case PRODUCT.FETCH_PRODUCT_PAGE.MAIN:
            return {
                ...state,
                productPage: '',
                productPageLoading: true,
                productPageErr: ''
            };

        case PRODUCT.FETCH_PRODUCT_PAGE.SUCCESS:
            return {
                ...state,
                productPage: action.result,
                productPageLoading: false,
                productPageErr: ''
            };

        case PRODUCT.FETCH_PRODUCT_PAGE.FAILURE:
            return {
                ...state,
                productPage: '',
                productPageLoading: false,
                productPageErr: 'Error from Server'
            };
        default:
            return state
    }
}
