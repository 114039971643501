import {GALLERY} from "../../constants/gallery";

export function fetchGalleryMain(params) {
  return {
    type: GALLERY.FETCH_GALLERY_PAGE.MAIN,
    params
  }
}

export function fetchGalleryChild(params) {
  return {
    type: GALLERY.FETCH_GALLERY_CHILD.MAIN,
    params
  }
}

