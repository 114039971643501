import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {hover, primaryText, secondaryText, tertiaryText, whiteText} from '../../../globalStyleVars';
import {Col, Container, Row, Form, Button} from "react-bootstrap";
import {ApiParams} from "../../../constants/apiParams";
import {connect} from "react-redux";
import Title from "../../../components/Title";
import {useForm} from 'react-hook-form';
import {fetchFormData, postFormSubmit} from "../../../redux/actions/form";
import {Parallax, useController} from "react-scroll-parallax";

// Image

const ContactForm = ({getFormData, postFormSubmit, formFields, popup, offset}) => {
        const {parallaxController} = useController();
        if (window.screen.width < 769) {
            parallaxController.destroy();
        }
        useEffect(() => {

            let mainParam = {
                [ApiParams.form_slug]: 'contact-form'

            };
            getFormData(mainParam);

        }, []);

        const {register, handleSubmit, formState: {errors}} = useForm();
        const onSubmit = (data) => {
            let bodyFormData = new FormData()
            for (let key in data) {
                bodyFormData.append(`${key}`, data[key])
            }

            postFormSubmit(bodyFormData);
        };

        return (
            <StyledContactForm offset={offset}>
                <Container>
                    <Row>
                        <Col md={{span: 7, offset: 5}}>
                            <div className="contact-form__title">
                                <Title fontSize={'50'} lineHeight={'60'} fontWeight={300} color={hover}
                                       textTransform={'uppercase'} text={'Get in touch'} margin={'0 0 20px 0'}/>
                                <p>Fill up the form & submit, Our team member contact you asap.</p>
                            </div>
                            <div className="contact-form__fields">
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <input hidden {...register("form_id")} type="text" value="contact-form"/>
                                    <input hidden {...register("spam_protector")} type="text" value=""/>
                                    <Row>
                                        <Col md={6} className={'contact-col'}>
                                            <Form.Group>
                                                <Form.Control
                                                    size="lg"
                                                    type="text"
                                                    placeholder="Name"
                                                    className={errors?.name ? 'has-error' : ''}
                                                    {...register('name',
                                                        {
                                                            required: 'Name can not be blank',
                                                            maxLength: 80,
                                                            pattern: {
                                                                value: /(.|\s)*\S(.|\s)*/i,
                                                                message: 'Name can not be blank'
                                                            }
                                                        }
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Control
                                                    size="lg"
                                                    type="email"
                                                    placeholder="name@example.com"
                                                    className={errors?.email ? 'has-error' : ''}
                                                    {...register('email',
                                                        {
                                                            required: 'Email can not be blank',
                                                            maxLength: 80,
                                                            pattern: {
                                                                value: /^\S+@\S+.\S$/i,
                                                                message: 'Please enter a valid email address'
                                                            }
                                                        }
                                                    )}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className={'contact-col'}>
                                            <Form.Group>
                                                <Form.Control
                                                    size="lg"
                                                    type="number"
                                                    placeholder="Phone Number"
                                                    className={errors?.phone ? 'has-error' : ''}
                                                    {...register('phone',
                                                        {
                                                            required: 'Contact number can not be blank',
                                                            maxLength: 16,
                                                            pattern: {
                                                                value: /^(?:\+88|01)?\d{11}\r?$/i,
                                                                message: 'Please enter a valid number'
                                                            }
                                                        }
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Control
                                                    size="lg"
                                                    type="text"
                                                    placeholder="Subject"
                                                    {...register('subject',
                                                        {
                                                            required: false,
                                                            maxLength: 80
                                                        }
                                                    )}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Form.Group>
                                        <Form.Control
                                            size="lg"
                                            as="textarea"
                                            placeholder="Message"
                                            rows={3}
                                            {...register('message',
                                                {
                                                    required: false,
                                                    maxLength: 80
                                                }
                                            )}
                                        />
                                    </Form.Group>

                                    <Button className="submitBtn" type="submit">
                                        <span>Submit</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                            <g id="Group_109" data-name="Group 109" transform="translate(0 0)">
                                                <circle id="defult-state" cx="10" cy="10" r="10" transform="translate(0 0)"
                                                        fill="#80c42b"/>
                                                <circle id="hover-state" cx="0.5" cy="0.5" r="0.5"
                                                        transform="translate(10 11)" fill="#fff" opacity="0"/>
                                                <g id="path-gorup" transform="translate(6 12.146) rotate(-45)">
                                                    <line id="line" x2="8.279" transform="translate(0 1.85)" fill="none"
                                                          stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                                    <path id="path" d="M0,0,1.9,1.89,0,3.7" transform="translate(6.79 0)"
                                                          fill="none" stroke="#fff" stroke-linecap="round"
                                                          stroke-linejoin="round" stroke-width="1"/>
                                                </g>
                                            </g>
                                        </svg>

                                    </Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="rotatedText">
                    <Parallax x={[-5, 5]} tagOuter="figure">
                        <h2>Eco Sourcing</h2>
                    </Parallax>
                </div>
            </StyledContactForm>
        )
    }
;

const StyledContactForm = styled.section`
  padding-top: 200px;
  padding-bottom: 250px;
  position: relative;
  overflow: hidden;

  .rotatedText {
    position: absolute;
    top: 0;
    left: 95px;

    /* Border is the new background */
    background: none;

    /* Rotate from top left corner (not default) */
    transform-origin: 0 0;
    transform: rotate(90deg);

    h2 {
      text-transform: uppercase;
      font-size: 100px;
      font-weight: 900;
      letter-spacing: 5px;
      color: #f1f1f1;
    }

  }

  .contact-form {
    &__title {
      margin-bottom: 40px;

      p {
        font-size: 18px;
        line-height: 27px;
        font-weight: 300;
        color: ${secondaryText};
        margin-bottom: 0;

      }
    }

    &__fields {
      input {
        height: 50px;
      }

      .form-control {
        font-size: 18px;
        line-height: 27px;
        color: ${tertiaryText};
        margin-bottom: 30px;

        &::placeholder {
          font-size: 18px;
          line-height: 27px;
          font-weight: 500;
          color: ${tertiaryText};
        }
      }

      .contact-col {
      }

      .submitBtn {
        width: 340px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border: 0;
        overflow: hidden;
        background-color: ${primaryText};

        &:before {
          content: '';
          position: absolute;
          left: -100%;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: ${hover};
          transition: all .4s ease;
          border-radius: .25rem;
          opacity: 0;
          z-index: 0;
        }

        span {
          font-size: 18px;
          line-height: 27px;
          font-weight: 500;
          color: ${whiteText};
          margin-right: 20px;
          z-index: 1;
        }

        svg {
          z-index: 1;

          circle#hover-state {
            cx: 10;
            cy: 10;
            r: 1;
            opacity: 0;
            transform: translate(0, 0) !important;
            fill: rgb(255, 255, 255);
            transition: all .4s ease;
          }

        }

        &:hover {
          &:before {
            left: 0;
            opacity: 1;
          }

          circle#hover-state {
            r: 10;
            opacity: 1;
          }

          #path-gorup {
            line, path {
              stroke: ${hover};
            }
          }
        }
      }
    }
  }

  @media (max-width: 1680px) {
    .contact-form {
      &__title {
        h2{
          font-size: 40px !important;
          line-height: 50px !important;
        }
        p {
          font-size: 16px;
          line-height: 24px;
        }
      }

      &__fields {
        .form-control {
          font-size: 16px;
          line-height: 24px;

          &::placeholder {
            font-size: 16px;
            line-height: 24px;
          }
        }
        .submitBtn {
          span {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {

  }

  @media (max-width: 768px) {

  }

  @media (max-width: 767px) {
    padding-top: 80px;
    padding-bottom: 80px;
    .rotatedText{
      display: none;
    }
    .contact-form {
      &__title {
        h2{
          font-size: 30px !important;
          line-height: 40px !important;
          margin-bottom: 15px;
        }
        p {
          font-size: 16px;
          line-height: 24px;
        }
      }

      &__fields {
        .form-control {
          font-size: 16px;
          line-height: 24px;

          &::placeholder {
            font-size: 16px;
            line-height: 24px;
          }
        }
        .submitBtn {
          width: 100%;
          span {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }

`;

function mapStateToProps(state) {
    return {
        formFields: state.formReducer.formFields,
        popup: state.formReducer.popup
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFormData: params => dispatch(fetchFormData(params)),
        postFormSubmit: data => dispatch(postFormSubmit(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
