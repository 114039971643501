import React from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
import {hover} from "../globalStyleVars";

const Title = ({text='', fontSize, fontWeight, color, letterSpacing, lineHeight, textTransform, margin}) => {
    return (

        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
            {({isVisible}) =>
                <StyledTitle className={`${isVisible ? 'anim-active' : ''} fade-right title`}
                             fontSize={fontSize}
                             fontWeight={fontWeight}
                             color={color}
                             lineHeight={lineHeight}
                             letterSpacing={letterSpacing}
                             textTransform={textTransform}
                             margin={margin}>
                    {text.split("").map(function (char, index) {
                        return <span aria-hidden="true" key={index}>{char}</span>;
                    })}
                </StyledTitle>
            }
        </VisibilitySensor>
    )
};


const StyledTitle = styled.h2`
  font-size: ${props => props.fontSize || 50}px;
  font-weight: ${props => props.fontWeight || '500'};
  margin: ${props => props.margin || '0'};;
  line-height: ${props => props.lineHeight || 50}px;
  color: ${props => props.color || hover};
  text-transform: ${props => props.textTransform || 'uppercase'};

  @media (max-width: 768px) {
    font-size: 40px !important;
    line-height: 60px !important;
  }
`;


export default Title;
