import {createGlobalStyle, css} from 'styled-components';
import 'splitting/dist/splitting.css';

export default createGlobalStyle`
  .dc-animation {
    /*Text Slide Animation*/

    &.text-slide {
      --duration: 1.6s;

      &:not(.word-no-padding-margin) {
        .word,
        .word-wrap {
          padding: 40px 15px 15px 5px;
          margin: -40px -15px -15px -5px;
        }
      }

      .word,
      .word-wrap {
        transition: transform var(--duration) cubic-bezier(0.25, 0.74, 0.22, 0.99); //standard default duration 1.6s
        transition-delay: calc(var(--line-index) * 60ms);
        overflow: hidden;
        display: inline-block;
        vertical-align: top;
        will-change: transform;
      }

      .word {
      }

      .word-wrap {
      }

      &-- {
        &up, &down {
        }

        &up {
          .word-wrap {
            transform: translateY(100%);
          }
        }

        &down {
          .word-wrap {
            transform: translateY(-100%);
          }
        }
      }
    }

    /*Text Slide Animation End*/


    /*Mask Animation Start*/

    &.masking-slide {
      --masking-color: rgb(50, 50, 50);
      --delay: 1s;
      position: relative;
      overflow: hidden;

      &:after {
        pointer-events: none;
        content: '';
        position: absolute;
        display: block;
        background-color: var(--masking-color);
        width: 100%;
        height: 100%;
        right: 0;
        top: 0;
        z-index: 30;
        transition-duration: var(--duration);
        transition-timing-function: cubic-bezier(0.25, 0.74, 0.22, 0.99); //standard default duration 1.6s
        transition-property: top, bottom, left, right;
        //transition-delay: calc(var(--line-index) * 60ms);
        transition-delay: var(--delay);
      }

      &-- {
        &up, &down {
          &:after {
            left: 0;
          }
        }

        &up {
          &:after {
            //top: calc(100% + 2px);
          }
        }

        &down {
          &:after {
            //top: calc(-100% - 2px);
          }
        }

        &left, &right {
          &:after {
          }
        }

        &left {
          &:after {
            //right: calc(-100% - 2px);
          }
        }

        &right {
          &:after {
            //right: calc(100% + 2px);
          }
        }
      }
    }

    /*Mask Animation End*/

    &.dc-animation--active {
      /*Text Slide Animation Start*/

      &.text-slide {
        &-- {
          &up, &down {
            .word-wrap {
              transform: translateY(0);
            }
          }
        }
      }

      /*Text Slide Animation End*/

      /*Mask Animation Start*/

      &.masking-slide {

        &:after {
        }

        &-- {
          &up {
            &:after {
              top: calc(-100% - 2px);
            }
          }

          &down {
            &:after {
              top: calc(100% + 2px);
            }
          }

          &left {
            &:after {
              right: calc(100% + 2px);
            }
          }

          &right {
            &:after {
              right: calc(-100% - 2px);
            }
          }
        }
      }

      /*Mask Animation End*/
    }
  }

  .dc-animation--disabled {
    /*Text Slide Animation Start*/

    &.text-slide {
      &-- {
        &up, &down {
          .word,
          .word-wrap {
            transition: none !important;
          }
        }
      }
    }

    /*Text Slide Animation End*/

    /*Mask Animation Start*/

    &.masking-slide {

      &:after {
        transition: none !important;
      }
    }

    /*Mask Animation End*/
  }
`;



