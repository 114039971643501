import React, {useEffect} from 'react';
import {connect} from "react-redux";
import ReactHtmlParser from "react-html-parser";

// components
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {ParallaxProvider} from 'react-scroll-parallax';
import InnerBanner from "../../components/InnerBanner";
import Address from "./components/Address";
import ContactForm from "./components/ContactForm";


// Dynamic Image
import BannerImage from "../../assets/images/dynamic/AboutBanner.jpg";
import BlurImg from "../../assets/images/blur.jpg";

// Action
import {fetchContactChild, fetchContactMain} from "../../redux/actions/contact";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";

const ContactPage = ({
                         getContactMain,
                         contactMain,
                         getContactChild,
                         contactChild,
                         offset
                     }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        let contactParam = {
            [ApiParams.page_type]: 'contact'
        };
        getContactMain(contactParam);
    }, []);


    useEffect(() => {
        let contactChildParam = {
            [ApiParams.page_id]: contactMain?.page_data?.id
        };
        getContactChild(contactChildParam);
    }, [contactMain]);


    let bannerImageData = contactMain?.images?.list ? contactMain?.images?.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = bannerImageData?.path ? baseUrl + bannerImageData?.path + bannerImageData?.img_name : BlurImg;


    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <ParallaxProvider>
                <InnerBanner image={bannerImagePath} subTitle={contactMain?.page_data?.title}
                             text={contactMain?.page_data?.sub_title}/>
                <Address data={contactChild} parentData={contactMain} offset={offset}/>
                <ContactForm/>
            </ParallaxProvider>
        </motion.div>
    );
};


function mapStateToProps(state) {
    return {
        contactMain: state.contactReducer.contactMain,
        contactChild: state.contactReducer.contactChild,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getContactMain: params => dispatch(fetchContactMain(params)),
        getContactChild: params => dispatch(fetchContactChild(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);