import React, {useState} from 'react';
import {hover} from '../globalStyleVars';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import arrowImg from '../assets/images/btn-arrow-green.svg';
import arrowImgHover from '../assets/images/btn-arrow-white.svg';
import VisibilitySensor from "react-visibility-sensor";


import btnArrowBlue from '../assets/images/btn-icon-blue.svg';
import btnArrowWhite from '../assets/images/btn-icon-white.svg';

const Button = ({
                    onSubmit,
                    text,
                    link,
                    img,
                    hoverImg,
                    fontSize,
                    fontWeight,
                    color,
                    letterSpacing,
                    lineHeight,
                    margin,
                    background,
                    border,
                    width,
                    disableHoverEffect = false,
                    transform
                }) => {


        let [btnArrow, setBtnArrow] = useState(btnArrowBlue)

        let handleMouseMove = (event) => {
            if (disableHoverEffect) {
                return;
            }
            let el = event.currentTarget;
            let boundingClientRect = el.getBoundingClientRect();
            let offsetX = event.clientX - boundingClientRect.x;
            let offsetY = event.clientY - boundingClientRect.y;
            let x = offsetX / el.offsetWidth;
            let y = (offsetY + (el.offsetWidth - el.offsetHeight) / 2) / el.offsetWidth;
            el.style.setProperty("--x", 100 * (x - .5) + "%");
            el.style.setProperty("--y", 100 * (y - .5) + "%");
        };
        let handleArrowImgChange = (img) => {
            setBtnArrow(img)
        };

        return (
            <>
                <VisibilitySensor getArrow={btnArrow} partialVisibility={{top: 0, bottom: 60}}>
                    {({isVisible}) =>
                        <StyledBtn
                            fontSize={fontSize}
                            fontWeight={fontWeight}
                            color={color}
                            background={background}
                            lineHeight={lineHeight}
                            letterSpacing={letterSpacing}
                            margin={margin}
                            border={border}
                            img={img}
                            width={width}
                            hoverImg={hoverImg}
                            className={`${isVisible ? 'anim-active' : ''} fade-up dc-btn ${disableHoverEffect ? 'hover-effect-disabled' : ''}`}
                            onMouseEnter={function (event) {
                                handleMouseMove(event);
                                handleArrowImgChange(btnArrowWhite);
                            }}
                            onMouseLeave={(event) => {
                                handleMouseMove(event);
                                handleArrowImgChange(btnArrowBlue);
                            }}
                        >

                            {link ?
                                <Link to={link || '/'}>
                                    <span
                                        /*onMouseOver={() => handleArrowImgChange(btnArrowWhite)}
                                        onMouseLeave={() => handleArrowImgChange(btnArrowBlue)}*/
                                        onClick={onSubmit}
                                    >
                                        <span>{text}</span>
                                        <img src={btnArrow} alt=""/>
                                    </span>
                                </Link>
                                : <a>
                                    <span
                                        onMouseOver={() => handleArrowImgChange(btnArrowWhite)}
                                        onMouseLeave={() => handleArrowImgChange(btnArrowBlue)}
                                        onClick={onSubmit}
                                    >
                                        <span>{text}</span>
                                        <img src={btnArrow} alt=""/>
                                    </span>

                                </a>


                            }

                        </StyledBtn>
                    }
                </VisibilitySensor>

            </>
        )
    }
;

const StyledBtn = styled.div`
  &.dc-btn {
    --x: 50%;
    --y: 50%;
    margin: ${props => props.margin || '0'};
    width: ${props => props.width || '194px'};
    height: 58px;
    transition: all .3s ease;
    overflow: hidden;

    &:not(.hover-effect-disabled) {
      a {
        &:before {
          content: '';
          left: 50%;
          top: 50%;
          right: auto;
          bottom: auto;
          width: 110%;
          padding-top: 110%;
          transform: scale(0) translate(-50%, -50%);
          transform-origin: var(--x) var(--y);
          min-height: 100%;
          position: absolute;
          border-radius: 500px;
          pointer-events: none;
          transition-property: transform, background-color, border-color, box-shadow, opacity;
          transition-duration: .4s, .8s, .8s, .8s, 2.5s;
          transition-timing-function: cubic-bezier(.25, .74, .22, .99);
          background-color: #333;
          opacity: 0;
        }
      }

      &:hover {
        a {
          &:before {
            opacity: 1;
            background-color: ${hover} !important;
            transform: scale(1) translate(-50%, -50%);
          }
        }
      }
    }

    &.hover-effect-disabled {
      a {

      }

      &:hover {
        a {
          background-color: ${hover} !important;
        }
      }
    }

    a {
      width: 168px;
      display: flex;
      height: 100%;
      //width: 100%;
      align-items: center;
      justify-content: center;
      font-size: ${props => props.fontSize || 14}px;
      font-weight: ${props => props.fontWeight || 400};
      margin: 0;
      line-height: ${props => props.lineHeight || 21}px;
      color: ${props => props.color || `${hover}`};
      text-transform: uppercase;
      border: ${props => props.border || `2px solid ${hover}`};
      background-color: ${props => props.background || `transparent`};
      letter-spacing: ${props => props.letterSpacing || `0`};
      position: relative;
      transition: all .3s ease;
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        transition: all .3s ease;
        background-image: url(${props => props.getArrow});
      }

      > span {
        height: 100%;
        width: 100%;
        z-index: 2;
        position: relative;
        transition: all .3s ease !important;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: ${props => props.color || `${hover}`};
        cursor: pointer;

        span {
          transform: none;
          transition: transform .4s ease;
          padding-left: 20px;
        }

        img {
          transition: transform .5s ease;
          margin-left: 10px;
          position: relative;
          object-fit: contain;
          display: inline;
          //height: 14px;
          transform: translateX(90px);
        }
      }


    }

    &:hover {


      a {
        border-color: ${hover};
        width: 194px;

        > span {
          color: #ffffff;

          span {
            transform: ${props => props.transform ? 'translateX(-30px)' : 'translateX(-40px)'};
          }

          img {
            //transform: translateX(25px);
            transform: ${props => props.transform ? 'translateX(10px)' : 'translateX(10px)'};
          }
        }

        &:after {
          height: 100%;
        }
      }

    }

  }

  @media (max-width: 767px) {
    height: 45px !important;
  }
`;


export default Button;
