import {CONTACT} from "../../constants/contcat";

export function fetchContactMain(params) {
  return {
    type: CONTACT.FETCH_CONTACT_PAGE.MAIN,
    params
  }
}

export function fetchContactChild(params) {
  return {
    type: CONTACT.FETCH_CONTACT_CHILD.MAIN,
    params
  }
}
