export const CONTACT = {
  FETCH_CONTACT_PAGE: {
    MAIN: 'FETCH_CONTACT_PAGE',
    SUCCESS: 'FETCH_CONTACT_PAGE_SUCCESS',
    FAILURE: 'FETCH_CONTACT_PAGE_FAILURE'
  },
  FETCH_CONTACT_CHILD: {
    MAIN: 'FETCH_CONTACT_CHILD',
    SUCCESS: 'FETCH_CONTACT_CHILD_SUCCESS',
    FAILURE: 'FETCH_CONTACT_CHILD_FAILURE'
  }
}
