import {GALLERY} from "../../constants/gallery";

let initialState = {
  galleryMain: {},
  galleryMainLoading: false,
  galleryMainErr: '',
  galleryChild: {},
  galleryChildLoading: false,
  galleryChildErr: ''
};

export default function galleryReducer(state = initialState, action) {
  switch (action.type) {
    case GALLERY.FETCH_GALLERY_PAGE.MAIN:
      return {
        ...state,
        galleryMain: '',
        galleryMainLoading: true,
        galleryMainErr: ''
      };

    case GALLERY.FETCH_GALLERY_PAGE.SUCCESS:
      return {
        ...state,
        galleryMain: action.result,
        galleryMainLoading: false,
        galleryMainErr: ''
      };

    case GALLERY.FETCH_GALLERY_PAGE.FAILURE:
      return {
        ...state,
        galleryMain: '',
        galleryMainLoading: false,
        galleryMainErr: 'Error from Server'
      };

    case GALLERY.FETCH_GALLERY_CHILD.MAIN:
      return {
        ...state,
        galleryChild: {},
        galleryChildLoading: true,
        galleryChildErr: ''
      };

    case  GALLERY.FETCH_GALLERY_CHILD.SUCCESS:
      return {
        ...state,
        galleryChild: action.result,
        galleryChildLoading: false,
        galleryChildErr: ''
      };

    case GALLERY.FETCH_GALLERY_CHILD.FAILURE:
      return {
        ...state,
        galleryChild: {},
        galleryChildLoading: false,
        galleryChildErr: 'Error from server'
      };

    default:
      return state
  }
}
