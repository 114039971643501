import React, {useState} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import VisibilitySensor from "react-visibility-sensor";


const LinkWithIcon = ({
                          IconWidth = 40,
                          IconHeight = 40,
                          IconBGColor = '#80C42B',
                          IconColor = '#FFFFFF',
                          LinkTo = '#',
                          LinkText = 'Learn More',
                          LinkFontSize = 16,
                          LinkLineheight = 24,
                          LinkTextColor = '#FFFFFF',
                          Hover = {
                              IconBGColor: '#FFFFFF',
                              IconColor: '#313033',
                              LinkTextColor: '#80C42B'
                          }
                      }) => {
        let rId = (Math.random() + 1).toString(36).substring(7);

        return (
            <StyledLink LinkFontSize={LinkFontSize} LinkLineheight={LinkLineheight} LinkTextColor={LinkTextColor}
                        Hover={Hover} className={'BtnTextWithIcon'}>
                {
                    LinkTo === '#' ?
                        <button>
                            <svg id={rId} data-name="Component 24 – 24" xmlns="http://www.w3.org/2000/svg"
                                 width={IconWidth}
                                 height={IconHeight} viewBox="0 0 57 57">
                                <g id="Group_109" data-name="Group 109" transform="translate(0 0)">
                                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="28.5" cy="28.5" r="28.5"
                                            transform="translate(0 0)" fill={IconBGColor}/>
                                    <circle id="Ellipse_4" data-name="Ellipse 4" cx="1.5" cy="1.5" r="1.5"
                                            transform="translate(27 27)" fill="#fff" opacity="0"/>
                                    <g id="Group_20" data-name="Group 20" transform="translate(17.102 34.095) rotate(-45)">
                                        <line id="Line_11" data-name="Line 11" x2="22.933" transform="translate(0 5.126)"
                                              fill="none" stroke={IconColor} stroke-linecap="round" stroke-width="1"/>
                                        <path id="Path_7" data-name="Path 7" d="M0,0,5.27,5.236,0,10.252"
                                              transform="translate(18.81)" fill="none" stroke={IconColor}
                                              stroke-linecap="round"
                                              stroke-width="1"/>
                                    </g>
                                </g>
                            </svg>
                            <span>{LinkText}</span>
                        </button>
                        :
                        <Link to={LinkTo}>
                            <svg id={rId} data-name="Component 24 – 24" xmlns="http://www.w3.org/2000/svg"
                                 width={IconWidth}
                                 height={IconHeight} viewBox="0 0 57 57">
                                <g id="Group_109" data-name="Group 109" transform="translate(0 0)">
                                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="28.5" cy="28.5" r="28.5"
                                            transform="translate(0 0)" fill={IconBGColor}/>
                                    <circle id="Ellipse_4" data-name="Ellipse 4" cx="1" cy="1" r="1"
                                            transform="translate(27 27)" fill="#fff" opacity="0"/>
                                    <g id="Group_20" data-name="Group 20" transform="translate(17.102 34.095) rotate(-45)">
                                        <line id="Line_11" data-name="Line 11" x2="22.933" transform="translate(0 5.126)"
                                              fill="none" stroke={IconColor} stroke-linecap="round" stroke-width="1"/>
                                        <path id="Path_7" data-name="Path 7" d="M0,0,5.27,5.236,0,10.252"
                                              transform="translate(18.81)" fill="none" stroke={IconColor}
                                              stroke-linecap="round"
                                              stroke-width="1"/>
                                    </g>
                                </g>
                            </svg>
                            <span>{LinkText}</span>
                        </Link>
                }
            </StyledLink>
        )
    }
;

const StyledLink = styled.div`
  &.BtnTextWithIcon {
    display: inline-flex;

    a, button {
      display: flex;
      flex-direction: column;
      border: none;
      background-color: transparent;

      svg {
        transition: all .3s ease;

        circle, line, path {
          transition: all .3s ease;
        }

        circle#Ellipse_4 {
          cx: 28.5;
          cy: 28.5;
          r: 1;
          transform: translate(0, 0) !important;
          fill: ${props => props.Hover.IconBGColor};
          stroke: ${props => props.Hover.IconBGColor};
          transition: all .3s ease;
        }
      }

      span {
        font-size: ${props => props.LinkFontSize}px;
        line-height: ${props => props.LinkLineheight}px;
        color: ${props => props.LinkTextColor};
        font-weight: 500;
        margin-top: 20px;
        transition: all .3s ease;
      }

      &:hover {
        svg {
          transform: rotate(45deg);

          circle#Ellipse_4 {
            r: 28.5;
            opacity: 1;
          }
          g{
            stroke: inherit;
          }
          line {
            stroke: ${props => props.LinkTextColor};
          }

          // circle {
            //   fill: ${props => props.Hover.IconBGColor};
          // }

          line, path {
            stroke: ${props => props.Hover.IconColor};
          }
        }

        span {
          color: ${props => props.Hover.LinkTextColor};
        }
      }
    }
  }
`;


export default LinkWithIcon;
