import React, {useEffect, useRef} from 'react';
import {connect} from "react-redux";

import {baseUrl} from "../../components/BaseUrl";
import {ApiParams} from "../../constants/apiParams";
import {
    fetchHomeBannerSlider,
    fetchHomeOverview,
    fetchHomeService,
    fetchHomeServiceChild,
    fetchHomeTestimonial,
    fetchHomeVideo,
    fetchHomeWhyChoose,
    fetchProductCategory
} from "../../redux/actions/home";

// components
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {ParallaxProvider} from 'react-scroll-parallax';
import BannerSlider from "./components/BannerSlider";
import AboutUs from "./components/AboutUs";
import WhyChoose from "./components/WhyChoose";
import Products from "./components/Products";
import Video from "./components/Video";
import Testimonial from "./components/Testimonial";

const HomePage = ({
                      getHomeBanner,
                      homeBannerData,
                      getHomeService,
                      homeServiceData,
                      getHomeServiceChild,
                      homeServiceChildData,
                      getHomeOverview,
                      homeOverviewData,
                      getHomeWhyChoose,
                      homeWhyChooseData,
                      getProductCategory,
                      productCategoryData,
                      getHomeVideo,
                      homeVideoData,
                      getHomeTestimonial,
                      homeTestimonialData,
                      offset
                  }) => {

    const scrollRef = useRef(null)

    // const executeScroll = () => scrollRef.current.scrollIntoView();
    const executeScroll = () => window.scrollTo({
        behavior: "smooth",
        top: scrollRef.current.offsetTop,
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        let bannerParam = {
            [ApiParams.page_type]: 'home-banner'
        };
        let serviceParam = {
            [ApiParams.page_type]: 'services'
        };
        let overviewParam = {
            [ApiParams.page_type]: 'home-overview'
        };
        let whyChooseParam = {
            [ApiParams.page_type]: 'home-why-choose'
        };
        let homeVideoParam = {
            [ApiParams.page_type]: 'video'
        };
        let homeTestimonialParam = {
            [ApiParams.page_type]: 'testimonial'
        };
        if (homeBannerData) {
            getHomeBanner(bannerParam);
        }
        if (homeServiceData) {
            getHomeService(serviceParam);
        }
        if (homeOverviewData) {
            getHomeOverview(overviewParam);
        }
        if (homeWhyChooseData) {
            getHomeWhyChoose(whyChooseParam);
        }
        if (productCategoryData) {
            getProductCategory();
        }
        if (homeVideoData) {
            getHomeVideo(homeVideoParam);
        }
        if (homeTestimonialData) {
            getHomeTestimonial(homeTestimonialParam);
        }

    }, []);
    useEffect(() => {
        let serviceChildParam = {
            [ApiParams.page_id]: homeServiceData?.page_data?.id
        };
        getHomeServiceChild(serviceChildParam);
    }, [homeServiceData]);

    // console.log(homeServiceData, 'home index');

    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <ParallaxProvider>
                <BannerSlider data={homeBannerData} serviceData={homeServiceData} serviceChildData={homeServiceChildData} offset={offset} executeScroll={executeScroll} />
                <AboutUs data={homeOverviewData}/>
                <WhyChoose data={homeWhyChooseData} offset={offset}/>
                <Products data={productCategoryData} offset={offset} scrollRef={scrollRef}/>
                <Video data={homeVideoData} offset={offset}/>
                <Testimonial data={homeTestimonialData} offset={offset}/>
            </ParallaxProvider>
        </motion.div>
    );
};


function mapStateToProps(state) {
    return {
        homeBannerData: state.homeReducer.homeBannerData,
        homeServiceData: state.homeReducer.homeServiceData,
        homeServiceChildData: state.homeReducer.homeServiceChildData,
        homeOverviewData: state.homeReducer.homeOverviewData,
        homeWhyChooseData: state.homeReducer.homeWhyChooseData,
        productCategoryData: state.homeReducer.productCategoryData,
        homeVideoData: state.homeReducer.homeVideoData,
        homeTestimonialData: state.homeReducer.homeTestimonialData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getHomeBanner: params => dispatch(fetchHomeBannerSlider(params)),
        getHomeService: params => dispatch(fetchHomeService(params)),
        getHomeServiceChild: params => dispatch(fetchHomeServiceChild(params)),
        getHomeOverview: params => dispatch(fetchHomeOverview(params)),
        getHomeWhyChoose: params => dispatch(fetchHomeWhyChoose(params)),
        getProductCategory: () => dispatch(fetchProductCategory()),
        getHomeVideo: params => dispatch(fetchHomeVideo(params)),
        getHomeTestimonial: params => dispatch(fetchHomeTestimonial(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);