export const MEDIA = {
  FETCH_MEDIA_PAGE: {
    MAIN: 'FETCH_MEDIA_PAGE',
    SUCCESS: 'FETCH_MEDIA_PAGE_SUCCESS',
    FAILURE: 'FETCH_MEDIA_PAGE_FAILURE'
  },
  FETCH_MEDIA_CHILD: {
    MAIN: 'FETCH_MEDIA_CHILD',
    SUCCESS: 'FETCH_MEDIA_CHILD_SUCCESS',
    FAILURE: 'FETCH_MEDIA_CHILD_FAILURE'
  },
  FETCH_MEDIA_CHILD_OF_CHILD: {
    MAIN: 'FETCH_MEDIA_CHILD_OF_CHILD',
    SUCCESS: 'FETCH_MEDIA_CHILD_OF_CHILD_SUCCESS',
    FAILURE: 'FETCH_MEDIA_CHILD_OF_CHILD_FAILURE'
  },
  FETCH_MEDIA_DETAIL: {
    MAIN: 'FETCH_MEDIA_DETAIL',
    SUCCESS: 'FETCH_MEDIA_DETAIL_SUCCESS',
    FAILURE: 'FETCH_MEDIA_DETAIL_FAILURE'
  }
}
