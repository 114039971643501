import React, {useEffect} from 'react';
import {connect} from "react-redux";
import ReactHtmlParser from "react-html-parser";

// components
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {ParallaxProvider} from 'react-scroll-parallax';
import InnerBanner from "../../components/InnerBanner";
import Strength from "./components/Strength";
import Overview from "./components/Overview";
import MissionVision from "./components/MissionVision";
import CoreValues from "./components/CoreValues";
import CEO from "./components/CEO";
import Team from "./components/Team";

// Dynamic Image
import BannerImage from "../../assets/images/dynamic/AboutBanner.jpg";

// Action
import {fetchAboutChild, fetchAboutMain} from "../../redux/actions/about";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import BlurImg from "../../assets/images/blur.jpg";

const AboutPage = ({
                       getAboutMain,
                       aboutMain,
                       getAboutChild,
                       aboutChild,
                       offset
                   }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        let aboutParam = {
            [ApiParams.page_type]: 'about-us'
        };
        if (!aboutMain) {
            getAboutMain(aboutParam);
        }
    }, []);

    useEffect(() => {
        let aboutChildParam = {
            [ApiParams.page_id]: aboutMain?.page_data?.id
        };
        getAboutChild(aboutChildParam);
    }, [aboutMain]);

    // console.log(aboutChild, 'about index');

    let bannerImageData = aboutMain?.images?.list ? aboutMain?.images?.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = bannerImageData?.path ? baseUrl + bannerImageData?.path + bannerImageData?.img_name : BlurImg;

    // filter Pages
    let companyStrength = aboutChild && aboutChild.find(f => f.page_data && f.page_data.types === '8');
    let overView = aboutChild && aboutChild.find(f => f.page_data && f.page_data.types === '9');
    let missionVision = aboutChild && aboutChild.find(f => f.page_data && f.page_data.types === '10');
    let coreValues = aboutChild && aboutChild.find(f => f.page_data && f.page_data.types === '11');
    let managingDirector = aboutChild && aboutChild.find(f => f.page_data && f.page_data.types === '12');
    let team = aboutChild && aboutChild.find(f => f.page_data && f.page_data.types === '13');

    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <ParallaxProvider>
                <InnerBanner image={bannerImagePath} subTitle={aboutMain?.page_data?.title}
                             text={aboutMain?.page_data?.sub_title}/>
                <Strength data={companyStrength}/>
                <Overview data={overView}/>
                <MissionVision data={missionVision} offset={offset}/>
                <CoreValues data={coreValues}/>
                <CEO data={managingDirector} offset={offset}/>
                <Team data={team} offset={offset}/>
            </ParallaxProvider>
        </motion.div>
    );
};


function mapStateToProps(state) {
    return {
        aboutMain: state.aboutReducer.aboutMain,
        aboutChild: state.aboutReducer.aboutChild,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAboutMain: params => dispatch(fetchAboutMain(params)),
        getAboutChild: params => dispatch(fetchAboutChild(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutPage);