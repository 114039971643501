import {createGlobalStyle, css} from 'styled-components';
import {hover, primaryText, whiteText} from './globalStyleVars';
import modalClose from './assets/images/close.svg';
import React from 'react'

import PrimaryFontLight2 from './assets/fonts/Gilroy-Light.woff2';
import PrimaryFontLight from './assets/fonts/Gilroy-Light.woff';
import PrimaryFontRegular2 from './assets/fonts/Gilroy-Regular.woff2';
import PrimaryFontRegular from './assets/fonts/Gilroy-Regular.woff';
import PrimaryFontMedium2 from './assets/fonts/Gilroy-Medium.woff2';
import PrimaryFontMedium from './assets/fonts/Gilroy-Medium.woff';
import PrimaryFontSemiBold2 from './assets/fonts/Gilroy-SemiBold.woff2';
import PrimaryFontSemiBold from './assets/fonts/Gilroy-SemiBold.woff';
import PrimaryFontBold2 from './assets/fonts/Gilroy-Bold.woff2';
import PrimaryFontBold from './assets/fonts/Gilroy-Bold.woff';


function createCSS() {
    let styles = '';

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `
    }

    for (let a = 2; a < 200; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    for (let a = 2; a < 200; a += 1) {
        styles += `
        .slick-active .fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    return css`${styles}`;
}


export default createGlobalStyle`
  ${createCSS()}
  #root {
    overflow: hidden !important;
  }

  body {
    &.open {
      overflow: hidden !important;
    }
  }

  @font-face {
    font-family: 'Gilroy';
    font-display: swap; /* Read next point */
    unicode-range: U+000-5FF; /* Download only latin glyphs */
    src: url(${PrimaryFontLight2}) format('woff2'),
    url(${PrimaryFontLight}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilroy';
    font-display: swap; /* Read next point */
    unicode-range: U+000-5FF; /* Download only latin glyphs */
    src: url(${PrimaryFontRegular2}) format('woff2'),
    url(${PrimaryFontRegular}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilroy';
    font-display: swap; /* Read next point */
    unicode-range: U+000-5FF; /* Download only latin glyphs */
    src: url(${PrimaryFontMedium2}) format('woff2'),
    url(${PrimaryFontMedium}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilroy';
    font-display: swap; /* Read next point */
    unicode-range: U+000-5FF; /* Download only latin glyphs */
    src: url(${PrimaryFontSemiBold2}) format('woff2'),
    url(${PrimaryFontSemiBold}) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilroy';
    font-display: swap; /* Read next point */
    unicode-range: U+000-5FF; /* Download only latin glyphs */
    src: url(${PrimaryFontBold2}) format('woff2'),
    url(${PrimaryFontBold}) format('woff');
    font-weight: bold;
    font-style: normal;
  }


  body {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    color: #1B1D1D;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

  }

  a {
    transition: color .3s ease;

    &:hover {
      color: ${hover} !important;
    }
  }

  ::selection {
    background: ${hover};
    color: #FFF;
  }

  p, a, h1, h2, h4, h3 {
    color: #1B1D1D;
  }

  ul {
    margin: 0;
    padding: 0
  }

  li {
    list-style: none;
  }

  a:hover, a:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }

  .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    box-shadow: none;
  }

  table {
    width: 100%;
  }

  .form-control {
    box-shadow: none;
    outline: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .p-0 {
    padding: 0 !important;
  }

  .pt-150 {
    padding-top: 150px;
  }

  .pb-150 {
    padding-bottom: 150px;
  }

  .pt-100 {
    padding-top: 100px;
  }

  .pb-100 {
    padding-bottom: 100px;
  }

  .pt-120 {
    padding-top: 120px;
  }

  .pb-20 {
    padding-bottom: 20px;
  }

  .pt-20 {
    padding-top: 20px;
  }

  .pb-120 {
    padding-bottom: 120px;
  }

  .pl-30 {
    padding-left: 30px;
  }

  /*Margin*/

  .mt-150 {
    margin-top: 150px;
  }

  .mb-150 {
    margin-bottom: 150px;
  }

  .mt-120 {
    margin-top: 120px;
  }

  .mb-120 {
    margin-bottom: 120px;
  }

  .ml-30 {
    margin-left: 30px;
  }

  .swiper-button-disabled {
    opacity: 0 !important;
  }

  .gmnoprint, .gm-control-active {
    opacity: 0;
  }

  .swiper-button-next:after {
    display: none;
  }

  .swiper-button-prev:after {
    display: none;
  }

  @media (min-width: 1440px) {
    .container {
      //min-width: 1366px;
      min-width: 80%;
      margin: auto;
    }
  }

  @media (max-width: 991px) {

    .container {
      min-width: 95%;
    }
  }

  @media (max-width: 768px) {
    .container, .container-md, .container-sm {
      max-width: 90%;
      overflow: hidden;
    }

    .mt-150 {
      margin-top: 60px;
    }

    .mb-150 {
      margin-bottom: 60px;
    }

    .pt-120, .pt-150, .pt-100 {
      padding-top: 60px;
    }

    .pb-120, .pb-150, .pb-100 {
      padding-bottom: 60px;
    }


  }

  @media (max-width: 767px) {
    .container, .container-sm {
      max-width: 100%;
    }

  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  #root {
    overflow-x: hidden;
  }

  .modal-backdrop {
    z-index: 9999;
  }

  .modal-open {
    .modal {
      z-index: 9999;
      background-color: #fff;
    }
  }

  // Custom Modal style
  .customModal {
    .modal-header {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;

      button {
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: transparent;
        border: none;
        z-index: 2;
                
     

             svg {
              rect#Rectangle_123 {
                r: 20;
                fill: rgb(255,255,255);
                stroke: rgb(255,255,255);
                transition: all .3s ease;
                width: 40px;
                height: 40px;
                rx: 20;
                transform: translate(20px, 20px) scale(0);
              }
            }
            
            
            &:hover{
                  svg {
                rect#Rectangle_123 {
                  rx: 20;
                  transform:translate(0, 0) scale(1);
               
                      opacity: 1;
                }
                g{
                stroke: #313033;
                }
                line{
                  stroke: #313033;
                }
                
                
}
            }
      }
    }

    .modal-dialog {
      margin-top: 100px;
      margin-bottom: 100px;
      min-height: calc(100% - 200px);
      background-color: ${primaryText};
      //transform: translateY(50%) !important;
    }

    .modal-content {
      box-shadow: none;
      border: none;
      background-color: transparent;
      min-height: calc(100vh - 200px);

      .modal-body {
        //padding: 85px 100px;
        display: flex;

        .col {
          padding: 0;
        }

        .modalContentWrap {
          padding: 100px 85px;
          .title{
            font-size: 32px;
            line-height: 40px;
          }
          p{
            font-size: 18px;
            line-height: 27px;
          }
          table{
            p{
              margin-bottom: 10px;
            }
            tbody{
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              tr{
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                td{
                  padding: 10px;
                  &:first-child{
                    flex: 0 0 40%;
                  }
                  &:last-child{
                    flex: 0 0 60%;
                  }
                }
              }
            }
          }

        }

        .popupImageWrap {
          height: 100%;
          img {
            //width: 100%;
          }
  .swiper-wrapper{
    background: radial-gradient(circle,rgba(241,241,241,1) 0%,rgba(225,225,225,1) 100%);
    img{
        padding: 70px;
    }
  }
          .swiper-container {
            height: 100%;
            .swiper-pagination {
              .swiper-pagination-bullet {
                background-color: #80c42b;
                border: 3px solid #ffffff;
                width: 15px;
                height: 15px;
                opacity: .4;

                &.swiper-pagination-bullet-active {
                  opacity: 1;
                }
              }
            }
          }
        }

        p {
          font-size: 16px;
          line-height: 24px;
          font-weight: 300;
          color: ${whiteText};
          margin-bottom: 28px;
        }

        ul {
          li {
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            color: #fff;
            padding-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            padding-left: 30px;

            &::before {
              position: relative;
              content: "\\2022";
              font-size: 25px;
              line-height: 25px;
              color: ${hover};
              font-weight: bold;
              display: inline-block;
              left: -30px;
              top: 5px;
            }
          }
        }
      }
    }
    &.imageModal{
      .modal-content {
        .modal-body {
          .modalContentWrap{
            img{
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .modal-dialog {
      width: 100%;
      margin: 0;
    }

    .modal-content {
      padding: 50px 15px;

      .close {
        height: 40px;
        width: 40px;
        right: 16px;
        background-size: 20px;
      }
    }

    .modal-title h2 {
      font-size: 25px !important;
      line-height: 30px;
    }
    .customModal {
      .modal-dialog{
        margin-top: 0;
        margin-bottom: 0;
        width: 100%;
        min-height: 100vh;
      }
      .modal-header{
        button{
          padding: 0;
          right: 15px;
          top: 15px;
        }
      }
      .modal-content {
        min-height: 100vh;
        .modal-body {
          .modalContentWrap{
            padding: 0;
            .title{
              font-size: 24px !important;
              line-height: 27px !important;
            }
            p{
              font-size: 14px;
              line-height: 21px;
            }
          }
        }
      }
    }
  }

  // Custom Modal style end

  .modal-header {
    padding: 0;
    padding-bottom: 27px;
    margin-bottom: 40px;
  }

  .modal-dialog {
    width: 80%;
    max-width: unset;
  }

  .modal-content {
    border-radius: 0;

    .close {
      height: 50px;
      width: 50px;
      background-image: url(${modalClose});
      background-color: ${hover};
      background-size: 25px;
      background-position: center;
      padding: 0;
      background-repeat: no-repeat;
      position: absolute;
      right: -34px;
      opacity: 1;
      top: 16px;
      transition: all .3s ease;

      &:hover {
        background-color: ${hover};
      }

      span {
        display: none;
      }

    }
  }

  .modal-body {
    padding: 0;

    tbody {
      tr {
        &:nth-of-type(1) td {
          border-top: 0;
        }

        td {
          padding: 20px;
          font-size: 14px;
          font-weight: 300;
          border-color: #DFE6E5;

          &:nth-of-type(1) {
            padding-left: 0;
            font-size: 16px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.51);
          }

          p {
            font-size: 14px;
            font-weight: 300;
            margin-bottom: 10px;
          }
        }

        &:nth-last-of-type(1) {
          td p:last-child {
            margin-bottom: 0;
          }

          border-bottom: 1px solid #DFE6E5;
        }
      }
    }
  }

  .modal-title {
    h2 {
      font-size: 42px !important;
      font-weight: bold;
      line-height: 42px;
      margin-bottom: 0;
    }
  }
  
  
  // video Popup style
  // React Video Modal style start
  @keyframes modal-video {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes modal-video-inner {
    from {
      transform: translate(0, 100px);
    }

    to {
      transform: translate(0, 0);
    }
  }

  .modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: .3s;
    animation-name: modal-video;
    -webkit-transition: opacity .3s ease-out;
    -moz-transition: opacity .3s ease-out;
    -ms-transition: opacity .3s ease-out;
    -o-transition: opacity .3s ease-out;
    transition: opacity .3s ease-out;
  }

  .modal-video-effect-exit {
    opacity: 0;

    & .modal-video-movie-wrap {
      -webkit-transform: translate(0, 100px);
      -moz-transform: translate(0, 100px);
      -ms-transform: translate(0, 100px);
      -o-transform: translate(0, 100px);
      transform: translate(0, 100px);
    }
  }

  .modal-video-body {
    max-width: 960px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  }

  .modal-video-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    @media (orientation: landscape) {
      padding: 10px 60px;
      box-sizing: border-box;
    }
  }

  .modal-video-movie-wrap {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    background-color: #333;
    animation-timing-function: ease-out;
    animation-duration: .3s;
    animation-name: modal-video-inner;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: -webkit-transform .3s ease-out;
    -moz-transition: -moz-transform .3s ease-out;
    -ms-transition: -ms-transform .3s ease-out;
    -o-transition: -o-transform .3s ease-out;
    transition: transform .3s ease-out;

    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .modal-video-close-btn {
    position: absolute;
    z-index: 2;
    top: -45px;
    right: 0px;
    display: inline-block;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border: none;
    background: transparent;

    @media (orientation: landscape) {
      top: 0;
      right: -45px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: #fff;
      border-radius: 5px;
      margin-top: -6px;
    }
  }

  // React Video Modal style end
  // video Popup style


  @media (min-width: 768px) {
    .modal-content .table-responsive {
      overflow: visible;
    }
  }

  @media (max-width: 767px) {
    .container {
      overflow: hidden;
    }

    section {
      overflow: hidden;
    }

    
    
    
    .customModal .modal-content {
      .modal-body {

        flex-direction: column;
        justify-content: flex-start;
        .popupImageWrap {
          .swiper-wrapper img {
            padding: 30px;
          }
        }
      }
    }
    
  
  }


  .css-yk16xz-control, .css-1pahdxg-control {
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color: #D9D9D9 !important;
    box-shadow: none !important;

    .css-1wa3eu0-placeholder {
      font-weight: 300;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.5);
      outline: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  .fade-up {
    transition: opacity .2s ease, transform .2s ease;
    opacity: 0;
    transform: translateY(40px);
    transition-delay: 0s;
  }

  .fade-right {
    span {
      opacity: 0;
    }

    &.anim-active {
      span {
        opacity: 1;
        transition: opacity .45s ease;
      }
    }
  }

  .anim-active {
    opacity: 1;
    transform: translateY(0px);
    transition-delay: .2s;
    transition: opacity .6s ease, transform .6s ease;
  }

  .slick-current {
    .fade-right span {
      opacity: 1;
      transition: transform .4s cubic-bezier(0.45, 0, 0.55, 1), opacity .7s cubic-bezier(0.45, 0, 0.55, 1);
    }
  }


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;
  }


  .gm-style-iw, .gmnoprint {
    height: auto !important;

    div {
      height: auto !important
    }

  }

  .map-info__img {
    height: auto;

    img {
      height: 100px;
      margin-bottom: 12px;
      object-fit: cover;
    }
  }

  .map-info__content {
    h4 {
      font-size: 20px;
    }

    p {
      margin-bottom: 5px;
    }
  }

  .Overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    //background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: none;

    &.show {
      display: block;
    }
  }

  .form-control.has-error {
    border-color: #dc004e !important;
  }

  .content-loader {
    position: absolute;
    height: 70%;
    width: 70%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .loading-before-submit {
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }

  .loading-before-content {
    position: fixed;
    height: 200px;
    width: 200px;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    //background: rgba(0, 0, 0, 0.65);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    img {
      height: 40px;
    }

    @keyframes ldio-2p01d405ya4 {
      0% {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      100% {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }

    .ldio-2p01d405ya4 div {
      position: absolute;
      width: 40px;
      height: 40px;
      border: 5px solid #353e97;
      border-top-color: #ffffff;
      border-radius: 50%;
    }

    .ldio-2p01d405ya4 div {
      animation: ldio-2p01d405ya4 1s linear infinite;
      top: 100px;
      left: 100px
    }

    .loadingio-spinner-rolling-qx6ynrq7t2 {
      width: 200px;
      height: 200px;
      display: inline-block;
      overflow: hidden;
      background: none;
    }

    .ldio-2p01d405ya4 {
      width: 100%;
      height: 100%;
      position: relative;
      transform: translateZ(0) scale(1);
      backface-visibility: hidden;
      transform-origin: 0 0; /* see note above */
    }

    .ldio-2p01d405ya4 div {
      box-sizing: content-box;
    }


  }

  .parallax-outer {
    margin-bottom: 0;
  }

  .modal-video {
    outline: none;
  }

  .top-0 {
    top: 0;
  }

  .left-0 {
    left: 0;
  }
`;



