import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import ReactHtmlParser from "react-html-parser";

// components
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {ParallaxProvider} from 'react-scroll-parallax';
import InnerBanner from "../../components/InnerBanner";
import Listing from "./components/Listing";
import VideoGallery from "./components/VideoGallery";
import {useParams} from "react-router-dom";
import Error from '../../container/error';


import BlurImg from "../../assets/images/blur.jpg";
// Dynamic Image
import BannerImage from "../../assets/images/dynamic/AboutBanner.jpg";

// Action
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {fetchGalleryMain, fetchGalleryChild} from "../../redux/actions/gallery";
import galleryReducer from "../../redux/reducers/gallery";


const GalleryPage = ({
                         getGalleryMain,
                         getGalleryChild,
                         galleryMain,
                         galleryChild,
                         offset
                     }) => {
    const {gallerySlug} = useParams();

    // const [childId, setChildId] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);

        let galleryParam = {
            [ApiParams.page_type]: 'gallery'
        };
        getGalleryMain(galleryParam);

    }, []);


    useEffect(() => {
        let galleryChildParam = {
            [ApiParams.page_slug]: gallerySlug
        };
        if (galleryChild) {
            getGalleryChild(galleryChildParam);
        }

    }, [gallerySlug]);


    let bannerImageData = galleryMain?.images?.list ? galleryMain?.images?.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = bannerImageData?.path ? baseUrl + bannerImageData?.path + bannerImageData?.img_name : BlurImg;


    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <ParallaxProvider>
                <InnerBanner image={bannerImagePath} subTitle={galleryMain?.page_data?.title}
                             text={galleryMain?.page_data?.sub_title}/>
                {
                    gallerySlug === "photo" ?
                        <Listing data={galleryChild} parentData={galleryMain} gallerySlug={gallerySlug}/> : gallerySlug === 'video' ?
                            <VideoGallery data={galleryChild} parentData={galleryMain} gallerySlug={gallerySlug}/>
                            : <Error/>
                }
            </ParallaxProvider>
        </motion.div>
    );
};


function mapStateToProps(state) {
    return {
        galleryMain: state.galleryReducer.galleryMain,
        galleryChild: state.galleryReducer.galleryChild,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getGalleryMain: params => dispatch(fetchGalleryMain(params)),
        getGalleryChild: params => dispatch(fetchGalleryChild(params))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GalleryPage);