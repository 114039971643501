import {HOME} from "../../constants/home";

export function fetchHomeBannerSlider(params) {
  return {
    type: HOME.FETCH_HOME_BANNER_SLIDER.MAIN,
    params
  }
}

export function fetchHomeService(params) {
  return {
    type: HOME.FETCH_HOME_SERVICE.MAIN,
    params
  }
}

export function fetchHomeServiceChild(params) {
  return {
    type: HOME.FETCH_HOME_SERVICE_CHILD.MAIN,
    params
  }
}

export function fetchHomeOverview(params) {
  return {
    type: HOME.FETCH_HOME_OVERVIEW.MAIN,
    params
  }
}

export function fetchHomeWhyChoose(params) {
  return {
    type: HOME.FETCH_HOME_WHY_CHOOSE.MAIN,
    params
  }
}

export function fetchProductCategory() {
  return {
    type: HOME.FETCH_PRODUCT_CATEGORY.MAIN,
  }
}

export function fetchHomeVideo(params) {
  return {
    type: HOME.FETCH_HOME_VIDEO.MAIN,
    params
  }
}

export function fetchHomeTestimonial(params) {
  return {
    type: HOME.FETCH_HOME_TESTIMONIAL.MAIN,
    params
  }
}