import {HOME} from '../../constants/home'

const initialState = {
  homeBannerData: {},
  homeBannerLoading: false,
  homeBannerErr: '',
  homeServiceData: {},
  homeServiceLoading: false,
  homeServiceErr: '',
  homeServiceChildData: {},
  homeServiceChildLoading: false,
  homeServiceChildErr: '',
  homeOverviewData: {},
  homeOverviewLoading: false,
  homeOverviewErr: '',
  homeWhyChooseData: {},
  homeWhyChooseLoading: false,
  homeWhyChooseErr: '',
  productCategoryData: {},
  productCategoryLoading: false,
  productCategoryErr: '',
  homeVideoData: {},
  homeVideoLoading: false,
  homeVideoErr: '',
  homeTestimonialData: {},
  homeTestimonialLoading: false,
  homeTestimonialErr: '',
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    // LANDING BANNER
    case HOME.FETCH_HOME_BANNER_SLIDER.MAIN:
      return {
        ...state,
        homeBannerData: {},
        homeBannerLoading: true,
        homeBannerErr: '',
        message: ''
      };
    case HOME.FETCH_HOME_BANNER_SLIDER.SUCCESS:
      return {
        ...state,
        homeBannerData: action.result,
        homeBannerLoading: false,
        homeBannerErr: '',
        message: action.message
      };
    case HOME.FETCH_HOME_BANNER_SLIDER.FAILURE:
      return {
        ...state,
        homeBannerData: {},
        homeBannerLoading: false,
        homeBannerErr: 'Error in banner slider',
        message: 'failure'
      };

    // HOME SERVICE
    case HOME.FETCH_HOME_SERVICE.MAIN:
      return {
        ...state,
        homeServiceData: {},
        homeServiceLoading: true,
        homeServiceErr: '',
        message: ''
      };
    case HOME.FETCH_HOME_SERVICE.SUCCESS:
      return {
        ...state,
        homeServiceData: action.result,
        homeServiceLoading: false,
        homeServiceErr: '',
        message: action.message
      };
    case HOME.FETCH_HOME_SERVICE.FAILURE:
      return {
        ...state,
        homeServiceData: {},
        homeServiceLoading: false,
        homeServiceErr: 'Error in service',
        message: 'failure'
      };

    // HOME SERVICE CHILD
    case HOME.FETCH_HOME_SERVICE_CHILD.MAIN:
      return {
        ...state,
        homeServiceChildData: {},
        homeServiceChildLoading: true,
        homeServiceChildErr: '',
        message: ''
      };
    case HOME.FETCH_HOME_SERVICE_CHILD.SUCCESS:
      return {
        ...state,
        homeServiceChildData: action.result,
        homeServiceChildLoading: false,
        homeServiceChildErr: '',
        message: action.message
      };
    case HOME.FETCH_HOME_SERVICE_CHILD.FAILURE:
      return {
        ...state,
        homeServiceChildData: {},
        homeServiceChildLoading: false,
        homeServiceChildErr: 'Error in service',
        message: 'failure'
      };

    // HOME OVERVIEW
    case HOME.FETCH_HOME_OVERVIEW.MAIN:
      return {
        ...state,
        homeOverviewData: {},
        homeOverviewLoading: true,
        homeOverviewErr: '',
        message: ''
      };
    case HOME.FETCH_HOME_OVERVIEW.SUCCESS:
      return {
        ...state,
        homeOverviewData: action.result,
        homeOverviewLoading: false,
        homeOverviewErr: '',
        message: action.message
      };
    case HOME.FETCH_HOME_OVERVIEW.FAILURE:
      return {
        ...state,
        homeOverviewData: {},
        homeOverviewLoading: false,
        homeOverviewErr: 'Error in Overview',
        message: 'failure'
      };

    // HOME WHY CHOOSE
    case HOME.FETCH_HOME_WHY_CHOOSE.MAIN:
      return {
        ...state,
        homeWhyChooseData: {},
        homeWhyChooseLoading: true,
        homeWhyChooseErr: '',
        message: ''
      };
    case HOME.FETCH_HOME_WHY_CHOOSE.SUCCESS:
      return {
        ...state,
        homeWhyChooseData: action.result,
        homeWhyChooseLoading: false,
        homeWhyChooseErr: '',
        message: action.message
      };
    case HOME.FETCH_HOME_WHY_CHOOSE.FAILURE:
      return {
        ...state,
        homeWhyChooseData: {},
        homeWhyChooseLoading: false,
        homeWhyChooseErr: 'Error in Why Choose',
        message: 'failure'
      };

    // Product Category
    case HOME.FETCH_PRODUCT_CATEGORY.MAIN:
      return {
        ...state,
        productCategoryData: {},
        productCategoryLoading: true,
        productCategoryErr: '',
        message: ''
      };
    case HOME.FETCH_PRODUCT_CATEGORY.SUCCESS:
      return {
        ...state,
        productCategoryData: action.result,
        productCategoryLoading: false,
        productCategoryErr: '',
        message: action.message
      };
    case HOME.FETCH_PRODUCT_CATEGORY.FAILURE:
      return {
        ...state,
        productCategoryData: {},
        productCategoryLoading: false,
        productCategoryErr: 'Error in Product Category',
        message: 'failure'
      };

    // Home Video
    case HOME.FETCH_HOME_VIDEO.MAIN:
      return {
        ...state,
        homeVideoData: {},
        homeVideoLoading: true,
        homeVideoErr: '',
        message: ''
      };
    case HOME.FETCH_HOME_VIDEO.SUCCESS:
      return {
        ...state,
        homeVideoData: action.result,
        homeVideoLoading: false,
        homeVideoErr: '',
        message: action.message
      };
    case HOME.FETCH_HOME_VIDEO.FAILURE:
      return {
        ...state,
        homeVideoData: {},
        homeVideoLoading: false,
        homeVideoErr: 'Error in home video',
        message: 'failure'
      };

    // Home Testimonial
    case HOME.FETCH_HOME_TESTIMONIAL.MAIN:
      return {
        ...state,
        homeTestimonialData: {},
        homeTestimonialLoading: true,
        homeTestimonialErr: '',
        message: ''
      };
    case HOME.FETCH_HOME_TESTIMONIAL.SUCCESS:
      return {
        ...state,
        homeTestimonialData: action.result,
        homeTestimonialLoading: false,
        homeTestimonialErr: '',
        message: action.message
      };
    case HOME.FETCH_HOME_TESTIMONIAL.FAILURE:
      return {
        ...state,
        homeTestimonialData: {},
        homeTestimonialLoading: false,
        homeTestimonialErr: 'Error in home testimonial',
        message: 'failure'
      };
    default:
      return state
  }
}
