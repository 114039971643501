import React, {useState} from 'react';
import styled from 'styled-components';
import {hover, primaryText, whiteText} from '../../../globalStyleVars';
import {Col, Container, Row} from "react-bootstrap";
import Title from "../../../components/Title";
import {baseUrl} from "../../../components/BaseUrl";
import ReactHtmlParser from "react-html-parser";
import {Parallax, useController} from "react-scroll-parallax";

// Image
import BlurImg from "../../../assets/images/blur.jpg";

const Address = ({data, parentData, offset}) => {


        return (
            <StyledAddress offset={offset}>
                <Container fluid>
                    {
                        data && data.length ?
                            <Row>


                                <Col md={6} className="footer-left" style={{paddingLeft: offset}}>
                                    {
                                        data.map((address, aKey) => {
                                            return (
                                                <Parallax y={[0, 0]} tagOuter="figure">
                                                    <div key={aKey} className="footer-left__single">
                                                        <div className="footer-left__logo">
                                                            <Title fontSize={'50'} lineHeight={'60'} fontWeight={300}
                                                                   color={hover}
                                                                   textTransform={'uppercase'}
                                                                   text={address?.page_data?.title} margin={'0 0 40px 0'}/>
                                                        </div>
                                                        {
                                                            address?.posts?.list ?
                                                                <div className="footer-left__locations">
                                                                    <ul>
                                                                        {
                                                                            address?.posts.list.map((addressItem, aiKey) => {
                                                                                let thumbImageData = addressItem?.images ? addressItem?.images.find(e => e.is_thumb === 'on') : '';
                                                                                let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;
                                                                                return (
                                                                                    <li key={aiKey}>
                                                                                        <img src={thumbImagePath} alt=""/>
                                                                                        {ReactHtmlParser(addressItem?.data?.description)}
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                                : ''
                                                        }

                                                    </div>
                                                </Parallax>
                                            )
                                        })
                                    }

                                </Col>

                                <Col md={6} className="footer-right">
                                    <Parallax y={[0, 0]} tagOuter="figure">
                                        <iframe
                                            src={`https://maps.google.com/maps?q=${parentData?.page_data?.short_desc}&z=16&output=embed`}
                                            frameBorder="0"
                                        />
                                    </Parallax>
                                </Col>
                            </Row>
                            : ''
                    }


                </Container>

            </StyledAddress>
        )
    }
;

const StyledAddress = styled.section`
  position: relative;
  background-color: #F1F1F1;

  .footer-left {
    background-color: ${primaryText};
    padding-top: 180px;
    padding-bottom: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .parallax-outer{
      &:last-child{
        .footer-left__logo{
          h2{
            color: ${whiteText};
          }
        }
      }
    }
    &__single {
      margin-bottom: 60px;
    }

    &__logo {
      //margin-bottom: 80px;
    }

    &__locations {
      ul {
        li {
          display: flex;
          margin-bottom: 20px;

          img {
            //margin-top: 5px;
            margin-right: 15px;
          }

          p, a {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: ${whiteText};
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .footer-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    .parallax-outer{
      height: 100%;
      .parallax-inner{
        height: 100%;
      }
    }

    iframe {
      height: 100%;
      width: 100%;
    }

  }

  @media (max-width: 1680px) {
    .footer-left {
      padding-top: 150px;
      padding-bottom: 120px;
      
      &__single {
        margin-bottom: 60px;
      }

      &__logo {
        h2{
          font-size: 40px !important;
          line-height: 50px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    
  }

  @media (max-width: 768px) {

  }

  @media (max-width: 767px) {
    .footer-left {
      padding: 80px 15px 20px 15px !important;

      &__logo {
        margin-bottom: 60px;
        h2{
          font-size: 30px !important;
          line-height: 50px;
        }
      }
    }

    .footer-right {
      height: 500px;
    }
  }

`;


export default Address;
