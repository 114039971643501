export const GALLERY = {
  FETCH_GALLERY_PAGE: {
    MAIN: 'FETCH_GALLERY_PAGE',
    SUCCESS: 'FETCH_GALLERY_PAGE_SUCCESS',
    FAILURE: 'FETCH_GALLERY_PAGE_FAILURE'
  },
  FETCH_GALLERY_CHILD: {
    MAIN: 'FETCH_GALLERY_CHILD',
    SUCCESS: 'FETCH_GALLERY_CHILD_SUCCESS',
    FAILURE: 'FETCH_MEDIA_CHILD_FAILURE'
  }
}
