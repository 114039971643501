import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import SubTitle from "../../../components/SubTitle";
import Title from "../../../components/Title";
import {hover, whiteText, primaryText} from "../../../globalStyleVars";
import {uniqueId} from "../../../helpers/utils";
import {Link} from "react-router-dom";
import Swiper from "react-id-swiper";
import 'swiper/css/swiper.css'
import TextSlideChildrenAnimation from "../../../components/animations/TextSlideChildrenAnimation";
import {baseUrl} from "../../../components/BaseUrl";

// Images
import navLeft from "../../../assets/images/nav-left.svg";
import navLeftAfter from "../../../assets/images/nav-left-hover.svg";
import navRight from "../../../assets/images/nav-right.svg";
import navRightAfter from "../../../assets/images/nav-right-hover.svg";

// Dynamic Images
import ProductMan from "../../../assets/images/dynamic/ProductMan.jpg";
import ProductWomen from "../../../assets/images/dynamic/ProductWomen.jpg";
import ProductBoy from "../../../assets/images/dynamic/ProductBoy.jpg";
import ProductGirl from "../../../assets/images/dynamic/ProductGirl.jpg";
import BlurImg from "../../../assets/images/blur.jpg";
import LinkWithIcon from "../../../components/LinkWithIcon";
import Modal from "../../../components/Modal";


const Team = ({data, offset}) => {

    const params = {
        speed: 7000,
        slidesPerView: 3,
        spaceBetween: 30,
        // freeMode: true,
        // loop: true,
        slidesPerGroup: 1,
        grabCursor: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        breakpoints: {
            769: {
                autoplay: false,
                spaceBetween: 15,
                slidesPerView: 3,
            },
            700: {
                autoplay: false,
                spaceBetween: 15,
                slidesPerView: 2,
            },
            411: {
                autoplay: false,
                spaceBetween: 15,
                slidesPerView: 2,
            },
            320: {
                autoplay: false,
                spaceBetween: 15,
                slidesPerView: 2,
            },

        }
    };

    const nextRef = useRef(null);
    const prevRef = useRef(null);

    let [leftNav, setLeftNav] = useState(navLeft);
    let [rightNav, setRightNav] = useState(navRight);

    // let [navStatus, setNavStatus] = useState(false);


    let handleRightBefore = (e) => {
        setRightNav(e);
    };
    let handleRightAfter = (e) => {
        setRightNav(e);
    };
    let handleLeftBefore = (e) => {
        setLeftNav(e);
    };
    let handleLeftAfter = (e) => {
        setLeftNav(e);
    };

    useEffect(() => {
        nextRef.current.addEventListener('click', () => {
            document.querySelector('.products .swiper-button-prev').click();
        });
        prevRef.current.addEventListener('click', () => {
            document.querySelector('.products .swiper-button-next').click();
        });

    }, []);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    return (
        <StyledTeam className="products">
            <Container>
                <Row>
                    <div className="slider-nav">
                        <ul>
                            <li onMouseLeave={() => handleLeftAfter(navLeft)}
                                onMouseOver={() => handleLeftBefore(navLeftAfter)} ref={nextRef}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                    <g id="Component_17_23" data-name="Component 17 – 23" transform="translate(40 40) rotate(180)">
                                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20" fill="#545355"/>
                                        <circle id="Ellipse_5" data-name="Ellipse 5" cx="1" cy="1" r="1" transform="translate(19 19)" fill="#545355" opacity="0"/>
                                        <g id="Group_274" data-name="Group 274" transform="translate(-710 -3473)">
                                            <path id="Path_9" data-name="Path 9" d="M775.554,4027.167l5,5-5,5" transform="translate(-42.554 -539.168)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                            <path id="Path_18" data-name="Path 18" d="M0,0H16" transform="translate(722 3493)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                    </g>
                                </svg>
                            </li>
                            <li onMouseLeave={() => handleRightAfter(navRight)}
                                onMouseOver={() => handleRightBefore(navRightAfter)} ref={prevRef}>
                                <svg id="Component_17_22" data-name="Component 17 – 22"
                                     xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                    <g id="Group_587" data-name="Group 587">
                                        <g id="Group_586" data-name="Group 586">
                                            <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20"
                                                    fill="#545355"/>
                                        </g>
                                    </g>
                                    <circle id="Ellipse_5" data-name="Ellipse 5" cx="1" cy="1" r="1"
                                            transform="translate(19 19)" fill="#545355" opacity="0"/>
                                    <g id="Group_274" data-name="Group 274" transform="translate(-710 -3473)">
                                        <path id="Path_9" data-name="Path 9" d="M775.554,4027.167l5,5-5,5"
                                              transform="translate(-42.554 -539.168)" fill="none" stroke="#fff"
                                              stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                        <path id="Path_18" data-name="Path 18" d="M0,0H16"
                                              transform="translate(722 3493)" fill="none" stroke="#fff"
                                              stroke-linecap="round" stroke-width="1"/>
                                    </g>
                                </svg>



                            </li>
                        </ul>
                    </div>
                </Row>

                <Row>
                    <Col lg={5} className="products__left">
                        <SubTitle color={'#939299'} text={''}/>
                        <TextSlideChildrenAnimation duration="1.17s">
                            <Title fontSize={'24'} lineHeight={'32'}
                                   text={data?.page_data?.sub_title} fontWeight={500} textTransform={'initial'}
                                   color={primaryText}/>
                        </TextSlideChildrenAnimation>
                    </Col>
                    <Col lg={7} className="p-0 slider-wrap">
                        <Title fontSize={'60'} lineHeight={'60'}
                               text={data?.page_data?.title} fontWeight={300} textTransform={'uppercase'} color={hover}
                               margin={'0 0 40px 0'}/>

                        <div className="slider-wrap__mobile">
                            <Title fontSize={'24'} lineHeight={'24'}
                                   text={data?.page_data?.sub_title} fontWeight={500} textTransform={'initial'}
                                   color={primaryText}/>
                        </div>
                        {
                            data?.posts?.list ?
                                <Swiper  {...params}>
                                    {
                                        data?.posts?.list.map((team, tKey) => {
                                            let thumbImageData = team?.images ? team?.images.find(e => e.is_thumb === 'on') : '';
                                            let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;
                                            return (
                                                <div key={tKey} className="slider_item">
                                                    <div className="popupButton" onClick={handleShow}>
                                                        <Link to={'#'}/>
                                                    </div>

                                                    <div className="slider_item__img">
                                                        <img src={thumbImagePath} alt=""/>
                                                    </div>
                                                    <div className="bottom">
                                                        <h3>{team?.data?.title}</h3>
                                                        <p>{team?.data?.extra_data}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Swiper>
                                : ''
                        }
                    </Col>
                </Row>
            </Container>
        </StyledTeam>
    );
};


const StyledTeam = styled.section`
  padding-top: 190px;
  padding-bottom: 180px;
  background-color: ${whiteText};
  position: relative;


  .nav-wrap {
    position: relative;
  }

  .slider-nav {
    position: absolute;
    //right: 15px;
    bottom: 180px;
    z-index: 2;
    width: fit-content;

    ul {
      display: flex;

      li {
        height: 42px;
        width: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #E5E5E5;
        transition: background-color .4s ease;
        cursor: pointer;
        position: relative;
    svg {
              circle#Ellipse_5 {
                cx: 20;
                cy: 20;
                r: 1;
                transform: translate(0, 0) !important;
                fill: rgb(128, 196, 43);
                stroke: rgb(128, 196, 43);
                transition: all .3s ease;
              }
            }
        &:after {
          content: "";
          position: absolute;
          height: 0;
          width: 0;
          background-color: ${hover};
          border-radius: 50%;
          transition: all .6s cubic-bezier(0.33, 1, 0.68, 1);
          opacity: 0;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }

        &:nth-of-type(1) {
          margin-right: 5px;
        }

        &:hover {
            //background-color: ${hover};
          &:after {
            // height: 100%;
            // width: 100%;
            // opacity: 1;
          }
    svg {
                circle#Ellipse_5 {
                  r: 20;
                      opacity: 1;
                }
                line{
                  stroke: ${whiteText};
                }
                
                
}
          img {
            z-index: 1;
          }
        }
      }
    }
  }

  .slider_item {
    position: relative;

    .slider_item__img {
      position: relative;
      padding-top: calc(250 / 220 * 100%);
      overflow: hidden;

      img {
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
        //transform: scale(1.01);
        //transition: transform 0.8s cubic-bezier(0.33, 1, 0.68, 1);
        //transform-origin: right top;
        position: absolute;
        top: 0;
        left: 0;
        //filter: grayscale(100%);
      }
    }

    &:hover {
      .arrow {
        opacity: 0;
      }

      img {
        //transform: scale(1.1);
        filter: grayscale(0);
      }

      .slider_item__img {
        &:before {
          top: -100%;
        }

        &__after {
          &__texts {
            opacity: 1;
          }
        }
      }
    }

    a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 4;
    }

  }

  .arrow {
    position: absolute;
    right: 30px;
    top: 30px;
    transform: rotate(0deg);
    transition: opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
    display: block;
    z-index: 3;

    path {
      stroke: ${whiteText} !important;
    }
  }

  .sub-title {
    padding-top: 85px;

    &:after {
      width: 80%;
      background-color: #E1E1E1;
    }
  }

  .slider-wrap {
    &__mobile {
      display: none;
    }

    > p {
      margin-top: 20px;
      margin-bottom: 40px;
      font-size: 16px;
      font-weight: 300;
      line-height: 21px;
    }
  }

  .bottom {
    margin-top: 30px;
    position: relative;
    margin-left: 10px;
    padding-left: 10px;

    &:before {
      content: '';
      position: absolute;
      width: 1px;
      background-color: ${hover};
      height: 100px;
      left: 0;
      top: -55px;
    }

    h3 {
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
      color: ${primaryText};
      text-transform: uppercase;
      margin: 0;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      color: ${hover};
    }
  }

  .swiper-container {
    //padding-right: 80px;
  }

  @media (min-width: 1499px) {
    //padding-top: 200px !important;
    //padding-bottom: 200px !important;
  }

  @media (max-width: 1680px) {
    padding-top: 160px;
    padding-bottom: 150px;
    .slider-wrap {
      > p {
        font-size: 14px;
      }
    }

    .slider_item {
      .slider_item__img {
        &:after {
          font-size: 40px;
        }
      }
    }

    .bottom {
      h3 {
        font-size: 18px;
        line-height: 20px;
      }
    }
  }

  @media (max-width: 991px) {
    padding-top: 85px;
    padding-bottom: 85px;
    .slider-nav {
      display: none;
    }

    .products {
      &__left {
        display: none;
      }
    }

    .slider-wrap {
      //padding: 0 40px !important;
      padding-left: 15px !important;
      padding-right: 15px !important;
      //margin-top: 50px;
      &__mobile {
        display: block;
        opacity: .7;
        padding-bottom: 20px;
        border-bottom: 1px solid #E1E1E1;
        margin-bottom: 30px;
      }
    }

    .slider_item {
      .slider_item__img {
        &:before {
          content: none;
        }

        &:after {
          content: none;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .slider-wrap {
      padding: 0 20px !important;
      margin-top: 50px;

      > .title {
        font-size: 40px !important;
        line-height: 50px !important;
      }

      &__mobile {
        .title {
          font-size: 20px !important;
          line-height: 32px !important;
        }
      }

      .swiper-container {
        //padding-right: 150px;
      }
    }
  }
  @media (max-width: 767px) {
    //padding-bottom: 150px !important;
    
    .slider-wrap {
      padding: 0 15px !important;
      margin-top: 0;

      &__mobile {
        .title {
          font-size: 20px !important;
          line-height: 32px !important;
        }
      }

      .slider_item {
        .bottom {
          margin-top: 20px;
          margin-left: 0;
          padding-left: 0;

          &:before {
            content: none;
          }
        }
      }

      .swiper-container {
        padding-right: 70px;
        .swiper-wrapper{
          //padding-right: 150px;
        }
      }
    }

    .slider-nav {
      bottom: 70px;
    }
    .bottom {
      h3{
        font-size: 14px;
        line-height: 21px;
      }
      p{
        font-size: 12px;
        line-height: 21px;
      }
    }

  }



`;


export default Team;
