import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import ReactHtmlParser from "react-html-parser";

// components
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {ParallaxProvider} from 'react-scroll-parallax';
import InnerBanner from "../../components/InnerBanner";
import NewsEventDetail from "./components/Detail";
import {useParams} from "react-router-dom";


import BlurImg from "../../assets/images/blur.jpg";
// Dynamic Image
import BannerImage from "../../assets/images/dynamic/AboutBanner.jpg";

// Action
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {fetchMediaDetail} from "../../redux/actions/media";


const MediaDetailPage = ({
                             getMediaDetail,
                             mediaDetail,
                             offset
                         }) => {
    const {mediaDetailSlug, mediaSlug} = useParams();

    // const [childId, setChildId] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);

        let mediaDetailParam = {
            [ApiParams.page_slug]: mediaDetailSlug
        };
        if (mediaDetail){
            getMediaDetail(mediaDetailParam);

        }
    }, []);

    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <ParallaxProvider>
                <NewsEventDetail data={mediaDetail} parentSlug={mediaSlug}/>
            </ParallaxProvider>
        </motion.div>
    );
};


function mapStateToProps(state) {
    return {
        mediaDetail: state.mediaReducer.mediaDetail,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getMediaDetail: params => dispatch(fetchMediaDetail(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaDetailPage);