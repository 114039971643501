import React, {useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Accordion} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import {hover, primaryText, secondaryText, whiteText} from "../../../globalStyleVars";
import ReactHtmlParser from "react-html-parser";


const CoreValues = ({data}) => {
    const [activeId, setActiveId] = useState();

    function toggleActive(id) {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }

    return (
        <StyledCoreValues className="core-values">
            <Container>
                <Row>
                    <Col md={5} className="d-none d-md-block">
                    </Col>
                    <Col md={7}>
                        <SubTitle text={data?.page_data?.title} color={hover} fontSize={60} fontWeight={300}
                                  lineHeight={70}/>
                    </Col>
                </Row>
            </Container>


            {
                data?.posts?.list ?
                    <Accordion>
                        {
                            data?.posts?.list.map((values, vKey) => {
                                return (
                                    <Accordion.Item eventKey={vKey} key={vKey}
                                                    className={activeId === vKey ? 'panel-wrap active-panel' : 'panel-wrap'}>
                                        <Accordion.Header onClick={() => toggleActive(vKey)} as={"div"}>
                                            <Container>
                                                <Row>
                                                    <Col md={5} className="panel-wrap__header-left d-none d-md-block">
                                                        <span>0{vKey + 1}</span>
                                                    </Col>
                                                    <Col md={7} sm={12} className="panel-wrap__header-right">
                                                        <h3>{values?.data.title}</h3>
                                                        {
                                                            activeId === vKey ?
                                                                <svg id="Component_30_9" data-name="Component 30 – 1"
                                                                     xmlns="http://www.w3.org/2000/svg" width="40"
                                                                     height="40"
                                                                     viewBox="0 0 40 40">
                                                                    <g id="Ellipse_1" data-name="Ellipse 1" fill="none"
                                                                       stroke="#545355"
                                                                       stroke-width="1">
                                                                        <circle cx="20" cy="20" r="20" stroke="none"/>
                                                                        <circle cx="20" cy="20" r="19.5" fill="none"/>
                                                                    </g>
                                                                    <circle id="Ellipse_9" data-name="Ellipse 9"
                                                                            cx="0.6" cy="0.6" r="0.6"
                                                                            transform="translate(19.4 19.4)"
                                                                            fill="#80c42b"/>
                                                                    <circle id="Ellipse_10" data-name="Ellipse 10"
                                                                            cx="1" cy="1" r="1"
                                                                            transform="translate(19 19)"
                                                                            fill="#80c42b"/>
                                                                    <g id="Group_381" data-name="Group 381"
                                                                       transform="translate(-1228.5 -3586.5)">
                                                                        <line id="Line_44" data-name="Line 44"
                                                                              transform="translate(1248.5 3606.5)"
                                                                              fill="none"
                                                                              stroke="#545355"
                                                                              stroke-linecap="round"
                                                                              stroke-width="1.5"/>
                                                                        <line id="Line_45" data-name="Line 45" y2="16"
                                                                              transform="translate(1256.5 3606.5) rotate(90)"
                                                                              fill="none"
                                                                              stroke="#545355" stroke-linecap="round"
                                                                              stroke-width="1.5"/>
                                                                    </g>
                                                                </svg>
                                                                :
                                                                <svg id="Component_30_1" data-name="Component 30 – 1"
                                                                     xmlns="http://www.w3.org/2000/svg" width="40"
                                                                     height="40"
                                                                     viewBox="0 0 40 40">
                                                                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="20"
                                                                            cy="20" r="20"
                                                                            fill="#545355"/>
                                                                    <circle id="Ellipse_9" data-name="Ellipse 9" cx="1"
                                                                            cy="1" r="1"
                                                                            transform="translate(19 19)"
                                                                            fill="#80c42b"/>


                                                                    <g id="Group_381" data-name="Group 381"
                                                                       transform="translate(-1228.5 -3586.5)">
                                                                        <line id="Line_44" data-name="Line 44" y2="16"
                                                                              transform="translate(1248.5 3598.5)"
                                                                              fill="none" stroke="#fff"
                                                                              stroke-linecap="round"
                                                                              stroke-width="1.5"/>
                                                                        <line id="Line_45" data-name="Line 45" y2="16"
                                                                              transform="translate(1256.5 3606.5) rotate(90)"
                                                                              fill="none"
                                                                              stroke="#fff" stroke-linecap="round"
                                                                              stroke-width="1.5"/>
                                                                    </g>
                                                                </svg>
                                                        }

                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Container>
                                                <Row>
                                                    <Col md={5} className="panel-wrap__body-left d-none d-md-block">
                                                    </Col>
                                                    <Col md={7} sm={12} className="panel-wrap__body-right">
                                                        {ReactHtmlParser(values?.data.description)}
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            })
                        }
                    </Accordion>
                    : ''
            }

        </StyledCoreValues>
    )
};

const StyledCoreValues = styled.section`
  &.core-values {
    .sub-title {
      padding-bottom: 0;
      text-align: left;

      &:after {
        content: none;
      }
    }

    .panel-wrap {
      .accordion-header {
        button {
          width: 100%;
          border: 0;
          padding: 75px 0 65px 0;
          background-color: transparent;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 0;
            background-color: #F1F1F1;
            transition: height .3s cubic-bezier(0.5, 1, 0.89, 1);
          }

          .panel-wrap__header-right {
            > svg {
              circle#Ellipse_10 {
                opacity: 0;
              }

              circle#Ellipse_9 {
                cx: 20;
                cy: 20;
                r: 1;
                transform: translate(0, 0) !important;
                fill: rgb(128, 196, 43);
                stroke: rgb(128, 196, 43);
                transition: all .3s ease;
              }
            }
          }

          &:hover {
            &:before {
              height: 100%;
            }

            .panel-wrap__header-right {
              > svg {
                circle#Ellipse_9 {
                  r: 20;
                }

                line {
                  stroke: ${whiteText};
                }
              }
            }

          }

          .container {
            position: relative;

            &:after {
              content: '';
              position: absolute;
              width: 100%;
              left: 0;
              height: 1px;
              background-color: #F1F1F1;
              bottom: -65px;
            }
          }

          .panel-wrap {
            &__header-left {
              span {
                font-size: 60px;
                font-weight: 700;
                line-height: 60px;
                color: ${hover};
                float: left;
              }
            }

            &__header-right {
              display: flex;
              align-items: center;
              justify-content: space-between;

              h3 {
                text-align: left;
                font-size: 24px;
                line-height: 32px;
                font-weight: 500;
                color: ${primaryText};
              }
            }
          }
        }
      }

      &.active-panel {
        .accordion-header {
          button {

            .panel-wrap__header-right {
              > svg {
                position: relative;

                circle#Ellipse_10 {
                  cx: 20;
                  cy: 20;
                  r: 1;
                  transform: translate(0, 0) !important;
                  fill: rgb(128, 196, 43);
                  stroke: rgb(128, 196, 43);
                  transition: all .3s ease;
                 
                }

                circle#Ellipse_9 {
                  r: 20;
                  fill: transparent;
                  stroke: transparent;
                }

                line {
                  stroke: #545355;
                }
              }
            }
          }

          &:hover {

            .panel-wrap__header-right {
              > svg {
                circle#Ellipse_10 {
                  r: 20;
                  opacity: 1;
                }
                line {
                  stroke: ${whiteText};
                }
              
              }
            }

          }
        }
      }
    }

    .accordion-body {
      .panel-wrap {
        &__body-right {
          padding-top: 40px;
          padding-bottom: 70px;

          p {
            font-size: 18px;
            line-height: 26px;
            font-weight: 500;
            color: ${secondaryText};
            margin-bottom: 32px;
          }
        }
      }
    }

    &.active-panel {
      background-color: #F1F1F1;

      .accordion-header {
        button {
          background-color: #F1F1F1;

          .container {
            &:after {
              background-color: #E1E1E1;
            }
          }
        }
      }
    }
  }
}


@media (max-width: 1680px) {
  &.core-values {
    .sub-title {
      font-size: 40px;
      line-height: 50px;
    }

    .panel-wrap {
      .accordion-body {
        .panel-wrap {
          &__body-right {
            p {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  &.core-values {
    padding-top: 85px;
  }
}

@media (max-width: 767px) {
  &.core-values {
    padding-top: 85px;

    .sub-title {
      //padding-left: 15px;
      //padding-right: 15px;
      text-align: left;
      font-size: 30px;
      line-height: 50px;
    }

    .panel-wrap {
      .accordion-header {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 15px;
          right: 15px;
          height: 1px;
          background-color: #E1E1E1;
          z-index: 9;
        }
      }
    }
  }
}

`;

export default CoreValues;