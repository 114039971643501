import React, {useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from "react-bootstrap";


// Component
import {hover, primaryText, secondaryText} from "../../../globalStyleVars";
import SubTitle from "../../../components/SubTitle";
import Title from "../../../components/Title";
import ModalVideo from 'react-modal-video';

import {baseUrl} from "../../../components/BaseUrl";
import BlurImg from "../../../assets/images/blur.jpg";


const HomeVideo = ({data, offset}) => {
    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');
    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
    };

    let thumbImageData = data?.images?.list ? data?.images?.list.find(e => e.is_thumb === 'on') : '';
    let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;
    return (
        <StyledHomeVideo>
            <Container>
                <Row>
                    <Col md={5} className="video-left">
                        <SubTitle color={'#939299'} text={''}/>
                        <Title fontSize={'24'} lineHeight={'24'}
                               text={data?.page_data?.sub_title} fontWeight={500}
                               textTransform={'initial'} color={primaryText}/>
                    </Col>
                    <Col md={7} className="video-right">
                        <div className="video-right__img"
                             onClick={() => handelOpen(true, `${thumbImageData?.short_desc}`)}>
                            <img src={thumbImagePath} alt="Video Thumbnail"/>
                            <div className="video-right__img__overlay">

                                <svg id="Component_26_1" data-name="Component 26 – 1" xmlns="http://www.w3.org/2000/svg"
                                     width="70" height="70" viewBox="0 0 70 70">
                                    <circle id="Ellipse_6" data-name="Ellipse 6" cx="35" cy="35" r="35" fill="#80c42b"/>
                                    <circle id="Ellipse_7" data-name="Ellipse 7" cx="1" cy="1" r="1"
                                            transform="translate(34 34)" fill="#fff" opacity="0"/>
                                    <g id="Polygon_1" data-name="Polygon 1" transform="translate(44 24) rotate(90)"
                                       fill="none">
                                        <path
                                            d="M9.419,1.872a1.5,1.5,0,0,1,2.5,0l7.864,11.8A1.5,1.5,0,0,1,18.531,16H2.8a1.5,1.5,0,0,1-1.248-2.332Z"
                                            stroke="none"/>
                                        <path
                                            d="M 10.66666603088379 2.704167366027832 L 2.80278205871582 14.49998760223389 C 2.802783966064453 14.49999046325684 2.802785873413086 14.4999942779541 2.802776336669922 14.49999713897705 L 18.53054809570312 14.49999713897705 C 18.53055000305176 14.49999523162842 18.53055191040039 14.49999332427979 18.53055572509766 14.49999713897705 L 10.66667556762695 2.704176902770996 C 10.666672706604 2.704172134399414 10.66667079925537 2.704168319702148 10.66666603088379 2.704167366027832 M 10.66666698455811 1.204167366027832 C 11.14227104187012 1.204167366027832 11.61787605285645 1.426816940307617 11.91474628448486 1.872117042541504 L 19.77863693237305 13.66794681549072 C 20.44318580627441 14.66477680206299 19.72860527038574 15.99999713897705 18.53055572509766 15.99999713897705 L 2.802776336669922 15.99999713897705 C 1.604736328125 15.99999713897705 0.8901462554931641 14.66477680206299 1.554695129394531 13.66794681549072 L 9.418596267700195 1.872117042541504 C 9.715460777282715 1.426816940307617 10.19106388092041 1.204167366027832 10.66666698455811 1.204167366027832 Z"
                                            stroke="none" fill="#fff"/>
                                    </g>
                                </svg>

                            </div>
                        </div>

                        <ModalVideo channel='youtube' isOpen={open} videoId={videoId}
                                    onClose={() => handelOpen(false, '')}/>
                    </Col>
                </Row>
            </Container>
        </StyledHomeVideo>
    );
};

const StyledHomeVideo = styled.section`
  .video-left {
    padding-right: 30px;

    .sub-title {
      padding-top: 1px;

      &:after {
        width: 80%;
        background-color: #E1E1E1;
      }
    }

    .title {
      width: 80%;
    }
  }

  .video-right {
    padding-left: 0;

    &__img {
      padding-top: calc(503 / 880 * 100%);
      position: relative;
      overflow: hidden;
      cursor: pointer;

      &__overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(49, 48, 51, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 80px;
          height: 80px;

          circle#Ellipse_7 {
            cx: 35;
            cy: 35;
            r: 1;
            transform: translate(0, 0) !important;
            fill: rgb(255, 255, 255);
            // stroke: rgb(255, 255, 255);
            transition: all .3s ease;
          }
        }
      }

      img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: transform .3s cubic-bezier(0.5, 1, 0.89, 1);

      }

      &:hover {
        img {
          transform: scale(1.1);
        }

        circle#Ellipse_7 {
          r: 35;
          opacity: 1;
        }

        g {
          fill: #545355;
        }
      }
    }
  }

  @media (max-width: 1536px) {

  }

  @media (max-width: 1440px) {

  }

  @media (max-width: 1680px) {
    .video-right {
      &__img {
        &__overlay {
          svg {
            width: 70px;
            height: 70px;
          }
        }
      }
    }
  }

  @media (max-width: 1280px) {

  }

  @media (max-width: 1024px) {

  }

  @media (max-width: 991px) {
    .video-left {
      //padding-left: 40px !important;
      //padding-right: 40px !important;

      //.sub-title {
      //  display: none;
      //}
    }

    .video-right {
      //padding-left: 40px !important;
      padding-right: 15px;
    }
  }

  @media (max-width: 840px) {

  }

  @media (max-width: 768px) {

  }

  @media (max-width: 767px) {
    .video-left {
      padding-left: 15px !important;
      padding-right: 15px !important;

      .sub-title {
        display: none;
      }

      .title {
        padding-bottom: 25px;
        margin-bottom: 30px;
        position: relative;
        font-size: 24px !important;
        line-height: 32px !important;
        color: ${primaryText};
        width: 100%;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          background-color: #E1E1E1;
        }
      }
    }

    .video-right {
      padding-left: 15px !important;
      padding-right: 15px !important;

      &__img {
        &__overlay {
          svg {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .video-left {
      .title {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
`;

export default HomeVideo;




