import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import SubTitle from "../../../components/SubTitle";
import Title from "../../../components/Title";
import {hover, whiteText, primaryText} from "../../../globalStyleVars";
import {uniqueId} from "../../../helpers/utils";
import {Link} from "react-router-dom";
import Swiper from "react-id-swiper";
import 'swiper/css/swiper.css'
import TextSlideChildrenAnimation from "../../../components/animations/TextSlideChildrenAnimation";
import {baseUrl} from "../../../components/BaseUrl";

// Images
import navLeft from "../../../assets/images/nav-left.svg";
import navLeftAfter from "../../../assets/images/nav-left-hover.svg";
import navRight from "../../../assets/images/nav-right.svg";
import navRightAfter from "../../../assets/images/nav-right-hover.svg";
import BlurImg from "../../../assets/images/blur.jpg";
import VisibilitySensor from "react-visibility-sensor";


const Products = ({data, scrollRef, offset}) => {

    const params = {
        speed: 1000,
        slidesPerView: 3,
        spaceBetween: 30,
        // freeMode: true,
        // loop: true,
        slidesPerGroup: 1,
        grabCursor: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        breakpoints: {
            769: {
                autoplay: false,
                spaceBetween: 15,
                slidesPerView: 3,
            },
            700: {
                autoplay: false,
                spaceBetween: 15,
                slidesPerView: 2,
            },
            411: {
                autoplay: false,
                spaceBetween: 15,
                slidesPerView: 2,
            },
            320: {
                autoplay: false,
                spaceBetween: 15,
                slidesPerView: 2,
            },

        }
    };

    const nextRef = useRef(null);
    const prevRef = useRef(null);
    const offsetRef = useRef(null);

    let [leftNav, setLeftNav] = useState(navLeft);
    let [rightNav, setRightNav] = useState(navRight);

    let [navStatus, setNavStatus] = useState(false);


    let handleRightBefore = (e) => {
        setRightNav(e);
    };
    let handleRightAfter = (e) => {
        setRightNav(e);
    };
    let handleLeftBefore = (e) => {
        setLeftNav(e);
    };
    let handleLeftAfter = (e) => {
        setLeftNav(e);
    };

    useEffect(() => {
        nextRef.current.addEventListener('click', () => {
            document.querySelector('.products .swiper-button-prev').click();
        });
        prevRef.current.addEventListener('click', () => {
            document.querySelector('.products .swiper-button-next').click();
        });

    }, []);

    return (
        <StyledProducts className="products" ref={scrollRef}>
            <Container>
                <Row>
                    <Col md={5} className="products__left">
                        <SubTitle color={'#939299'} text={''}/>
                        {/*<TextSlideChildrenAnimation duration="1.17s">*/}
                        <Title fontSize={'24'} lineHeight={'32'}
                               text={'Category of products.'} fontWeight={500} textTransform={'initial'}
                               color={primaryText}/>
                        {/*</TextSlideChildrenAnimation>*/}

                        <div className="slider-nav">
                            <ul>
                                <li onMouseLeave={() => handleLeftAfter(navLeft)}
                                    onMouseOver={() => handleLeftBefore(navLeftAfter)} ref={nextRef}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                <g id="Component_17_23" data-name="Component 17 – 23" transform="translate(40 40) rotate(180)">
                                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20" fill="#545355"/>
                                    <circle id="Ellipse_5" data-name="Ellipse 5" cx="1" cy="1" r="1" transform="translate(19 19)" fill="#545355" opacity="0"/>
                                    <g id="Group_274" data-name="Group 274" transform="translate(-710 -3473)">
                                        <path id="Path_9" data-name="Path 9" d="M775.554,4027.167l5,5-5,5" transform="translate(-42.554 -539.168)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                        <path id="Path_18" data-name="Path 18" d="M0,0H16" transform="translate(722 3493)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                    </g>
                                </g>
                                </svg>

                            </li>
                                <li onMouseLeave={() => handleRightAfter(navRight)}
                                    onMouseOver={() => handleRightBefore(navRightAfter)} ref={prevRef}>
                                    <svg id="Component_17_22" data-name="Component 17 – 22"
                                         xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                        <g id="Group_587" data-name="Group 587">
                                            <g id="Group_586" data-name="Group 586">
                                                <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20"
                                                        fill="#545355"/>
                                            </g>
                                        </g>
                                        <circle id="Ellipse_5" data-name="Ellipse 5" cx="1" cy="1" r="1"
                                                transform="translate(19 19)" fill="#545355" opacity="0"/>
                                        <g id="Group_274" data-name="Group 274" transform="translate(-710 -3473)">
                                            <path id="Path_9" data-name="Path 9" d="M775.554,4027.167l5,5-5,5"
                                                  transform="translate(-42.554 -539.168)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                            <path id="Path_18" data-name="Path 18" d="M0,0H16"
                                                  transform="translate(722 3493)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                    </svg>


                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={7} className="slider-wrap" ref={offsetRef}>
                        <Title fontSize={'60'} lineHeight={'60'}
                               text={'Products'} fontWeight={300} textTransform={'uppercase'} color={hover}/>
                        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                            {({isVisible}) =>
                                <p className={`${isVisible ? 'anim-active' : ''} fade-right`}>{'Choose the most suitable products category for you.'.split("").map(function (char, index) {
                                    return <span aria-hidden="true" key={index}>{char}</span>;
                                })}</p>
                            }
                        </VisibilitySensor>

                        <div className="slider-wrap__mobile">
                            <Title fontSize={'24'} lineHeight={'24'}
                                   text={'Category of products.'} fontWeight={500} textTransform={'initial'}
                                   color={primaryText}/>
                        </div>
                        {data?.list?.length &&
                            <Swiper  {...params}>
                                {

                                    data?.list.map((category, key) => {
                                        let thumbImageData = category?.images?.list ? category?.images?.list.find(e => e.is_thumb === 'on') : '';
                                        let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;
                                        return (
                                            <div className="slider_item">
                                                <Link to={'/products/' + category.category_data.slug}/>

                                                <div className="slider_item__img" att-count={key + 1}>
                                                    <img src={thumbImagePath} alt=""/>
                                                    <div className="slider_item__img__after">
                                                        <div className="slider_item__img__after__texts">
                                                            <svg id="Slider_Hover_Icon" data-name="Slider Hover Icon"
                                                                 xmlns="http://www.w3.org/2000/svg" width="40"
                                                                 height="40"
                                                                 viewBox="0 0 40 40">
                                                                <circle id="Ellipse_1" data-name="Ellipse 1" cx="20"
                                                                        cy="20"
                                                                        r="20"
                                                                        fill="#545355"/>
                                                                <circle id="Ellipse_5" data-name="Ellipse 5" cx="20"
                                                                        cy="20"
                                                                        r="20"
                                                                        fill="#80c42b"/>
                                                                <g id="Group_274" data-name="Group 274"
                                                                   transform="translate(-710 -3473)">
                                                                    <path id="Path_9" data-name="Path 9"
                                                                          d="M775.554,4027.167l5,5-5,5"
                                                                          transform="translate(-42.554 -539.168)"
                                                                          fill="none"
                                                                          stroke="#fff" stroke-linecap="round"
                                                                          stroke-linejoin="round" stroke-width="1"/>
                                                                    <path id="Path_18" data-name="Path 18" d="M0,0H16"
                                                                          transform="translate(722 3493)" fill="none"
                                                                          stroke="#fff"
                                                                          stroke-linecap="round" stroke-width="1"/>
                                                                </g>
                                                            </svg>
                                                            <p>Explore</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <svg className="arrow" xmlns="http://www.w3.org/2000/svg" width="11.414"
                                                     height="11.414"
                                                     viewBox="0 0 11.414 11.414">
                                                    <g id="Group_4445" data-name="Group 4445"
                                                       transform="translate(1172.355 -452.855)">
                                                        <path id="Path_1881" data-name="Path 1881"
                                                              d="M-1171.648,463.562l10-10"
                                                              fill="none"
                                                              stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                                                        <path id="Path_1882" data-name="Path 1882"
                                                              d="M-1169.087,453.562h7.666"
                                                              transform="translate(-0.226)" fill="none" stroke="#fff"
                                                              strokeLinecap="round"
                                                              strokeWidth="1"/>
                                                        <path id="Path_1883" data-name="Path 1883" d="M0,0H7.665"
                                                              transform="translate(-1161.648 453.562) rotate(90)"
                                                              fill="none"
                                                              stroke="#fff"
                                                              strokeLinecap="round" strokeWidth="1"/>
                                                    </g>
                                                </svg>

                                                <div className="bottom">
                                                    <h3>{category.category_data.title}</h3>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Swiper>
                        }
                    </Col>
                </Row>
            </Container>
        </StyledProducts>
    );
};


const StyledProducts = styled.section`
  padding-top: 0;
  padding-bottom: 180px;
  background-color: ${whiteText};
  position: relative;

  .nav-wrap {
    position: relative;
  }

  .slider-nav {
    position: absolute;
    left: 15px;
    bottom: 55px;
    z-index: 2;
    width: fit-content;

    ul {
      display: flex;

      li {
        height: 42px;
        width: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #E5E5E5;
        transition: background-color .4s ease;
        cursor: pointer;
        position: relative;
            svg {
              circle#Ellipse_5 {
                cx: 20;
                cy: 20;
                r: 1;
                transform: translate(0, 0) !important;
                fill: rgb(128, 196, 43);
                stroke: rgb(128, 196, 43);
                transition: all .3s ease;
              }
            }
          
        &:after {
          content: "";
          position: absolute;
          height: 0;
          width: 0;
          background-color: ${hover};
          border-radius: 50%;
                     transition: height .3s cubic-bezier(0.5, 1, 0.89, 1);

          opacity: 0;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }

        &:nth-of-type(1) {
          margin-right: 5px;
        }

        &:hover {
            //background-color: ${hover};
          &:after {
            // height: 100%;
            // width: 100%;
            // opacity: 1;
          }
          
          svg {
                circle#Ellipse_5 {
                  r: 20;
                      opacity: 1;
                }
                line{
                  stroke: ${whiteText};
                }
                
                
}
          img {
            z-index: 1;
          }
        }
      }
    }
  }

  .slider_item {
    position: relative;

    .slider_item__img {
      position: relative;
      padding-top: 100%;
      overflow: hidden;

      img {
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
        //transform: scale(1.01);
        //transition: transform 0.8s cubic-bezier(0.33, 1, 0.68, 1);
        //transform-origin: right top;
        position: absolute;
        top: 0;
        left: 0;
        //filter: grayscale(100%);
      }

      &__after {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        background-color: rgba(49, 48, 51, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        &__texts {
          opacity: 0;
          text-align: center;
          transition: opacity .6s cubic-bezier(0.33, 1, 0.68, 1);
          transition-delay: .2s;

          svg {
            margin-bottom: 20px;
          }

          p {
            font-size: 16px;
            font-weight: 500;
            color: ${whiteText};
            margin: 0;
          }
        }
      }

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        background-color: ${hover};
        z-index: 2;
        transition: top 0.8s cubic-bezier(0.33, 1, 0.68, 1);
      }

      &:after {
        content: attr(att-count);
        position: absolute;
        z-index: 5;
        bottom: 20px;
        left: 30px;
        font-size: 60px;
        color: ${whiteText};
        transition: bottom .6s cubic-bezier(0.33, 1, 0.68, 1);
        //transition-delay: .1s;
      }
    }

    &:hover {
      .arrow {
        opacity: 0;
      }

      img {
        //transform: scale(1.1);
        filter: grayscale(0);
      }

      .slider_item__img {
        &:before {
          top: -100%;
        }
        &:after{
          bottom: 100%;
        }

        &__after {
          &__texts {
            opacity: 1;
          }
        }
      }
    }

    a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 4;
    }

  }

  .arrow {
    position: absolute;
    right: 30px;
    top: 30px;
    transform: rotate(0deg);
    transition: opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
    display: block;
    z-index: 3;

    path {
      stroke: ${whiteText} !important;
    }
  }

  .sub-title {
    padding-top: 125px;

    &:after {
      width: 80%;
      background-color: #E1E1E1;
    }
  }

  .slider-wrap {
    padding-left: 0;
    &__mobile {
      display: none;
    }

    > p {
      margin-top: 20px;
      margin-bottom: 40px;
      font-size: 18px;
      font-weight: 300;
      line-height: 24px;
    }
  }

  .bottom {
    margin-top: 30px;

    h3 {
      font-size: 24px;
      font-weight: bold;
      color: ${primaryText};
      text-transform: uppercase;
      margin: 0;
    }
  }

  //.swiper-container {
  //  padding-right: 80px;
  //}

  @media (min-width: 1499px) {
    //padding-top: 200px !important;
    //padding-bottom: 200px !important;
  }

  @media (max-width: 1680px) {
    padding-top: 0;
    padding-bottom: 150px;
    .slider-wrap {
      > p {
        font-size: 16px;
        line-height: 21px;
      }
    }

    .slider_item {
      .slider_item__img {
        &:after {
          font-size: 40px;
        }
      }
    }

    .bottom {
      h3 {
        font-size: 18px;
        line-height: 20px;
      }
    }
  }

  @media (max-width: 991px) {
    padding-top: 0;
    padding-bottom: 85px;
    .slider-nav {
      //display: none;
    }

    .products {
      &__left {
        //display: none;
      }
    }

    .slider-wrap {
      //padding: 0 40px !important;
      //padding-left: 40px !important;
      padding-right: 15px !important;
      //margin-top: 50px;
      &__mobile {
        //display: block;
        opacity: .7;
        padding-bottom: 20px;
        border-bottom: 1px solid #E1E1E1;
        margin-bottom: 30px;
      }
    }
    .slider_item {
      .slider_item__img {
        &:before {
          content: none;
        }

        &:after {
          content: none;
        }
        &__after{
          &__texts{
            svg{
              width: 30px;
              height: 30px;
              margin-bottom: 5px;
            }
            p{
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding-top: 85px;
    padding-bottom: 85px;
    .slider-nav {
      display: none;
    }

    .products {
      &__left {
        display: none;
      }
    }

    .slider-wrap {
      //padding: 0 20px !important;
      margin-top: 50px;
      padding-left: 0 !important;
      padding-right: 0 !important;
      &__mobile {
        display: block;
        opacity: .7;
        padding-bottom: 20px;
        border-bottom: 1px solid #E1E1E1;
        margin-bottom: 30px;
      }
    }
  }
  @media (max-width: 767px) {
    //padding-bottom: 150px !important;
    .slider-wrap {
      margin-top: 0;

      &__mobile {
        margin-left: 15px;
        margin-right: 15px;
        .title {
          font-size: 20px !important;
          line-height: 32px !important;
        }
      }
      > .title, > p{
        margin-left: 15px;
        margin-right: 15px;
      }
      .swiper-container{
        margin-left: 15px;
        padding-right: 150px;
      }

      .slider_item {
        .bottom {
          margin-top: 20px;
        }
      }
    }

    .slider-nav {
      bottom: 70px;
    }

  }
  @media (max-width: 567px) {
    //padding-bottom: 150px !important;
    .slider-wrap {
      .swiper-container{
        padding-right: 70px;
      }
    }

  }



`;


export default Products;
