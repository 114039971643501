export const PRODUCT = {
  FETCH_PRODUCT: {
    MAIN: 'FETCH_PRODUCT',
    SUCCESS: 'FETCH_PRODUCT_SUCCESS',
    FAILURE: 'FETCH_PRODUCT_FAILURE'
  },
  FETCH_PRODUCT_PAGE: {
    MAIN: 'FETCH_PRODUCT_PAGE',
    SUCCESS: 'FETCH_PRODUCT_PAGE_SUCCESS',
    FAILURE: 'FETCH_PRODUCT_PAGE_FAILURE'
  }
}
